/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Paper } from '@material-ui/core';
import {
  Chart,
  ChartData,
  ChartEvent,
  LegendElement,
  LegendItem,
  TooltipItem
} from 'chart.js';
import 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { ExtendedChartData } from 'chartjs-plugin-stacked100/build/types';
import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptionsBar } from '../../store/types';
import { formatNumber } from '../../utils/utils';
import sortPlugin from './sortPlugin';

/**
 * Component that draw a horizontal stacked bar chart.
 * It is using the framework `chart.js`, for drawing the chart,
 * and also an external plugin `chartjs-plugin-stacked100`, which is
 * sadly not typescript compliant, that is why there are
 * some `//@ts-ignore` in the code
 * @param data The chart data
 * @returns
 */
export const BarChart: FC<{
  data: ChartData<"bar">,
  comparison: boolean,
  selectedStagesNumber: number
}> = ({
  data,
  comparison,
  selectedStagesNumber
}) => {
    Chart.register(ChartjsPluginStacked100)

    const options: ChartOptionsBar = {
      indexAxis: "y", // Rotate the chart, to have horizontal bars
      scales: { // Stack the bars
        x: {
          title: {
            display: true,
            text: "Impact contribution amount",
            font: {
              size: 20
            }
          },
          stacked: true,
          max: comparison ? undefined : 100,
          ticks: {
            // Include a percent sign in the ticks
            callback: (value: string | number) => {
              return value.toString() + (comparison ? "" : " %");
            }
          },
        },
        y: {
          title: {
            display: true,
            text: "Impact categories",
            font: {
              size: 20
            }
          },
          stacked: true,
        }
      },
      interaction: { // Tooltip interaction
        intersect: false, // Tooltip is displayed anytime
        mode: 'nearest', // Tooltip display all information about the nearest bar
        axis: 'y'
      },
      plugins: {
        stacked100: {
          enable: !comparison,
          replaceTooltipLabel: false,
          precision: 3,
          fixNegativeScale: false,
          individual: false
        },
        title: { // Chart title
          display: true,
          text: 'Total life cycle stages impact contributions',
          font: {
            size: 30
          }
        },
        tooltip: {
          callbacks: {
            // Remove "," from the tooltip title
            title: (titleItem: TooltipItem<"bar">[]) => {
              return titleItem[0].dataset.stack as string
            },
            beforeBody: (tooltipItem: TooltipItem<"bar">[]) => {
              // Add total impact label
              const total = tooltipItem.reduce(
                (total, item) => total += item.raw as number, 0
              )
              return "Total impact: " + formatNumber(total)
            },
            label: (tooltipItem: TooltipItem<"bar">) => {
              const data = tooltipItem.chart.data as ExtendedChartData
              const datasetIndex = tooltipItem.datasetIndex;
              const index = tooltipItem.dataIndex;
              const datasetLabel = data.datasets[datasetIndex].label || "";
              if (!comparison) {
                // `data.originalData` is raw values,
                // `data.calculatedData` is percentage values
                const originalValue =
                  formatNumber(
                    data.originalData?.[datasetIndex][index] as number
                  )
                const rateValue =
                  data.calculatedData?.[datasetIndex][index] as number
                return `${datasetLabel}: ${originalValue} ( ${rateValue}% )`;
              }
              const value = formatNumber(
                data.datasets[datasetIndex].data[index] as number
              )
              return `${datasetLabel}: ${value} `;
            }
          },
          // Sort the tooltip items by descending order
          itemSort: (
            item1: TooltipItem<"bar">,
            item2: TooltipItem<"bar">
          ) => (item2.raw as number) - (item1.raw as number),
        },
        legend: {
          labels: {
            // Avoid having duplicate legend items
            filter: (legendItem: LegendItem) => {
              return !(legendItem.datasetIndex as number >
                selectedStagesNumber - 1)
            }
          },

          // Hide/show every dataset that is linked to the selected LCS
          onClick: (
            _: ChartEvent,
            legendItem: LegendItem,
            legend: LegendElement<"bar">
          ) => {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            let idx = index as number % selectedStagesNumber
            const max = data.datasets.length
            if (ci.isDatasetVisible(index as number)) {
              while (idx < max) {
                ci.hide(idx);
                idx += selectedStagesNumber
              }
              legendItem.hidden = true;
            } else {
              while (idx < max) {
                ci.show(idx);
                idx += selectedStagesNumber
              }
              legendItem.hidden = false;
            }
          }
        }
      }
    };

    return (
      <Paper style={{ width: "100%" }}>
        <Bar
          data={data}
          options={options}
          plugins={[sortPlugin]}
        />
      </Paper>
    )
  }
