import axios from "axios";
import { InfiniteUser, InfiniteUserUpdate } from "../store/schema";
import configData from "./config.json";

const userService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/users",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const createUser = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string
): Promise<InfiniteUser> => {
  const response = await userService.post<InfiniteUser>("/", {
    email,
    firstName,
    lastName,
    password,
    isActive: true,
    isSuperuser: false,
  });
  return response.data;
};

export const getCurrentUser = async (): Promise<InfiniteUser> => {
  const response = await userService.get<InfiniteUser>("/current");
  return response.data;
};

export const updateCurrentUser = async (
  userUpdate: InfiniteUserUpdate
): Promise<InfiniteUser> => {
  const response = await userService.put<InfiniteUser>("/current", userUpdate);
  return response.data;
};

export const deleteCurrentUser = async (): Promise<InfiniteUser> => {
  const response = await userService.delete<InfiniteUser>("/current");
  return response.data;
};
