/* eslint-disable indent */
import axios from "axios";
import {
  CalculationSetup,
  CalculationState,
  Flow,
  FlowCreate,
  FlowProperty,
  ImpactMethod,
  ImpactResult,
  Msg,
  Process,
  ProcessCreate,
  ProcessUpdate,
  Provider,
  Ref,
  Unit,
  UnitGroup,
} from "../store/schema";
import configData from "./config.json";

const olcaService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/olca",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getLocationDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/locations");
  return response.data;
};

export const getLocationDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/locations/${id}/info`);
  return response.data;
};

export const getLocation = async (id: string): Promise<Location> => {
  const response = await olcaService.get<Location>(`/locations/${id}`);
  return response.data;
};

export const getUnitDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/units");
  return response.data;
};

export const getUnitDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/units/${id}/info`);
  return response.data;
};

export const getUnit = async (id: string): Promise<Unit> => {
  const response = await olcaService.get<Unit>(`/units/${id}`);
  return response.data;
};

export const getUnitGroupDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/unit-groups");
  return response.data;
};

export const getUnitGroupDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/unit-groups/${id}/info`);
  return response.data;
};

export const getUnitGroup = async (id: string): Promise<UnitGroup> => {
  const response = await olcaService.get<UnitGroup>(`/unit-groups/${id}`);
  return response.data;
};

export const getFlowPropertyDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/flow-properties");
  return response.data;
};

export const getFlowPropertyDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/flow-properties/${id}/info`);
  return response.data;
};

export const getFlowProperty = async (id: string): Promise<FlowProperty> => {
  const response = await olcaService.get<FlowProperty>(
    `/flow-properties/${id}`
  );
  return response.data;
};

export const getFlowDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/flows");
  return response.data;
};

export const getFlowDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/flows/${id}/info`);
  return response.data;
};

export const getFlow = async (id: string): Promise<Flow> => {
  const response = await olcaService.get<Flow>(`/flows/${id}`);
  return response.data;
};

export const createFlow = async (flowCreate: FlowCreate): Promise<Flow> => {
  const response = await olcaService.post<Flow>("/flows", flowCreate);
  return response.data;
};

export const getProcessDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/processes");
  return response.data;
};

export const getProcessDescriptor = async (id: string): Promise<Ref> => {
  const response = await olcaService.get<Ref>(`/processes/${id}/info`);
  return response.data;
};

export const getProcess = async (id: string): Promise<Process> => {
  const response = await olcaService.get<Process>(`/processes/${id}`);
  return response.data;
};

export const createProcess = async (
  processCreate: ProcessCreate
): Promise<Process> => {
  const response =
    await olcaService.post<Process>("/processes", processCreate);
  return response.data;
};

export const updateProcess = async (
  processUpdate: ProcessUpdate
): Promise<Process> => {
  const response = await olcaService.put<Process>(
    "/processes",
    processUpdate
  );
  return response.data;
};

export const deleteProcess = async (id: string): Promise<Msg> => {
  const response = await olcaService.delete<Msg>(`/processes/${id}`);
  return response.data;
};

export const getProvider = async (): Promise<Provider[]> => {
  const response = await olcaService.get<Provider[]>("/providers");
  return response.data;
};

export const getProviderByFlow = async (id: string): Promise<Provider[]> => {
  const response = await olcaService.get<Provider[]>(`/providers/${id}`);
  return response.data;
};

export const getMethodDescriptors = async (): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>("/methods");
  return response.data;
};

export const getMethodIndicators = async (id: string): Promise<Ref[]> => {
  const response = await olcaService.get<Ref[]>(`/methods/${id}/indicators`);
  return response.data;
};

export const getMethod = async (id: string): Promise<ImpactMethod> => {
  const response = await olcaService.get<ImpactMethod>(`/methods/${id}`);
  return response.data;
};

export const calculate = async (
  calculationSetup: CalculationSetup
): Promise<CalculationState> => {
  const response = await olcaService.post<CalculationState>(
    "/calculations",
    calculationSetup
  );
  return response.data;
};

export const getCalculationState = async (
  id: string
): Promise<CalculationState> => {
  const response = await olcaService.get<CalculationState>(
    `/calculations/${id}/state`
  );
  return response.data;
};

export const getCalculationResults = async (
  id: string
): Promise<ImpactResult[]> => {
  const response =
    await olcaService.get<ImpactResult[]>(`/calculations/${id}`);
  return response.data;
};

export const deleteCalculation = async (
  id: string
): Promise<unknown> => {
  const response = await olcaService.delete<unknown>(
    `/calculations/${id}`
  );
  return response.data;
};
