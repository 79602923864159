import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  getBIMPAuthToken
} from '../../api/bimp/authentication.service';
import {
  getBIMPScopes,
  getBIMPCategories,
  getBIMPTipologies,
  createBIMPResource
} from '../../api/bimp/resource.service';
import {
  getBIMPUserProjects,
  getBIMPUserToken,
  getBIMPUserRoles
} from '../../api/bimp/user.service';
import { useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Input
} from '@chakra-ui/react';
import {
  BIMPUserProject,
  BIMPUserRole,
  BIMPScope,
  BIMPCategory,
  BIMPTipology,
  BIMPCreateResourceRequestBody,
  BIMPResourceProperty
} from '../../store/schema';

export const BIMP_AUTH_TOKEN = "BIMPAuthToken";
export const BIMP_USER_TOKEN = "BIMPUserToken";

interface CreateBIMPResourceProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lciaResult: string;
  resultFileName: string;
}

function CreateBIMPResource({
  isOpen,
  setIsOpen,
  lciaResult,
  resultFileName
}: CreateBIMPResourceProps) {
  const cachedAuthToken = sessionStorage.getItem(BIMP_AUTH_TOKEN);
  const cachedTokenUser = sessionStorage.getItem(BIMP_USER_TOKEN);
  const base64Resource = Buffer.from(lciaResult || "").toString('base64');
  const [loggedInToBIMP, setLoggedInToBIMP] = useState(false);
  const [BIMPAuthToken, setBIMPAuthToken] = useState(
    cachedAuthToken ? cachedAuthToken : ""
  );
  const [tokenUser, setTokenUser] = useState(
    cachedTokenUser ? cachedTokenUser : ""
  );
  const [BIMPUsername, setBIMPUsername] = useState("");
  const [BIMPPassword, setBIMPPassword] = useState("");
  const [projects, setProjects] = useState<BIMPUserProject[]>([]);
  const [roles, setRoles] = useState<BIMPUserRole[]>([]);
  const [scopes, setScopes] = useState<BIMPScope[]>([]);
  const [categories, setCategories] = useState<BIMPCategory[]>([]);
  const [tipologies, setTipologies] = useState<BIMPTipology[]>([]);
  const [BIMPInputs, setBIMPInputs] = useState<BIMPInputs>({
    tokenProject: "",
    role: "",
    tokenScope: "",
    tokenCategory: "",
    tokenTipology: ""
  });
  const toast = useToast();

  useEffect(() => {
    if (BIMPAuthToken)
      return;
    getAuthToken();
  }, [BIMPAuthToken]);

  useEffect(() => {
    getProjects();
  }, [tokenUser, loggedInToBIMP]);

  useEffect(() => {
    getRoles();
  }, [BIMPInputs.tokenProject, loggedInToBIMP]);

  useEffect(() => {
    getScopes();
  }, [BIMPInputs.role, loggedInToBIMP]);

  useEffect(() => {
    getCategories();
  }, [BIMPInputs.tokenScope, loggedInToBIMP]);

  useEffect(() => {
    getTipologies();
  }, [BIMPInputs.tokenCategory, loggedInToBIMP]);

  async function getAuthToken() {
    const error = "Failed to get authentication token from BIMP platform";
    let response: AxiosResponse<string> | null = null;
    try { response = await getBIMPAuthToken(); }
    catch (e) {
      showAlert("error", "Error", `${error}: ${e.message}`);
      return;
    }
    showAlert("success", "Success", "Acquired authentication token for BIMP platform");
    setBIMPAuthToken(response.data);
    sessionStorage.setItem(BIMP_AUTH_TOKEN, response.data);
  }

  async function loginToBIMP() {
    if (!BIMPUsername || !BIMPPassword || !BIMPAuthToken)
      return;
    const error = "Failed to login to BIMP platform";
    let response: AxiosResponse<string> | null = null;
    try { response = await getBIMPUserToken(BIMPUsername, BIMPPassword); }
    catch (e) {
      if (e.response && e.response.status === 401) {
        if (e.response.data)
          showAlert("error", "Error", "Wrong credential, please check your username and password again");
        else {
          await getAuthToken();
          loginToBIMP();
        }
      } else
        showAlert("error", "Error", `${error}: ${e.message}`);
      return;
    }
    showAlert("success", "Success", "Acquired user token for BIMP platform");
    setTokenUser(response.data);
    setLoggedInToBIMP(true); //once logged in to BIMP, enable select inputs on modal
    sessionStorage.setItem(BIMP_USER_TOKEN, response.data);
  }

  async function getProjects() {
    if (!loggedInToBIMP || !tokenUser)
      return;
    const projects = await getBIMPUserProjects(tokenUser);
    setProjects(projects);
  }

  async function getRoles() {
    if (!loggedInToBIMP || !BIMPInputs.tokenProject)
      return;
    const roles = await getBIMPUserRoles(tokenUser, BIMPInputs.tokenProject);
    setRoles(roles);
  }

  async function getScopes() {
    if (!loggedInToBIMP || !BIMPInputs.role)
      return;
    const scopes = await getBIMPScopes(
      tokenUser,
      BIMPInputs.tokenProject,
      BIMPInputs.role
    );
    setScopes(scopes);
  }

  async function getCategories() {
    if (!loggedInToBIMP || !BIMPInputs.tokenScope)
      return;
    const categories = await getBIMPCategories(
      tokenUser,
      BIMPInputs.tokenProject,
      BIMPInputs.tokenScope,
      BIMPInputs.role
    );
    setCategories(categories);
  }

  async function getTipologies() {
    if (!loggedInToBIMP || !BIMPInputs.tokenCategory)
      return;
    const tipologies = await getBIMPTipologies(
      tokenUser,
      BIMPInputs.tokenProject,
      BIMPInputs.tokenScope,
      BIMPInputs.tokenCategory,
      BIMPInputs.role
    );
    setTipologies(tipologies);
  }

  function onClose() {
    setIsOpen(false);
  }

  function setBIMPInput(inputName: keyof BIMPInputs, inputValue: string) {
    setBIMPInputs({ ...BIMPInputs, [inputName]: inputValue });
  }

  async function createResource() {
    if (!readyToCreateResource())
      return;
    const fileName = `${resultFileName}.csv`;
    const error = "Failed to create a resource on BIMP";
    const resourceProps: BIMPResourceProperty[] = [
      {
        propertyName: "Title",
        valueProperty: fileName,
        propertyTab: "property_system"
      },
      {
        propertyName: "Description",
        valueProperty: "",
        propertyTab: "property_system"
      },
      {
        propertyName: "Keywords",
        valueProperty: "",
        propertyTab: "property_system"
      },
      {
        propertyName: "Synonyms",
        valueProperty: "",
        propertyTab: "property_system"
      }
    ];
    const requestBody: BIMPCreateResourceRequestBody = {
      tokenUser,
      tokenProject: BIMPInputs.tokenProject,
      tokenScope: BIMPInputs.tokenScope,
      tokenCategory: BIMPInputs.tokenCategory,
      tokenTipology: BIMPInputs.tokenTipology,
      pIsIso: "0",
      pInout: "0",
      pfilename: fileName,
      pfilename_original: fileName,
      prop: resourceProps,
      base64: base64Resource,
    };
    try { await createBIMPResource(requestBody) }
    catch (e) {
      showAlert("error", "Error", `${error}: ${e.message}`);
      return;
    }
    showAlert("success", "Success", "Created a new resource on BIMP");
  }

  function readyToCreateResource() {
    return loggedInToBIMP &&
      tokenUser &&
      BIMPInputs.tokenProject &&
      BIMPInputs.role &&
      BIMPInputs.tokenScope &&
      BIMPInputs.tokenCategory &&
      BIMPInputs.tokenTipology;
  }

  function showAlert(
    status: "success" | "error" | "warning" | "info",
    title: string,
    description: string
  ) {
    toast({
      status,
      title,
      description,
      isClosable: true,
      position: "top",
      duration: 4000
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a BIMP resource</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel>BIMP username</FormLabel>
              <Input type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setBIMPUsername(e.target.value)
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>BIMP password</FormLabel>
              <Input type="password"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setBIMPPassword(e.target.value)
                }
              />
            </FormControl>
            <Button onClick={loginToBIMP}>Login to BIMP platform</Button>
            <FormControl>
              <FormLabel>BIMP project</FormLabel>
              <Select placeholder='Select a project'
                isDisabled={!loggedInToBIMP || !projects.length}
                value={BIMPInputs.tokenProject}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBIMPInput("tokenProject", e.target.value)
                }
              >
                {projects && projects.length > 0 && projects.map(p =>
                  <option key={p.TOKEN} value={p.TOKEN}>{p.NAME}</option>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Project role</FormLabel>
              <Select placeholder='Select your role in the project'
                isDisabled={!loggedInToBIMP || !roles.length}
                value={BIMPInputs.role}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBIMPInput("role", e.target.value)
                }
              >
                {roles.map(r =>
                  <option key={r.INTERNAL_ROLE} value={r.NAME}>{r.NAME}</option>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Project scope</FormLabel>
              <Select placeholder='Select a scope'
                isDisabled={!loggedInToBIMP || !scopes.length}
                value={BIMPInputs.tokenScope}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBIMPInput("tokenScope", e.target.value)
                }
              >
                {scopes.map(s =>
                  <option key={s.TOKEN} value={s.TOKEN}>{s.NAME}</option>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Project category</FormLabel>
              <Select placeholder='Select a category'
                isDisabled={!loggedInToBIMP || !categories.length}
                value={BIMPInputs.tokenCategory}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBIMPInput("tokenCategory", e.target.value)
                }
              >
                {categories.map(c =>
                  <option key={c.TOKEN} value={c.TOKEN}>{c.NAME}</option>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Project tipology</FormLabel>
              <Select placeholder='Select a tipology'
                isDisabled={!loggedInToBIMP || !tipologies.length}
                value={BIMPInputs.tokenTipology}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBIMPInput("tokenTipology", e.target.value)
                }
              >
                {tipologies.map(t =>
                  <option key={t.TOKEN} value={t.TOKEN}>{t.NAME}</option>
                )}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={!readyToCreateResource()}
            onClick={createResource}
          >
            Create BIMP resource
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface BIMPInputs {
  tokenProject: string;
  role: string;
  tokenScope: string;
  tokenCategory: string;
  tokenTipology: string;
}

export default CreateBIMPResource
