import { useState } from 'react'

type Hook = () => [string, React.Dispatch<React.SetStateAction<string>>]
const useSearchBar: Hook = () => {
  const [search, setSearch] = useState("")

  return (
    [search, setSearch]
  );
};

export default useSearchBar
