export const LOCAL_STORAGE_AUTHSTATE_KEY = 'global_state'
export const LOCAL_STORAGE_SAVESTATE_KEY = 'isStateSaved'
export const LOCAL_STORAGE_CALC_RESULT_KEY = 'calculationResult'
export const LOCAL_STORAGE_CALC_INFO_KEY = 'calculationInfo'
export const LOCAL_STORAGE_CALC_STAGES_KEY = 'calculationStages'
export const JWT_ALGO = "HS512"
export const SESSION_EXPIRATION_TIME = 60; // In minutes
export const API_URL = "http://localhost:3001"
export const IS_FAKE_BACKEND = process.env.REACT_APP_IS_FAKE_BACKEND === "true"
export const DATE_LANGUAGE = "en-EN"

