/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createCustomBuilding,
  deleteBuilding,
  getBuilding,
  getCustomBuildings,
  getDefaultBuildings,
  updateBuilding
} from "../api/building.service";
import { useGlobalState } from "../store/globalState";
import {
  InfiniteBuilding,
  InfiniteBuildingCreate,
  InfiniteBuildingUpdate,
  InfiniteCustomBuilding,
  InfiniteEntityType,
  InfiniteKit,
  InfiniteLifeCycleStage
} from "../store/schema";
import { InfiniteEntity } from "../store/types";
import { getErrorMessage } from "../utils/utils";

enum Action {
  ADD, DELETE
}
const updateBuildings = <T extends InfiniteBuilding>(
  buildings: T[],
  modifiedBuildings: T[] | number[],
  action: Action
) => {
  if (action === Action.DELETE) {
    return buildings.filter(
      k => !(modifiedBuildings as number[])
        .some(modifiedBuilding => modifiedBuilding === k.id)
    )
  }
  (modifiedBuildings as T[]).forEach(modifiedBuilding => {
    const index = buildings.findIndex((k) => k.id === modifiedBuilding.id)
    if (index === -1) {
      buildings.push(modifiedBuilding);
    } else {
      buildings[index] = modifiedBuilding;
    }
  })
  return buildings
}

const updateCustomeLifeCycleStages = (
  lifeCycleStages: InfiniteLifeCycleStage[],
  modifiedLifeCycleStages: InfiniteLifeCycleStage[],
  action: Action
) => {
  if (action === Action.DELETE) {
    return lifeCycleStages.filter(
      k => !modifiedLifeCycleStages
        .some(modifiedBuilding => modifiedBuilding.id === k.id)
    )
  }
  modifiedLifeCycleStages.forEach(modifiedBuilding => {
    const index = lifeCycleStages.findIndex(k => k.id === modifiedBuilding.id)
    if (index === -1) {
      lifeCycleStages.push(modifiedBuilding);
    } else {
      lifeCycleStages[index] = modifiedBuilding;
    }
  })
  return lifeCycleStages
}


type buildingHook = () => {
  getDefaultBuildings: () => Promise<InfiniteBuilding[]>;
  getDefaultBuilding: (buildingId: number) => Promise<void>;
  getCustomBuildings: () => Promise<InfiniteCustomBuilding[]>;
  getCustomBuilding: (buildingId: number) => Promise<void>;
  createCustomBuilding: (
    building: InfiniteBuilding | InfiniteBuildingCreate
  ) => Promise<InfiniteBuilding>;
  updateCustomBuilding: (
    id: number,
    buildingUpdate: InfiniteBuildingUpdate
  ) => Promise<void>;
  deleteCustomBuilding: (buildingIds: number[]) => Promise<void>;
}
export const useBuilding: buildingHook = () => {
  const [globalState, setGlobalState] = useGlobalState("globalState")

  const fetchDefaultBuildings = async (): Promise<InfiniteBuilding[]> => {
    try {
      return await getDefaultBuildings()
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchDefaultBuilding = async (buildingId: number): Promise<void> => {
    try {
      const building = await getBuilding(buildingId)
      const buildings = updateBuildings(
        [...globalState.buildings],
        [building],
        Action.ADD
      )
      setGlobalState({ ...globalState, buildings: { ...buildings } })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchCustomBuildings = async (): Promise<InfiniteCustomBuilding[]> => {
    try {
      return await getCustomBuildings()
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchCustomBuilding = async (buildingId: number): Promise<void> => {
    try {
      const building = await getBuilding(buildingId)
      const buildings = updateBuildings(
        [...globalState.user.buildings],
        [building],
        Action.ADD
      )
      setGlobalState({
        ...globalState,
        user: { ...globalState.user, buildings }
      })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const newCustomBuilding = async (
    building: InfiniteBuilding | InfiniteBuildingCreate
  ): Promise<InfiniteBuilding> => {
    try {
      const newBuilding = await createCustomBuilding(
        building.name,
        building.description,
        building.referenceAmount,
        building.unitRef.id,
        building.flowPropertyRef.id,
        building.locationRef.id,
        building.lifeCycleStages
      )
      const updatedBuilding = await getBuilding(newBuilding.id)
      const buildings = updateBuildings(
        [...globalState.user.buildings],
        [updatedBuilding],
        Action.ADD
      )
      setGlobalState({
        ...globalState,
        user: { ...globalState.user, buildings }
      })
      return updatedBuilding
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const modifyCustomBuilding = async (
    id: number,
    buildingUpdate: InfiniteBuildingUpdate
  ): Promise<void> => {
    try {
      const building = await updateBuilding(id, buildingUpdate)
      const buildings = updateBuildings(
        [...globalState.user.buildings],
        [building],
        Action.ADD
      )
      setGlobalState({
        ...globalState,
        user: { ...globalState.user, buildings }
      })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const removeCustomBuilding = async (
    buildingIds: number[]
  ): Promise<void> => {
    try {
      const buildingsToRemove = []
      for (const buildingId of buildingIds) {
        await deleteBuilding(buildingId)
        buildingsToRemove.push(buildingId)
      }

      const buildings = updateBuildings(
        globalState.user.buildings,
        buildingsToRemove,
        Action.DELETE
      )
      setGlobalState({
        ...globalState,
        user: { ...globalState.user, buildings }
      })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  return {
    getDefaultBuildings: fetchDefaultBuildings,
    getDefaultBuilding: fetchDefaultBuilding,
    getCustomBuildings: fetchCustomBuildings,
    getCustomBuilding: fetchCustomBuilding,
    createCustomBuilding: newCustomBuilding,
    updateCustomBuilding: modifyCustomBuilding,
    deleteCustomBuilding: removeCustomBuilding
  }
}

export const formatBuildingRefAmount =
  (building: InfiniteBuilding): string => {
    let ref = building.referenceAmount.toString()
    if (building.unitRef.name) {
      ref += " " + building.unitRef.name
    }
    return ref
  }

export const isInfiniteCustomBuilding =
  (entity: InfiniteEntity): entity is InfiniteCustomBuilding => {
    return ((entity as InfiniteKit).manufacturer === undefined) &&
      entity.type === InfiniteEntityType.CUSTOM
  }
