import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core"
import React, { FC } from "react"
import { InfiniteEntityStatus } from "../../store/schema"
import StatusChip from "./StatusChip"

// FIXME: find an elegant way to store the status,
// since now it is stored stringified in the local state
const ChipSelect: FC<{
  defaultStatus: InfiniteEntityStatus
  handleStatus: (s: InfiniteEntityStatus) => void
  isModificationEnabled: boolean
}> = ({ defaultStatus, handleStatus, isModificationEnabled }) => {

  const handleChange = (status: InfiniteEntityStatus) => {
    handleStatus(status)
  }

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Status</InputLabel>
      <Select
        label="Status"
        value={defaultStatus}
        onChange={(event) => {
          handleChange(event.target.value as InfiniteEntityStatus)
        }}
        input={<Input />}
        renderValue={(selected) => (
          <StatusChip
            status={selected as InfiniteEntityStatus}
          />
        )}
        disabled={!isModificationEnabled}
      >
        {Object.values(InfiniteEntityStatus).map((entityStatus) => (
          <MenuItem key={entityStatus} value={entityStatus}>
            <StatusChip status={entityStatus} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default ChipSelect
