export interface Msg {
  detail: string;
}

export enum LifeCycleStageCategory {
  MANUFACTURING = "MANUFACTURING",
  PACKAGING = "PACKAGING",
  TRANSPORT = "TRANSPORT",
  INSTALLATION = "INSTALLATION",
  USE = "USE",
  MAINTENANCE = "MAINTENANCE",
  END_OF_LIFE = "END_OF_LIFE",
}

export enum InfiniteEntityStatus {
  PRELIMINARY = "PRELIMINARY",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  FINAL = "FINAL",
}
export enum InfiniteEntityType {
  CUSTOM = "CUSTOM",
  ALL = "ALL",
  DEFAULT = "DEFAULT",
}
export enum InfiniteLCSType {
  CUSTOM = "CUSTOM",
  DEFAULT = "DEFAULT",
}

export type SocialStakeHolder =
  "Workers" | "Value chain actors" | "Local community" | "Residents" | string;

export interface InfiniteLifeCycleStage {
  id: number;
  name: string;
  category: LifeCycleStageCategory;
  processRefId: string;
}

export interface InfiniteLifeCycleStageCreate {
  name: string;
  category: LifeCycleStageCategory;
  processRefId: string;
}

export interface InfiniteLifeCycleStageUpdate {
  name?: string;
  category?: LifeCycleStageCategory;
  processRefId?: string;
}

export interface InfiniteEntityRef {
  id: string;
  name?: string;
}

export interface InfiniteBuilding {
  id: number;
  name: string;
  description: string;
  lastChange: string;
  lifeCycleStages: InfiniteLifeCycleStage[];
  technicalSpecs: TechnicalSpecs[];
  flowPropertyRef: InfiniteEntityRef;
  locationRef: InfiniteEntityRef;
  unitRef: InfiniteEntityRef;
  referenceAmount: number;
  status?: InfiniteEntityStatus;
  type: InfiniteEntityType;
  lciaResult: string;
}

export interface InfiniteBuildingCreate {
  name: string;
  description?: string;
  unitRef: InfiniteEntityRef;
  flowPropertyRef: InfiniteEntityRef;
  locationRef: InfiniteEntityRef;
  referenceAmount: number;
  lifeCycleStages: InfiniteLifeCycleStage[];
  status?: InfiniteEntityStatus;
}
export interface InfiniteBuildingUpdate {
  name?: string;
  description?: string;
  unitRefId?: string;
  flowPropertyRefId?: string;
  locationRefId?: string;
  referenceAmount: number;
  status?: InfiniteEntityStatus;
  lciaResult?: string;
}

export interface KitSocialAspect {
  stakeholder: SocialStakeHolder,
  value: number,
  unit: string,
  description: string,
  id: number,
  socialIndicator: KitSocialIndicator
}

export interface BuildingSocialAspect extends KitSocialAspect { }

export interface SocialAspectResp {
  name: string,
  category: LifeCycleStageCategory,
  processRefId: string,
  description: string,
  id: number,
  socialAspects: KitSocialAspect[]
}

export interface NewSocialAspect {
  id?: number,
  socialTheme: number,
  customSocialTheme: string,
  customSocialThemeId: number,
  filteredSocIndis: KitSocialIndicator[],
  stakeholder: SocialStakeHolder,
  customStakeholder?: SocialStakeHolder,
  value: number,
  unit: string,
  description?: string,
  socialIndicatorId: number,
  customSocialIndicator?: string,
  customSocialIndicatorId?: number,
}

export interface KitSocialIndicator {
  indicator: string,
  id: number,
  socialTheme: SocialTheme
}

export interface SocialTheme {
  theme: string,
  id: number
}

export interface TechnicalSpecs {
  name: string;
  description?: string;
  id: number;
  kitId?: number;
}

export interface InfiniteKit {
  id: number;
  name: string;
  description: string;
  lastChange: string;
  lifeCycleStages: InfiniteLifeCycleStage[];
  technicalSpecs: TechnicalSpecs[];
  manufacturer: string;
  referenceAmount: number;
  unitRef: InfiniteEntityRef;
  flowPropertyRef: InfiniteEntityRef;
  locationRef: InfiniteEntityRef;
  status?: InfiniteEntityStatus;
  type: InfiniteEntityType;
  lciaResult: string;
}

export interface InfiniteCustomKit extends InfiniteKit {
  type: InfiniteEntityType.CUSTOM;
}

export interface InfiniteCustomBuilding extends InfiniteBuilding {
  type: InfiniteEntityType.CUSTOM;
}

export type InfiniteEntityUpdate = InfiniteKitUpdate | InfiniteBuildingUpdate;
export type InfiniteCustomEntity = InfiniteCustomKit | InfiniteCustomBuilding;
export interface InfiniteKitUpdate {
  name?: string;
  description?: string;
  manufacturer?: string;
  unitRefId?: string;
  flowPropertyRefId?: string;
  locationRefId?: string;
  referenceAmount: number;
  status?: InfiniteEntityStatus;
  lciaResult?: string;
}

export interface InfiniteKitCreate {
  name: string;
  description?: string;
  manufacturer?: string;
  unitRef: InfiniteEntityRef;
  flowPropertyRef: InfiniteEntityRef;
  locationRef?: InfiniteEntityRef;
  referenceAmount: number;
  lifeCycleStages: InfiniteLifeCycleStage[];
  status?: InfiniteEntityStatus;
}

export interface InfiniteUser {
  id: number;
  email: string;
  isActive: boolean;
  isSuperuser: boolean;
  hasEcoinvent: boolean;
  firstName?: string;
  lastName?: string;
  kits: InfiniteKit[];
  buildings: InfiniteBuilding[];
}

export interface InfiniteUserUpdate {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

export enum FlowType {
  ELEMENTARY_FLOW = "ELEMENTARY_FLOW",
  PRODUCT_FLOW = "PRODUCT_FLOW",
  WASTE_FLOW = "WASTE_FLOW",
}

export enum ProcessType {
  LCI_RESULT = "LCI_RESULT",
  UNIT_PROCESS = "UNIT_PROCESS",
}

enum AllocationType {
  PHYSICAL_ALLOCATION = "PHYSICAL_ALLOCATION",
  ECONOMIC_ALLOCATION = "ECONOMIC_ALLOCATION",
  CAUSAL_ALLOCATION = "CAUSAL_ALLOCATION",
  USE_DEFAULT_ALLOCATION = "USE_DEFAULT_ALLOCATION",
  NO_ALLOCATION = "NO_ALLOCATION",
}

enum FlowPropertyType {
  ECONOMIC_QUANTITY = "ECONOMIC_QUANTITY",
  PHYSICAL_QUANTITY = "PHYSICAL_QUANTITY",
}

enum UncertaintyType {
  LOG_NORMAL_DISTRIBUTION = "LOG_NORMAL_DISTRIBUTION",
  NORMAL_DISTRIBUTION = "NORMAL_DISTRIBUTION",
  TRIANGLE_DISTRIBUTION = "TRIANGLE_DISTRIBUTION",
  UNIFORM_DISTRIBUTION = "UNIFORM_DISTRIBUTION",
}

enum ParameterScope {
  PROCESS_SCOPE = "PROCESS_SCOPE",
  IMPACT_SCOPE = "IMPACT_SCOPE",
  GLOBAL_SCOPE = "GLOBAL_SCOPE",
}

enum RiskLevel {
  NO_OPPORTUNITY = "NO_OPPORTUNITY",
  HIGH_OPPORTUNITY = "HIGH_OPPORTUNITY",
  MEDIUM_OPPORTUNITY = "MEDIUM_OPPORTUNITY",
  LOW_OPPORTUNITY = "LOW_OPPORTUNITY",
  NO_RISK = "NO_RISK",
  VERY_LOW_RISK = "VERY_LOW_RISK",
  LOW_RISK = "LOW_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  HIGH_RISK = "HIGH_RISK",
  VERY_HIGH_RISK = "VERY_HIGH_RISK",
  NO_DATA = "NO_DATA",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export enum CalculationType {
  SIMPLE_CALCULATION = "SIMPLE_CALCULATION",
  CONTRIBUTION_ANALYSIS = "CONTRIBUTION_ANALYSIS",
  UPSTREAM_ANALYSIS = "UPSTREAM_ANALYSIS",
  REGIONALIZED_CALCULATION = "REGIONALIZED_CALCULATION",
  MONTE_CARLO_SIMULATION = "MONTE_CARLO_SIMULATION",
}

export interface Entity {
  "@id"?: string;
  "@type"?: string;
}

export interface RootEntity extends Entity {
  name?: string;
  description?: string;
  version?: string;
  lastChange?: string;
}

export interface Ref extends RootEntity {
  "@id": string;
  "@type": string;
  category?: string;
  library?: string;
  refUnit?: string;
  location?: string;
  flowType?: FlowType;
  processType?: ProcessType;
}

export interface CategorizedEntity extends RootEntity {
  category?: string;
  tags?: string[];
  library?: string;
}

export interface ProcessDocumentation extends Entity {
  timeDescription?: string;
  validUntil?: string;
  validFrom?: string;
  technologyDescription?: string;
  dataCollectionDescription?: string;
  completenessDescription?: string;
  dataSelectionDescription?: string;
  reviewDetails?: string;
  dataTreatmentDescription?: string;
  inventoryMethodDescription?: string;
  modelingConstantsDescription?: string;
  reviewer?: Ref;
  samplingDescription?: string;
  sources?: Ref[];
  restrictionsDescription?: string;
  copyright?: boolean;
  creationDate?: string;
  dataDocumentor?: Ref;
  dataGenerator?: Ref;
  dataSetOwner?: Ref;
  intendedApplication?: string;
  projectDescription?: string;
  publication?: Ref;
  geographyDescription?: string;
}

export interface ExchangeRef extends Entity {
  "@type": "ExchangeRef";
  internalId?: number;
}

export interface Uncertainty extends Entity {
  distributionType?: UncertaintyType;
  mean?: number;
  meanFormula?: string;
  geomMean?: number;
  geomMeanFormula?: string;
  minimum?: number;
  minimumFormula?: string;
  sd?: number;
  sdFormula?: string;
  geomSd?: number;
  geomSdFormula?: string;
  mode?: number;
  modeFormula?: string;
  maximum?: number;
  maximumFormula?: string;
}

export interface AllocationFactor extends Entity {
  allocationType?: AllocationType;
  product?: Ref;
  value?: number;
  formula?: string;
  exchange?: ExchangeRef;
}

export interface Parameter extends CategorizedEntity {
  "@type": "Parameter";
  parameterScope?: ParameterScope;
  isInputParameter?: boolean;
  value?: number;
  formula?: string;
  uncertainty?: Uncertainty;
}

export interface ParameterRedef extends Entity {
  "@type": "ParameterRedef";
  context?: Ref;
  description?: string;
  name?: string;
  uncertainty?: Uncertainty;
  value?: number;
}

export interface ParameterRedefSet extends Entity {
  "@type": "ParameterRedefSet";
  name?: string;
  description?: string;
  isBaseline?: boolean;
  parameters?: ParameterRedef[];
}

export interface NwFactor extends Entity {
  "@type": "NwFactor";
  impactCategory?: Ref;
  normalisationFactor?: number;
  weightingFactor?: number;
}

export interface NwSet extends RootEntity {
  "@type": "NwSet";
  weightedScoreUnit?: string;
  factors?: NwFactor[];
}

export interface FlowResult extends Entity {
  "@type": "FlowResult";
  flow?: Ref;
  isInput?: boolean;
  value?: number;
  location?: Ref;
}

export interface ImpactResult extends Entity {
  "@type": "ImpactResult";
  impactCategory?: Ref;
  amount?: number;
}

export interface SimpleResult extends Entity {
  "@type": "SimpleResult";
  flowResults?: FlowResult[];
  impactResults?: ImpactResult[];
}

export interface SavedResult {
  "Impact Method"?: string;
  "Impact Category"?: string;
  "Impact Value"?: number;
  "Unit"?: string;
}

export interface CalculationSetup extends Entity {
  calculationType?: CalculationType;
  productSystem?: Ref;
  process?: Ref;
  impactMethod?: Ref;
  withCosts?: boolean;
  withRegionalization?: boolean;
  withUncertainties?: boolean;
  nwSet?: Ref;
  allocationMethod?: AllocationType;
  parameterRedefs?: ParameterRedef[];
  amount?: number;
  unit?: Ref;
  flowProperty?: Ref;
  numberOfRuns?: number;
}

export interface CalculationState {
  "@id": string;
  error?: string;
  isReady: boolean;
  isScheduled: boolean;
  time: number;
}

export interface InfiniteEntityCalculationStates {
  manufacturing: CalculationState;
  packaging: CalculationState;
  transport: CalculationState;
  installation: CalculationState;
  use: CalculationState;
  maintenance: CalculationState;
  endOfLife: CalculationState;
}

export interface SocialAspect extends Entity {
  activityValue?: number;
  comment?: string;
  quality?: string;
  rawAmount?: string;
  riskLevel?: RiskLevel;
  socialIndicator?: Ref;
  source?: Ref;
}

export interface Location extends CategorizedEntity {
  "@type": "Location";
  code?: string;
  latitude?: number;
  longitude?: number;
  geometry?: Record<string, string>;
}

export interface Unit extends RootEntity {
  "@id": string;
  "@type": "Unit";
  conversionFactor?: number;
  isReferenceUnit?: boolean;
  synonyms?: string[];
}

export interface BaseUnitGroup extends CategorizedEntity {
  defaultFlowProperty?: Ref;
  units?: Unit[];
}

export interface UnitGroup extends BaseUnitGroup {
  "@context": string;
  "@id": string;
  "@type": "UnitGroup";
}

export interface FlowPropertyFactor extends Entity {
  "@type": "FlowPropertyFactor";
  flowProperty?: Ref;
  conversionFactor?: number;
  isReferenceFlowProperty?: boolean;
}

export interface BaseFlowProperty extends CategorizedEntity {
  flowPropertyType?: FlowPropertyType;
  unitGroup?: Ref;
}

export interface FlowProperty extends BaseFlowProperty {
  "@context": string;
  "@id": string;
  "@type": "FlowProperty";
}

export interface BaseFlow extends CategorizedEntity {
  flowType?: FlowType;
  cas?: string;
  formula?: string;
  flowProperties?: FlowPropertyFactor[];
  location?: Ref;
  synonyms?: string;
  isInfrastructureFlow?: boolean;
}

export interface Flow extends BaseFlow {
  "@context": string;
  "@id": string;
  "@type": "Flow";
}

export interface FlowCreate extends BaseFlow {
  flowType: FlowType;
  flowProperties: FlowPropertyFactor[];
}

export interface Exchange extends Entity {
  "@type": "Exchange";
  isAvoidedProduct?: boolean;
  costFormula?: string;
  costValue?: number;
  currency?: Ref;
  internalId?: number;
  flow?: Ref;
  flowProperty?: Ref;
  isInput?: boolean;
  isQuantitativeReference?: boolean;
  baseUncertainty?: number;
  defaultProvider?: Ref;
  amount?: number;
  amountFormula?: string;
  unit?: Ref;
  dqEntry?: string;
  uncertainty?: Uncertainty;
  description?: string;
  location?: string;
  provider?: string;
}

export interface ProcessLink extends Entity {
  "@type": "ProcessLink";
  provider?: Ref;
  flow?: Ref;
  process?: Ref;
  exchange?: ExchangeRef;
}

export interface BaseProcess extends CategorizedEntity {
  allocationFactors?: AllocationFactor[];
  defaultAllocationMethod?: AllocationType;
  exchanges?: Exchange[];
  lastInternalId?: number;
  location?: Ref;
  parameters?: Parameter[];
  processDocumentation?: ProcessDocumentation;
  processType?: ProcessType;
  dqSystem?: Ref;
  exchangeDqSystem?: Ref;
  socialDqSystem?: Ref;
  dqEntry?: string;
  isInfrastructureProcess?: boolean;
  socialAspects?: SocialAspect[];
}

export interface Process extends BaseProcess {
  "@context": string;
  "@id": string;
  "@type": "Process";
}

export interface ProcessCreate extends BaseProcess {
  "@type"?: "Process";
  processType: ProcessType;
}

export interface ProcessUpdate extends BaseProcess {
  "@type"?: "Process";
}

export interface Provider {
  provider: {
    "@type": "Process";
    "@id": string;
    name: string;
    category: string;
    processType: ProcessType;
    flowType: FlowType;
    location: string;
  },
  flow: {
    "@type": "Flow";
    "@id": string;
    name: string;
    category: string;
    flowType: FlowType;
    refUnit: string;
  }
}

export interface BaseProductSystem extends CategorizedEntity {
  processes?: Ref[];
  referenceProcess?: Ref;
  referenceExchange?: ExchangeRef;
  targetAmount?: number;
  targetUnit?: Ref;
  targetFlowProperty?: Ref;
  processLinks?: ProcessLink[];
  parameterSets?: ParameterRedefSet[];
}

export interface ProductSystem extends BaseProductSystem {
  "@context": string;
  "@id": string;
  "@type": "ProductSystem";
}

export interface ImpactFactor extends Entity {
  "@type": "ImpactFactor";
  flow?: Ref;
  location?: Ref;
  flowProperty?: Ref;
  unit?: Ref;
  value?: number;
  formula?: string;
  uncertainty?: Uncertainty;
}

export interface ImpactMethod extends CategorizedEntity {
  "@type": "ImpactMethod";
  impactCategories?: Ref[];
  nwSets?: NwSet[];
}

export interface BIMPAuthBody {
  client_id: string;
  client_secret: string;
  grant_type: string;
}

export interface BIMPEntity {
  TOKEN: string;
  NAME: string;
}

export interface BIMPUserProject extends BIMPEntity {
  COMPANY: string;
  CITY: string;
  STATUS: number;
  ORIGINAL_NAME: string;
}

export interface BIMPUserRole {
  NAME: string;
  INTERNAL_ROLE: number;
}

export interface BIMPScope extends BIMPEntity {}
export interface BIMPCategory extends BIMPEntity {}
export interface BIMPTipology extends BIMPEntity {}

export type BIMPResourcePropertyName = "Title" | "Description" | "Keywords" | "Synonyms";

export interface BIMPResourceProperty {
  propertyName: BIMPResourcePropertyName;
  valueProperty: string;
  propertyTab: "property_system";
}

export interface BIMPCreateResourceRequestBody {
  tokenUser: string;
  tokenProject: string;
  tokenScope: string;
  tokenCategory: string;
  tokenTipology: string;
  pIsIso: "0" | "1";
  pInout: "0" | "1";
  pfilename: string;
  pfilename_original: string;
  prop: BIMPResourceProperty[];
  base64: string;
}
