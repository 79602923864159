/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useToast } from "@chakra-ui/react"
import { encode } from "jwt-simple"
import { useEffect } from "react"
import { useHistory } from "react-router"
import {
  getCustomBuildings,
  getDefaultBuildings
} from "../api/building.service"
import {
  deleteCalculation,
  getCalculationResults,
  getCalculationState,
  getFlowDescriptors,
  getFlowPropertyDescriptors,
  getLocationDescriptors,
  getMethodDescriptors,
  getUnitGroup,
  getUnitGroupDescriptors
} from "../api/olca.service"
import {
  JWT_ALGO,
  LOCAL_STORAGE_AUTHSTATE_KEY,
  LOCAL_STORAGE_SAVESTATE_KEY,
  LOCAL_STORAGE_CALC_RESULT_KEY,
  LOCAL_STORAGE_CALC_INFO_KEY
} from "../constants"
import { useAuthentication } from "../services/authentication.service"
import { useKit } from "../services/kits.service"
import { useGlobalState } from "../store/globalState"
import {
  CalculationState,
  ImpactResult,
  InfiniteEntityRef,
  InfiniteUser,
  Ref,
  SimpleResult,
} from "../store/schema"
import { JWT_KEY } from "../utils/secretKey"
import { isCalculationReady, sleep } from "../utils/utils"

const useHandleAuthenticationState = () => {
  const [authState] = useGlobalState("authState")
  const { authenticate, signout } = useAuthentication()
  const toast = useToast()
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const { getCustomKits, getDefaultKits } = useKit()
  const history = useHistory()

  /**
   * Whenever the authState changes,
   * we store it in the local storage as a JWT Token
   */
  const storeState = (): void => {
    const encodedJWT = encode(JSON.stringify(authState), JWT_KEY, JWT_ALGO)
    const storedState = localStorage.getItem(LOCAL_STORAGE_AUTHSTATE_KEY)
    if (!storedState || (storedState && encodedJWT !== storedState)) {
      localStorage.setItem(LOCAL_STORAGE_AUTHSTATE_KEY, encodedJWT)
    }
  }

  /**
   * Load the authentication state from local storage when the app is mounted.
   * It is stored as a JWT token.
   * It will be stored only if the server authenticate the loaded token.
   */
  const authenticateState = async (): Promise<InfiniteUser | undefined> => {
    try {
      return await authenticate()
    } catch (error) {
      signout()
    }
  }

  useEffect(() => {
    if (globalState.isAuthStateSave) {
      // Store the authState in localStorage when it changes
      storeState()
    }
  }, [authState.token])

  useEffect(() => {
    // Store a boolean to indicate wether we want to store the state or not
    localStorage.setItem(
      LOCAL_STORAGE_SAVESTATE_KEY,
      globalState.isAuthStateSave.toString()
    )
  }, [globalState.isAuthStateSave])

  const fetchAllItems = async (user: InfiniteUser) => {
    try {
      if (authState.isAuthenticated) {
        const defaultKits = await getDefaultKits()
        const userKits = await getCustomKits()
        const defaultBuildings = await getDefaultBuildings()
        const userBuildings = await getCustomBuildings()
        const flowProperties = await getFlowPropertyDescriptors()
        const locations: InfiniteEntityRef[] = (
          await getLocationDescriptors()
        ).map((l) => {
          return { id: l["@id"], name: l.name as string }
        })
        const flows = await getFlowDescriptors()
        const methods = await getMethodDescriptors()
        const unitGroups = await getUnitGroupDescriptors()
        const units = await unitsOf(unitGroups)
        setGlobalState({
          ...globalState,
          units,
          flowProperties,
          locations,
          methods,
          kits: [...defaultKits],
          buildings: [...defaultBuildings],
          user: {
            ...user,
            kits: [...userKits],
            buildings: [...userBuildings],
          },
          flows,
        })
      }
    } catch (error) {
      toast.closeAll()
      toast({
        position: "top",
        title: "Error",
        description: "Session expired",
        status: "error",
        duration: 4000,
        isClosable: true,
      })
      signout()
    }
  }

  const setInitialState = async () => {
    // authenticate the stored state when the app is mounted
    const user = await authenticateState()
    if (user && user.id !== -1) {
      void fetchAllItems(user)
    }
  }
  useEffect(() => {
    return void setInitialState()
  }, [authState.isAuthenticated])

  const getLCSCalculationResults = async (
    calculationstate: CalculationState
  ): Promise<ImpactResult[]> => {
    const response = await getCalculationState(calculationstate["@id"])
    if (isCalculationReady(response)) {
      const results =
        await getCalculationResults(calculationstate["@id"]);
      void deleteCalculation(calculationstate["@id"]);
      return results;
    } else {
      await sleep(10000)
      return getLCSCalculationResults(calculationstate)
    }
  }
  const fetchCalculationResult = async (
    calculationStates: CalculationState[][]
  ) => {
    const results = await Promise.all(
      calculationStates.map(
        async (calculationState) =>
          await Promise.all(
            calculationState.map(
              async (lcsCalCulationState) => {
                const result = await getLCSCalculationResults(
                  lcsCalCulationState
                )
                return {
                  "@type": "SimpleResult",
                  impactResults: result
                } as SimpleResult
              }
            )
          )
      )
    )
    localStorage.setItem(
      LOCAL_STORAGE_CALC_RESULT_KEY,
      JSON.stringify(results)
    )
    setGlobalState({ ...globalState, results })
    toast.closeAll()
    toast({
      position: "top",
      title: "Calculation done",
      description: "The calculation has been successfully done",
      status: "success",
      duration: 4000,
      isClosable: true,
    })
    history.push("/results")
  }

  useEffect(() => {
    if (
      globalState.calculationInfo &&
      globalState.calculationInfo?.calculationStates.length > 0
    ) {
      localStorage.setItem(
        LOCAL_STORAGE_CALC_INFO_KEY,
        JSON.stringify(globalState.calculationInfo)
      );
      void fetchCalculationResult(
        globalState.calculationInfo?.calculationStates
      )
    }
  }, [globalState.calculationInfo])
}

async function unitsOf(unitGroupDescriptors: Ref[]) {
  const unitGroupPromises = unitGroupDescriptors.map(
    (ref) => getUnitGroup(ref["@id"])
  )
  const unitGroups = await Promise.all(unitGroupPromises)
  return unitGroups.flatMap(
    (unitGroup) => unitGroup.units
  ).filter(Boolean) as unknown as Ref[]
}

export default useHandleAuthenticationState
