import { GridItem, IconButton } from "@chakra-ui/react"
import { Card, CardActions, CardContent, Typography } from "@material-ui/core"
import React, { FC, useRef } from "react"
import { HiDownload } from "react-icons/hi"
import { Document } from "../../store/types"
import { Delete } from "../Icons/icons"
const DocumentCard: FC<{
  document: Document,
  handleDelete: () => void
}> = ({
  document,
  handleDelete
}) => {
    const ref = useRef<HTMLAnchorElement>(null)
    return (
      <GridItem w="100%" wordBreak={"break-word"}>
        <Card style={{ width: "100%" }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {document.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {document.fileType}
            </Typography>
          </CardContent>
          <CardActions>

            <IconButton
              aria-label="add to favorites"
              colorScheme="green"
              size="xs"
              icon={<HiDownload />}
              onClick={() => ref.current?.click()}
            />


            <IconButton
              mr={2}
              colorScheme="red"
              size="xs"
              aria-label="Search database"
              icon={<Delete />}
              onClick={handleDelete}
            />
            <a
              href={document.downloadLink}
              download={document.name}
              ref={ref}
              style={{ display: "none" }}
            > </a>
          </CardActions>
        </Card>
      </GridItem>
    )
    return (
      <div>
        <a href={document.downloadLink} download={document.name}>
          download
        </a>
      </div>
    )
  }

export default DocumentCard
