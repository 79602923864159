import {
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Spacer,
  Stack,
  useToast
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { FaLock, FaUserAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import Logo from "../assets/INFINITE.svg";
import { useAuthentication } from '../services/authentication.service';
import { useGlobalState } from '../store/globalState';
import { isEmail } from '../utils/utils';
const SignIn: FC = () => {
  const [authState] = useGlobalState("authState")
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const { isAuthenticated } = authState
  const { signin } = useAuthentication()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [touched, setTouched] = useState(false)
  const history = useHistory();
  const isAllowedToSignUp = false
  const toast = useToast()

  const handleShowClick = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/")
    }
  }, [isAuthenticated])

  const submitHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setTouched(true)
    if (!isEmail(email) || !password)
      return
    try {
      await signin(email, password, globalState.isAuthStateSave)
    } catch (error) {
      toast.closeAll()
      toast({
        position: "top",
        title: "Error",
        description: error as string,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const CFaUserAlt = chakra(FaUserAlt);
  const CFaLock = chakra(FaLock);

  return (
    <>
      <Flex
        flexDirection="column"
        w="100wh"
        h="calc(100% - 52px)"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={Logo} height={"70"} />
          <form>
            <Box minW={{ base: "90%", md: "468px" }} >
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl isRequired isInvalid={touched && !isEmail(email)}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                    >
                      <CFaUserAlt color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="email"
                      placeholder="email address"
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {`Please provide a${email ? " well formated" : "n"} email`}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={touched && !password}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300">
                      <CFaLock color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    Please provide a password
                  </FormErrorMessage>
                  <HStack pt="2">
                    <Box>
                      <Checkbox
                        isChecked={globalState.isAuthStateSave}
                        onChange={(e) => setGlobalState({
                          ...globalState,
                          isAuthStateSave: e.target.checked
                        })}
                      >
                        Remember me
                      </Checkbox>
                    </Box>
                    <Spacer />
                    <Box>
                      <FormHelperText textAlign="right">
                        <Link>forgot password?</Link>
                      </FormHelperText>
                    </Box>
                  </HStack>
                </FormControl>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                  disabled={loading}
                  onClick={submitHandler}

                >
                  {loading ? "Loading ..." : "Login"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Stack>
        {isAllowedToSignUp &&
          <Box>
            New to us?{" "}
            <Link color="blue.600" href="#">
              Sign Up
            </Link>
          </Box>
        }
      </Flex>
    </>
  );
}

export default SignIn;
