import {
  Button,
  Flex,
  HStack,
  Spacer, useDisclosure,
  VStack
} from "@chakra-ui/react"
import { Grid } from "@material-ui/core"
import React, { FC, useState } from "react"
import { IoMdArrowDroprightCircle } from "react-icons/io"
import useSearchBar from "../../hook/SearchBar"
import { Calculation } from "../Modal/Calculation"
import SearchBar from "../SearchBar"
import DefaultBuildingLibrary from "./DefaultBuildingLibrary"
import UserBuildingLibrary from "./UserBuildingLibrary"

const BuildingLibraries: FC = () => {
  const [search, setSearch] = useSearchBar()
  const [userSelectedBuildingIds, setUserBuildingIds] = useState<number[]>([])
  const [infiniteSelectedBuildingIds, setInfiniteKBuildingds] = useState<
    number[]
  >([])
  const {
    isOpen: isCalculationOpen,
    onOpen: onCalculationOpen,
    onClose: onCalculationClose,
  } = useDisclosure()
  return (
    <Flex m={5}>
      <VStack w="100%">
        <HStack alignSelf="end">
          <Spacer />
          <SearchBar
            search={search}
            setSearch={setSearch}
            placeholder="Building name"
          />
        </HStack>
        <HStack w="100%" mb={6}>
          <VStack w="100%">
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6}>
                <DefaultBuildingLibrary
                  searchString={search}
                  setBuildingIds={setInfiniteKBuildingds}
                  onCalculationOpen={onCalculationOpen}
                />
              </Grid>
              <Grid item xs={12} xl={6} >
                <UserBuildingLibrary
                  searchString={search}
                  setBuildingIds={setUserBuildingIds}
                  onCalculationOpen={onCalculationOpen}
                />
              </Grid>
            </Grid>
            <Flex mt={5} alignSelf="end">
              <HStack w="100%">
                <Button
                  isDisabled={
                    userSelectedBuildingIds.length +
                    infiniteSelectedBuildingIds.length ===
                    0
                  }
                  onClick={onCalculationOpen}
                  leftIcon={
                    <IoMdArrowDroprightCircle
                      color="green"
                      size={20}
                      style={{ marginTop: 2 }}
                    />
                  }
                >
                  {userSelectedBuildingIds.length +
                    infiniteSelectedBuildingIds.length >
                    1
                    ? "Run Building LCA comparison"
                    : "Run Building LCA"}
                </Button>
              </HStack>
            </Flex>
          </VStack>
          <Calculation
            isInfiniteKit={false}
            isOpen={isCalculationOpen}
            onClose={onCalculationClose}
            infiniteEntitiesIds={userSelectedBuildingIds.concat(
              infiniteSelectedBuildingIds
            )}
          />
        </HStack>
      </VStack>
    </Flex>
  )
}

export default BuildingLibraries
