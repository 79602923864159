import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack
} from '@chakra-ui/react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { FC, useEffect, useState } from 'react';
import {
  useHandleLifeCycleStagesProps
} from '../../hook/handleInfiniteEntityLifeCycleStages';
import useSearchBar from '../../hook/SearchBar';
import { useGlobalState } from '../../store/globalState';
import { Exchange, Ref } from '../../store/schema';
import { Role } from '../../store/types';
import SearchBar from '../SearchBar';

type FlowRow = {
  id: number,
  flowId: string,
  name: string,
  description: string,
  unit: string
}
const AddComponent: FC<{
  isOpen: boolean,
  onClose: () => void,
  role: Role,
  handleLifeCycleStage: useHandleLifeCycleStagesProps
}> = ({
  isOpen,
  onClose,
  role,
  handleLifeCycleStage
}) => {
    const [
      currentProcess,
      setCurrentProcess,
      _,
      addExchanges
    ] = handleLifeCycleStage.currentProcess
    const [{ units }] = useGlobalState("globalState")
    const [search, setSearch] = useSearchBar()
    const [flowRows, setFlowRows] = useState<FlowRow[]>([])
    const [initialFlowRows, setInitialFlowRows] = useState<FlowRow[]>([])
    const { flows } = useGlobalState("globalState")[0]
    const toast = useToast()
    const [selectedRowsID, setSelectedRowsID] = useState<GridRowId[]>([])

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 3 / 8 },
      { field: 'unit', headerName: 'Unit', flex: 2 / 8 },
      { field: 'description', headerName: 'Description', flex: 3 / 8 },
    ];

    useEffect(() => {
      const formatFlows = async (flows: Ref[]) => {
        const rows = flows.map((flow: Ref, idx: number) => (
          {
            id: idx,
            flowId: flow['@id'],
            name: flow.name as string,
            description: flow.description as string,
            unit: flow.refUnit as string
          }
        ))
        setFlowRows(rows)
        setInitialFlowRows(rows)
      };
      if (flows) {
        formatFlows(flows)
      }
    }, [flows])

    const handleSelection = (selectionModel: GridRowId[]) => {
      setSelectedRowsID(selectionModel)
    }

    useEffect(() => {
      if (search)
        setFlowRows(initialFlowRows.filter(
          row => row.name.includes(search)
        ))
    }, [search])

    const addExchangesToEntity = () => {
      try {
        const flowIds = selectedRowsID.map(rowId =>
          flowRows.find(row =>
            row.id === rowId
          )?.flowId as string
        )
        const exchanges: Exchange[] = flows.filter(f =>
          flowIds.includes(f['@id'])
        ).map((f, idx) => ({
          "@type": "Exchange",
          flow: f,
          amount: 0,
          isInput: role === Role.INPUT,
          unit: units.find(unit =>
            unit.name === f.refUnit
          ) as Ref,
        }))
        addExchanges(exchanges)
      } catch (error) {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    const handleClose = () => {
      setSelectedRowsID([])
      onClose()
    }

    const handleAddEntity = () => {
      addExchangesToEntity()
      setSelectedRowsID([])
      onClose()
    }

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'3xl'}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add component</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex >
              <VStack w="100%">
                <SearchBar
                  style={{ width: "100%" }}
                  search={search}
                  setSearch={setSearch}
                  placeholder={"Component name"}
                />
                <Box w="100%" h="300px">
                  <DataGrid
                    className="datagridModal"
                    rows={flowRows}
                    columns={columns}
                    checkboxSelection
                    autoPageSize
                    pagination
                    density="compact"
                    onSelectionModelChange={handleSelection}
                    selectionModel={selectedRowsID}
                  />
                </Box>
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="outline"
              onClick={handleAddEntity}>
              Add components
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >

    );
  };

export default AddComponent
