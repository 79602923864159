/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createUser,
  deleteCurrentUser,
  getCurrentUser,
  updateCurrentUser
} from "../api/user.service";
import { useGlobalState } from "../store/globalState";
import { InfiniteUser, InfiniteUserUpdate } from "../store/schema";
import { getErrorMessage } from "../utils/utils";


type userHook = () => {
  createUser: (
    email: string,
    firstName: string,
    lastName: string,
    password: string
  ) => Promise<InfiniteUser>,
  getCurrentUser: () => Promise<void>,
  updateUser: (userUpdate: InfiniteUserUpdate) => Promise<void>,
  deleteUser: () => Promise<void>
}
export const useUser: userHook = () => {
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const setAuthState = useGlobalState("authState")[1]

  const newUser = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string
  ): Promise<InfiniteUser> => {
    try {
      const user = await createUser(email, firstName, lastName, password)
      return user
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchCurrentUser = async (): Promise<void> => {
    try {
      const user = await getCurrentUser()
      setGlobalState({ ...globalState, user })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }
  const updateUser = async (userUpdate: InfiniteUserUpdate): Promise<void> => {
    try {
      const user = await updateCurrentUser(userUpdate)
      setGlobalState({ ...globalState, user })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }
  const deleteUser = async (): Promise<void> => {
    try {
      await deleteCurrentUser()
      setAuthState({ isAuthenticated: false })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  return {
    createUser: newUser,
    getCurrentUser: fetchCurrentUser,
    updateUser,
    deleteUser
  }
}
