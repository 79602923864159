/* eslint-disable indent */

import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Switch,
  useToast,
  VStack
} from "@chakra-ui/react";
import {
  Flow,
  FlowCreate,
  FlowType,
  InfiniteLifeCycleStageCreate,
  LifeCycleStageCategory,
  Process,
  ProcessCreate,
  ProcessType,
  Ref
} from "../../store/schema";
import {
  createFlow,
  createProcess,
  getProcessDescriptors
} from "../../api/olca.service";
import SearchBar from "../SearchBar";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import useSearchBar from "../../hook/SearchBar";
import { useGlobalState } from "../../store/globalState";

type AddLifeCycleStageProps = {
  category: string,
  handleAddLifeCycleStage: (lcs: InfiniteLifeCycleStageCreate) => Promise<void>
  isOpen: boolean,
  onClose: () => void,
}

type ProcessRow = {
  id: number,
  processId: string,
  name: string,
}

function AddLifeCycleStage({
  category,
  handleAddLifeCycleStage,
  isOpen,
  onClose
}: AddLifeCycleStageProps): JSX.Element {
  const initialRef = useRef(null)
  const [{ flowProperties }] = useGlobalState("globalState")

  const [name, setName] = useState<string>("")
  const [isNewProcess, setIsNewProcess] = useState<boolean>(false)
  const [referenceFlowName, setReferenceFlowName] = useState<string>("")
  const [
    flowPropertyId,
    setFlowPropertyId
  ] = useState<string | undefined>(undefined)
  const [flowProperty, setFlowProperty] = useState<Ref>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [processes, setProcesses] = useState<Ref[]>([])

  const [search, setSearch] = useSearchBar()

  const [processRows, setProcessRows] = useState<ProcessRow[]>([])
  const [
    initialProcessRows,
    setInitialProcessRows
  ] = useState<ProcessRow[]>([])
  const [selectedRowID, setSelectedRowID] = useState<GridRowId>()

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
  ];

  const toast = useToast()

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    setter:
      React.Dispatch<React.SetStateAction<string>> |
      React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    const value = event.target.value
    setter(value)
  }

  const handleSelection = (rowId: GridRowId) => {
    setSelectedRowID(rowId)
  }

  const handleClose = () => {
    setSelectedRowID(undefined)
    setName("")
    setSearch("")
    setIsNewProcess(false)
    setReferenceFlowName("")
    setFlowPropertyId(undefined)
    onClose()
  }

  const handleCreateFlow = async (flow: FlowCreate): Promise<Flow> => {
    return await createFlow(flow)
  }

  const handleCreateProcess =
    async (process: ProcessCreate): Promise<Process> => {
      return await createProcess(process)
    }

  const handleAddLCS = () => {
    if (isNewProcess) {
      if (!referenceFlowName || !flowProperty) return
      const newFlow: FlowCreate = {
        "@type": "Flow",
        name: referenceFlowName,
        flowType: FlowType.PRODUCT_FLOW,
        flowProperties: [
          {
            "@type": "FlowPropertyFactor",
            conversionFactor: 1,
            flowProperty: flowProperty,
            isReferenceFlowProperty: true,
          }
        ]
      }
      handleCreateFlow(newFlow)
        .then((flow) => {
          if (!flow) return
          const newProcess: ProcessCreate = {
            "@type": "Process",
            name: name,
            processType: ProcessType.UNIT_PROCESS,
            exchanges: [
              {
                "@type": "Exchange",
                flow: flow as Ref,
                amount: 1,
                isInput: false,
                isQuantitativeReference: true,
                flowProperty,
              }
            ]
          }
          handleCreateProcess(newProcess)
            .then((process) => {
              if (!process) return
              const lcs: InfiniteLifeCycleStageCreate = {
                name,
                category: category
                  .replaceAll(" ", "_")
                  .toUpperCase() as LifeCycleStageCategory,
                processRefId: process["@id"],
              }
              handleAddLifeCycleStage(lcs)
                .then(() => {
                  toast.closeAll()
                  toast({
                    position: "top",
                    title: "Creation",
                    description: `Life cycle stage added succesfully`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                  })
                })
                .catch((error) => {
                  toast.closeAll()
                  toast({
                    position: "top",
                    title: "Error",
                    description: error as string,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                  })
                })
              handleClose()
            })
            .catch((error) => {
              toast({
                title: "Error",
                description: error as string,
                status: "error",
                duration: 5000,
                isClosable: true,
              })
            })
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: error as string,
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        })
    } else {
      const processRefId =
        processRows.find(row => row.id === selectedRowID)?.processId
      if (!processRefId) return
      const lcs: InfiniteLifeCycleStageCreate = {
        name,
        category: category
          .replaceAll(" ", "_")
          .toUpperCase() as LifeCycleStageCategory,
        processRefId: processRefId,
      }
      handleAddLifeCycleStage(lcs)
        .then(() => {
          toast.closeAll()
          toast({
            position: "top",
            title: "Creation",
            description: `Life cycle stage added succesfully`,
            status: "success",
            duration: 4000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast.closeAll()
          toast({
            position: "top",
            title: "Error",
            description: error as string,
            status: "error",
            duration: 4000,
            isClosable: true,
          })
        })
      handleClose()
    }
  }

  useEffect(() => {
    const fetchProcesses = async () => {
      setProcesses(await getProcessDescriptors())
    }
    setIsLoading(true)
    fetchProcesses()
      .then(() => setIsLoading(false))
      .catch((error) => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      })

  }, [])

  useEffect(() => {
    setFlowProperty(flowProperties.find(fp => fp["@id"] === flowPropertyId))
  }, [flowPropertyId])

  useEffect(() => {
    const formatProcesses = (processes: Ref[]) => {
      const rows = processes.map((process: Ref, idx: number) => {
        let name = process.name;
        if (typeof process.location !== "undefined") {
          name = name + " - " + process.location;
        }
        return {
          id: idx,
          processId: process['@id'],
          name: name as string
        };
      })
      setProcessRows(rows)
      setInitialProcessRows(rows)
    }
    if (processes) {
      formatProcesses(processes)
    }
  }, [processes])

  useEffect(() => {
    if (!search) {
      setProcessRows(initialProcessRows)
    } else {
      setProcessRows(initialProcessRows.filter(
        p => p.name.toLowerCase().includes(search.toLowerCase())
      ))
    }
  }, [search])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={initialRef}
      size={"4xl"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add {category} life cycle stage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                type='text'
                value={name}
                onChange={(e) => handleInputChange(e, setName)} />
            </FormControl>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='new-process' mb='0'>
                Create a new process?
              </FormLabel>
              <Switch
                id='new-process'
                isChecked={isNewProcess}
                onChange={() => setIsNewProcess(!isNewProcess)}
              />
            </FormControl>
            {isNewProcess ? (
              <>
                <FormControl isRequired>
                  <FormLabel>Name of the reference flow</FormLabel>
                  <Input
                    type='text'
                    value={referenceFlowName}
                    onChange={
                      (e) => handleInputChange(e, setReferenceFlowName)
                    } />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Reference flow property</FormLabel>
                  <Select
                    placeholder='Select reference flow property'
                    value={flowPropertyId}
                    onChange={(e) => handleInputChange(e, setFlowPropertyId)}>
                    {flowProperties.map((flowProperty) => (
                      <option
                        key={flowProperty["@id"]}
                        value={flowProperty["@id"]}
                      >
                        {flowProperty.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <FormControl>
                <FormLabel>Select a process</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Flex>
                    <VStack w="100%">
                      <SearchBar
                        style={{ width: "100%" }}
                        search={search}
                        setSearch={setSearch}
                        placeholder={"Process name"}
                      />
                      <Box w="100%" h="300px">
                        <DataGrid
                          className="datagridModal"
                          rows={processRows}
                          columns={columns}
                          autoPageSize
                          pagination
                          density="compact"
                          onRowClick={
                            (params) => handleSelection(params.row.id)
                          }
                        />
                      </Box>
                    </VStack>
                  </Flex>
                </Skeleton>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => { handleAddLCS(); handleClose(); }}
            disabled={
              isNewProcess ?
                (!name || !referenceFlowName || !flowPropertyId) :
                (!name || !selectedRowID)
            }
          >
            Add life cycle stage
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddLifeCycleStage
