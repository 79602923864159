import { GridSelectionModel } from "@mui/x-data-grid"
import { ChartDataset, ChartOptions } from "chart.js"
import { PluginOptions } from "chartjs-plugin-stacked100/build/types"
import {
  CalculationState,
  InfiniteBuilding,
  InfiniteEntityRef,
  InfiniteEntityType,
  InfiniteKit,
  InfiniteLifeCycleStage,
  InfiniteUser,
  Process,
  Ref,
  SimpleResult,
} from "./schema"

export enum UserRole {
  ADMINISTRATOR = "administrator",
  FINAL_USER = "final_user",
  ENERGY_FACILITY_MANAGER = "energy_facility_manager",
  INVESTORS_CUSTOMERS = "inverstors_customers",
  DESIGN_TEAM = "design_team",
  LCA_LCC_SPECIALIST = "lca_lcc_specialist",
  MANUFACTURER_INSTALLER = "manufacturer_installer",
  TECHNOLOGY_PROVIDER = "technology_provider"
}

export enum LCSName {
  MANUFACTURING = "Manufacturing",
  PACKAGING = "Packaging",
  TRANSPORT = "Transport",
  INSTALLATION = "Installation",
  USE = "Use",
  MAINTENANCE = "Maintenance",
  END_OF_LIFE = "End of Life"
}

export enum Role {
  INPUT = "input",
  OUTPUT = "output"
}

export type User = {
  firstName: string,
  email: string,
  id: number,
  password: string,
  role: UserRole,
}

export type AuthState = {
  isAuthenticated: boolean,
  loading?: boolean,
  token?: Token,
  error?: boolean
}

export type CalculationInfo = {
  calculationStates: CalculationState[][],
  infiniteEntities: InfiniteEntity[],
  isInfiniteKit: boolean,
  assessmentMethod: Ref
}

export type GlobalState = {
  user: InfiniteUser,
  isAuthStateSave: boolean,
  kits: InfiniteKit[],
  buildings: InfiniteBuilding[],
  components?: Component[],
  defaultLifeCycleStages: InfiniteLifeCycleStage[],
  customLifeCycleStages: InfiniteLifeCycleStage[],
  flowProperties: Ref[],
  locations: InfiniteEntityRef[],
  methods: Ref[],
  flows: Ref[],
  units: Ref[],
  results: SimpleResult[][],
  selectedStageIndex: GridSelectionModel,
  processes: Process[],
  calculationInfo?: CalculationInfo
}

export type InfiniteEntity = InfiniteBuilding | InfiniteKit
export type ComponentTypeInput = "Resource" | "Product"
export type ComponentTypeOutput = "Waste" | "Emission"
export type ComponentType = ComponentTypeInput | ComponentTypeOutput

export type Component = {
  id: number,
  name: string,
  description: string,
  creationDate: Date,
  type: ComponentType,
  location: string,
  role: Role
}

export type ComponentData = {
  amount: number
}

export type KitStage = {
  input: Component[],
  output: Component[]
}
export type Document = {
  name: string;
  file: string | ArrayBuffer | null,
  downloadLink: string;
  fileType: string,
  id: string
}

export const EntityStatus = {
  "PRELIMINARY": "Preliminary",
  "WORK_IN_PROGRESS": "Work in progress",
  "FINAL": "Final"
}

export type InfiniteEntityAction = { actionLabel: string, action: () => void }
export const dummyInfiniteEntity: InfiniteEntityRef = { id: "-1", name: "" }

export const dummyProcess: Process = {
  "@context": "",
  "@id": "-1",
  "@type": "Process"
}

export const dummyRef: Ref = { "@id": "", "@type": "Ref", name: "" }

export type LCAStage = {
  name: string,
  description: string,
  index: number
}
export const LCAStages: {
  Manufacturing: LCAStage,
  Packaging: LCAStage,
  Transport: LCAStage,
  Installation: LCAStage,
  Use: LCAStage,
  Maintenance: LCAStage,
  EndOfLife: LCAStage,
} = {
  Manufacturing: {
    name: LCSName.MANUFACTURING,
    description: "Manufacturing stage",
    index: 0
  },
  Packaging: {
    name: LCSName.PACKAGING,
    description: "Packaging stage",
    index: 1
  },
  Transport: {
    name: LCSName.TRANSPORT,
    description: "Transport stage",
    index: 2
  },
  Installation: {
    name: LCSName.INSTALLATION,
    description: "Installation stage",
    index: 3
  },
  Use: {
    name: LCSName.USE,
    description: "Use stage",
    index: 4
  },
  Maintenance: {
    name: LCSName.MAINTENANCE,
    description: "Maintenance stage",
    index: 5
  },
  EndOfLife: {
    name: LCSName.END_OF_LIFE,
    description: "End Of Life stage",
    index: 6
  }
}

export interface Token {
  accessToken: string,
  tokenType: string
}

export const anonymousKit: InfiniteKit = {
  id: -1,
  name: "",
  description: "",
  lastChange: new Date().toISOString(),
  lifeCycleStages: [],
  technicalSpecs: [],
  flowPropertyRef: { id: "-1", name: "" },
  locationRef: { id: "-1", name: "" },
  referenceAmount: 0,
  unitRef: { id: "-1", name: "" },
  manufacturer: "",
  type: InfiniteEntityType.DEFAULT
}

export const anonymousCustomKit: InfiniteKit = {
  id: 0,
  name: "",
  description: "",
  lastChange: new Date().toISOString(),
  lifeCycleStages: [],
  technicalSpecs: [],
  flowPropertyRef: { id: "-1", name: "" },
  locationRef: { id: "-1", name: "" },
  referenceAmount: 0,
  unitRef: { id: "-1", name: "" },
  manufacturer: "",
  type: InfiniteEntityType.CUSTOM
}

export const anonymousBuilding: InfiniteBuilding = {
  id: -1,
  name: "",
  description: "",
  lastChange: new Date().toISOString(),
  lifeCycleStages: [],
  technicalSpecs: [],
  flowPropertyRef: { id: "-1", name: "" },
  locationRef: { id: "-1", name: "" },
  referenceAmount: 0,
  unitRef: { id: "-1", name: "" },
  type: InfiniteEntityType.DEFAULT
}

export type Dataset = ChartDataset<"bar", number[]>

export type ChartOptionsBar = ChartOptions<"bar"> & {
  plugins: {
    stacked100?: PluginOptions
  }
}
