import { CheckCircleIcon } from "@chakra-ui/icons"
import {
  Box,
  Button as Btn,
  Grid,
  HStack,
  Spacer,
  VStack
} from "@chakra-ui/react"
import { Button } from "@material-ui/core"
import React, { FC, useState } from "react"
import { v4 as uuid } from "uuid"
import {
  useHandleModifyEntity
} from "../../hook/handleInfiniteEntityModification"
import { Document, InfiniteEntity } from "../../store/types"
import DocumentCard from "./DocumentCard"

const FileUpload: FC<{
  entity: InfiniteEntity
  handleModifyEntity: useHandleModifyEntity
  onSave: () => void
}> = ({ entity, handleModifyEntity, onSave }) => {
  const [documents, setDocuments] = useState<Document[]>([])
  const isModificationEnabled = handleModifyEntity.isModificationEnabled
  const [touched, setTouched] = handleModifyEntity.touched

  const handleDelete = (id: string) => {
    setDocuments(documents.filter((document) => document.id !== id))
    setTouched(true)
  }

  const handleUpload = ({
    target,
  }: {
    target: EventTarget & HTMLInputElement
  }) => {
    const fileReader = new FileReader()
    const file = target?.files?.[0]
    if (!file) return
    fileReader.readAsDataURL(file)
    fileReader.onload = async () => {
      if (!fileReader.result) return
      const fileType = (fileReader.result as string)
        .split(";")[0]
        .split(":")[1]
      const b64 = await fetch(fileReader.result as string)
      const blob = await b64.blob()
      const document: Document = {
        name: file.name,
        file: fileReader.result,
        downloadLink: URL.createObjectURL(blob),
        fileType: fileType,
        id: uuid(),
      }
      setDocuments([...documents, document])
      setTouched(true)
    }
  }

  return (
    <VStack>
      <Box alignSelf={"start"} mb={5}>
        <Button
          color={"primary"}
          variant="contained"
          component="label"
          disabled={!isModificationEnabled}
        >
          Upload File
          <input
            type="file"
            hidden
            onChange={handleUpload}
            disabled={!isModificationEnabled}
          />
        </Button>
      </Box>
      <Grid templateColumns='repeat(4, 1fr)' gap={3}>
        {documents.map((doc) => (
          <DocumentCard
            key={doc.id}
            document={doc}
            handleDelete={() => handleDelete(doc.id)}
          />
        ))}
      </Grid>
      <HStack w="100%" pt={5}>
        <Spacer />
        <Btn
          disabled={!touched}
          alignSelf="end"
          mr={5}
          leftIcon={<CheckCircleIcon color={"green"} />}
          onClick={onSave}
        >
          Save modification
        </Btn>
      </HStack>
    </VStack>
  )
}
export default FileUpload
