/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Paper } from '@material-ui/core';
import { Chart, ChartData, TooltipItem } from 'chart.js';
import 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { ExtendedChartData } from 'chartjs-plugin-stacked100/build/types';
import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptionsBar } from '../../store/types';
import { formatNumber } from '../../utils/utils';
import sortPlugin from './sortPlugin';
/**
 * Component that draw a horizontal stacked bar chart.
 * It is using the framework `chart.js`, for drawing the chart,
 * and also an external plugin `chartjs-plugin-stacked100`
 * @param data The chart data
 * @returns
 */
export const ImpactCategoryChart: FC<{
  data: ChartData<"bar">
}> = ({ data }) => {
  Chart.register(ChartjsPluginStacked100)
  const options: ChartOptionsBar = {
    indexAxis: "y", // Rotate the chart, to have horizontal bars
    scales: { // Stack the bars
      x: {
        title: {
          display: true,
          text: "Impact contribution amount",
          font: {
            size: 20
          }
        },

        max: 100,
        ticks: {
          // Include a percent sign in the ticks
          callback: (value: string | number) => {
            return value.toString() + " %";
          }
        },
      },
      y: {
        title: {
          display: true,
          text: "Impact categories",
          font: {
            size: 20
          }
        },

      }
    },
    interaction: { // Tooltip interaction
      intersect: false, // Tooltip is displayed anytime
      mode: 'nearest', // Tooltip display all information about the nearest bar
      axis: 'y'
    },
    plugins: {
      stacked100: {
        enable: true,
        replaceTooltipLabel: false,
        precision: 3,
        fixNegativeScale: false,
        individual: true
      },
      title: { // Chart title
        display: true,
        text: 'Total impact contributions',
        font: {
          size: 30
        }
      },
      tooltip: {
        callbacks: {
          // Remove "," from the tooltip title
          title: (titleItem: TooltipItem<"bar">[]) => {
            return titleItem[0].label.split(", ").join(" ")
          },
          label: (tooltipItem: TooltipItem<"bar">) => {
            const data = tooltipItem.chart.data as ExtendedChartData
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.dataIndex;
            const datasetLabel = data.datasets[datasetIndex].label || "";
            const originalValue =
              formatNumber(data.originalData?.[datasetIndex][index] as number)
            const rateValue =
              data.calculatedData?.[datasetIndex][index] as number
            return `${datasetLabel}: ${originalValue} ( ${rateValue}% )`;
          }
        },

        // Sort the tooltip items by descending order
        itemSort: (
          item1: TooltipItem<"bar">,
          item2: TooltipItem<"bar">
        ) => (item2.raw as number) - (item1.raw as number),
      },
    }
  };

  return (
    <Paper style={{ width: "100%" }}>
      <Bar
        data={data}
        options={options}
        plugins={[sortPlugin]}
      />
    </Paper>
  )
}
