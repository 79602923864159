/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createKit,
  deleteKit,
  getCustomKits,
  getDefaultKits,
  getKit,
  updateKit
} from "../api/kit.service";
import { useGlobalState } from "../store/globalState";
import {
  InfiniteBuilding,
  InfiniteCustomKit,
  InfiniteEntityStatus,
  InfiniteEntityType,
  InfiniteKit,
  InfiniteKitCreate,
  InfiniteKitUpdate,
  InfiniteLifeCycleStage
} from "../store/schema";
import { InfiniteEntity } from "../store/types";
import { getErrorMessage } from "../utils/utils";

enum Action {
  ADD, DELETE
}
const updateKits = <T extends InfiniteKit>(
  kits: T[],
  modifiedKits: number[] | T[],
  action: Action
) => {
  if (action === Action.DELETE) {
    return kits.filter(
      k => !(modifiedKits as number[])
        .some(modifiedKitId => modifiedKitId === k.id)
    )
  }
  (modifiedKits as T[]).forEach(modifiedKit => {
    const index = kits.findIndex((k) => k.id === modifiedKit.id)
    if (index === -1) {
      kits.push(modifiedKit);
    } else {
      kits[index] = modifiedKit;
    }
  })
  return kits
}

const updateCustomeLifeCycleStages = (
  lifeCycleStages: InfiniteLifeCycleStage[],
  modifiedLifeCycleStages: InfiniteLifeCycleStage[],
  action: Action
) => {
  if (action === Action.DELETE) {
    return lifeCycleStages.filter(
      k => !modifiedLifeCycleStages
        .some(modifiedKit => modifiedKit.id === k.id)
    )
  }
  modifiedLifeCycleStages.forEach(modifiedKit => {
    const index = lifeCycleStages.findIndex((k) => k.id === modifiedKit.id)
    if (index === -1) {
      lifeCycleStages.push(modifiedKit);
    } else {
      lifeCycleStages[index] = modifiedKit;
    }
  })
  return lifeCycleStages
}


type kitHook = () => {
  getDefaultKits: () => Promise<InfiniteKit[]>;
  getDefaultKit: (kitId: number) => Promise<void>;
  getCustomKits: () => Promise<InfiniteKit[]>;
  getCustomKit: (kitId: number) => Promise<void>;
  createCustomKit: (
    kit: InfiniteKit | InfiniteKitCreate
  ) => Promise<InfiniteKit>;
  updateCustomKit: (id: number, kitUpdate: InfiniteKitUpdate) => Promise<void>;
  deleteCustomKit: (kitIds: number[]) => Promise<void>;
}
export const useKit: kitHook = () => {
  const [globalState, setGlobalState] = useGlobalState("globalState")

  const fetchDefaultKits = async (): Promise<InfiniteKit[]> => {
    try {
      return await getDefaultKits()
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchDefaultKit = async (kitId: number): Promise<void> => {
    try {
      const kit = await getKit(kitId)
      const kits = updateKits([...globalState.kits], [kit], Action.ADD)
      setGlobalState({ ...globalState, kits: { ...kits } })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchCustomKits = async (): Promise<InfiniteKit[]> => {
    try {
      return await getCustomKits()
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const fetchCustomKit = async (kitId: number): Promise<void> => {
    try {
      const kit = await getKit(kitId)
      const kits = updateKits([...globalState.user.kits], [kit], Action.ADD)
      setGlobalState({ ...globalState, user: { ...globalState.user, kits } })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const newCustomKit = async (
    kit: InfiniteKit | InfiniteKitCreate
  ): Promise<InfiniteKit> => {
    try {
      const newKit = await createKit(
        kit.name,
        kit.description,
        kit.manufacturer,
        kit.referenceAmount,
        kit.unitRef.id,
        kit.flowPropertyRef.id,
        kit.lifeCycleStages,
        kit.status || InfiniteEntityStatus.PRELIMINARY
      )
      const updatedKit = await getKit(newKit.id)
      const kits =
        updateKits([...globalState.user.kits], [updatedKit], Action.ADD)
      setGlobalState({ ...globalState, user: { ...globalState.user, kits } })
      return updatedKit
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const modifyCustomKit = async (
    id: number,
    kitUpdate: InfiniteKitUpdate
  ): Promise<void> => {
    try {
      const kit = await updateKit(id, kitUpdate)
      const kits = updateKits([...globalState.user.kits], [kit], Action.ADD)
      setGlobalState({ ...globalState, user: { ...globalState.user, kits } })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  const removeCustomKit = async (kitIds: number[]): Promise<void> => {
    try {
      const kitsToRemove = []
      for (const kitId of kitIds) {
        await deleteKit(kitId)
        kitsToRemove.push(kitId)
      }

      const kits = updateKits(
        globalState.user.kits,
        kitsToRemove, Action.DELETE
      )
      setGlobalState({ ...globalState, user: { ...globalState.user, kits } })
    } catch (error) {
      throw getErrorMessage(error)
    }
  }

  return {
    getDefaultKits: fetchDefaultKits,
    getDefaultKit: fetchDefaultKit,
    getCustomKits: fetchCustomKits,
    getCustomKit: fetchCustomKit,
    createCustomKit: newCustomKit,
    updateCustomKit: modifyCustomKit,
    deleteCustomKit: removeCustomKit
  }
}

export const formatKitRefAmount = (kit: InfiniteKit): string => {
  let ref = kit.referenceAmount.toString()
  if (kit.unitRef) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    ref += " " + kit.unitRef.name
  }
  return ref
}

export const isInfiniteCustomKit =
  (entity: InfiniteEntity): entity is InfiniteCustomKit => {
    return ((entity as InfiniteBuilding).locationRef === undefined) &&
      entity.type === InfiniteEntityType.CUSTOM
  }
