/* eslint-disable indent */

import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useGlobalState } from "../../store/globalState";
import { InfiniteBuildingCreate, Unit } from "../../store/schema";
import { getFlowProperty, getUnitGroup } from "../../api/olca.service";
import { useBuilding } from "../../services/buildings.service";

type AddBuildingProps = {
  isOpen: boolean,
  onClose: () => void,
}

function AddBuilding({
  isOpen,
  onClose
}: AddBuildingProps): JSX.Element {
  const initialRef = useRef(null)
  const [{ flowProperties, locations }] = useGlobalState("globalState")
  const [units, setUnits] = useState<Unit[]>([])

  const [name, setName] = useState<string | undefined>(undefined)
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [location, setLocation] = useState<string | undefined>(undefined)
  const [
    flowProperty,
    setFlowProperty
  ] = useState<string | undefined>(undefined)
  const [unit, setUnit] = useState<string | undefined>(undefined)
  const [amount, setAmount] = useState<number>(1)

  const { createCustomBuilding } = useBuilding()

  const toast = useToast()

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    setter: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    const value = event.target.value
    setter(value)
  }

  const handleCreate = () => {
    if (!name || !unit || !flowProperty || !location) return
    const building: InfiniteBuildingCreate = {
      name,
      description,
      locationRef: {
        id: location
      },
      flowPropertyRef: {
        id: flowProperty
      },
      unitRef: {
        id: unit
      },
      referenceAmount: amount,
      lifeCycleStages: []
    }
    createCustomBuilding(building)
      .catch((error) => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      })
  }

  useEffect(() => {
    if (!flowProperty) return
    const fetchFlowProperty = async () => {
      const fp = await getFlowProperty(flowProperty)
      if (!fp.unitGroup) return
      const ug = await getUnitGroup(fp.unitGroup["@id"])
      if (!ug.units) return
      setUnits(ug.units)
    }
    fetchFlowProperty()
      .catch((error) => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      })

  }, [flowProperty])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new building renovation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                type='text'
                value={name}
                onChange={(e) => handleInputChange(e, setName)} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => handleInputChange(e, setDescription)} />
            </FormControl>
            <FormControl>
              <FormLabel>Location</FormLabel>
              <Select
                placeholder='Select building renovation flow property'
                value={location}
                onChange={(e) => handleInputChange(e, setLocation)}>
                {locations.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Flow property</FormLabel>
              <Select
                placeholder='Select building renovation flow property'
                value={flowProperty}
                onChange={(e) => handleInputChange(e, setFlowProperty)}>
                {flowProperties.map((flowProperty) => (
                  <option
                    key={flowProperty["@id"]}
                    value={flowProperty["@id"]}
                  >
                    {flowProperty.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Unit</FormLabel>
              <Select
                placeholder='Select building renovation unit'
                value={unit}
                onChange={(e) => handleInputChange(e, setUnit)}>
                {units.map((unit) => (
                  <option key={unit["@id"]} value={unit["@id"]}>
                    {unit.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Amount</FormLabel>
              <NumberInput
                value={amount}
                precision={2}
                step={0.01}
                onChange={(v) => setAmount(Number(v))} >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => { handleCreate(); onClose(); }}>
            Add Building Renovation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddBuilding
