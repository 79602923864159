/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Flex, HStack, Link, Text, useToast } from '@chakra-ui/react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import {
  formatBuildingRefAmount,
  useBuilding
} from '../../services/buildings.service';
import { useGlobalState } from '../../store/globalState';
import {
  InfiniteBuilding,
  InfiniteCustomBuilding,
  InfiniteEntityStatus
} from '../../store/schema';
import { Copy, Info, RunLCA } from '../Icons/icons';
import StatusChip from '../StatusChipMenu/StatusChip';

type BuildingRow = {
  id: number,
  buildingId: number,
  name: string,
  description: string,
  lastChange: string,
  status: InfiniteEntityStatus
}
type State = {
  buildingsRow: GridRowsProp,
  initialBuildingsRow: BuildingRow[],
  selectedRowsID: GridRowId[],
}

const DefaultBuildingLibrary: FC<{
  searchString: string,
  setBuildingIds: Dispatch<SetStateAction<number[]>>,
  onCalculationOpen: () => void
}> = ({
  searchString,
  setBuildingIds,
  onCalculationOpen
}) => {
    const { createCustomBuilding } = useBuilding()
    const toast = useToast()
    const history = useHistory();

    const [globalState] = useGlobalState("globalState")
    const { buildings } = globalState
    const [state, setState] = useState<State>({
      buildingsRow: [],
      initialBuildingsRow: [],
      selectedRowsID: [],
    })
    const { buildingsRow, initialBuildingsRow, selectedRowsID } = state
    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 6 / 24 },
      { field: 'referenceAmount', headerName: 'Reference amount', flex: 4 / 24 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 3 / 24,
        renderCell: (params: GridCellParams) => (
          <StatusChip status={params.row.status} />)
      },
      { field: 'description', headerName: 'Description', flex: 6 / 24 },
      {
        field: 'lastChange',
        headerName: 'Last change',
        flex: 3 / 24,
        type: "date",
        valueFormatter: (params: GridValueFormatterParams) => {
          return new Date(params.value as Date).toLocaleDateString()
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 3 / 24,
        align: "right",
        disableColumnMenu: true,
        headerAlign: "right",
        renderCell: (params: GridCellParams) => (
          <HStack>
            <Link
              as={ReachLink}
              to={`/buildings/${params.row.buildingId as number}`}
            >
              <Info />
            </Link>
            <Link
              as={ReachLink}
              to={`#`}
              onClick={() => handleCopy(params.row as BuildingRow)}
            >
              <Copy />

            </Link>
            <Link as={ReachLink} to="#" onClick={() => handleRowLCA(params)}>
              <RunLCA color />
            </Link>
          </HStack>
        )
      }
    ];

    useEffect(() => {
      const buildingIds = selectedRowsID.map(rowId => {
        const buildingId = (buildingsRow.find(
          row => row.id === rowId
        ) as BuildingRow).buildingId
        return buildingId
      })
      setBuildingIds(buildingIds)
    }, [selectedRowsID])

    const handleCopy = async (buildingRow: BuildingRow) => {
      try {
        const findBuilding = {
          ...buildings.find(
            building => building.id === buildingRow.buildingId
          ) as InfiniteCustomBuilding
        }
        findBuilding.name += " (copy)"

        await createCustomBuilding(findBuilding)
        toast({
          position: "top",
          title: "Success",
          description: "Building copied successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      } catch (error) {
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    useEffect(() => {
      /**
        * Filter the buildings on name, description or creation date.
        * It is case insensitive
        * @returns The filtered rows array
        */
      const filterRows = () => {
        const formatedSearch = searchString.toLowerCase()
        return initialBuildingsRow.filter(row =>
          row.name.toLowerCase().includes(formatedSearch)
        )
      }

      setState({ ...state, buildingsRow: filterRows() })
    }, [searchString])

    useEffect(() => {
      const formatBuildings = (buildings: InfiniteBuilding[]) => {
        const rows: BuildingRow[] = buildings.map(
          (building: InfiniteBuilding, idx: number) => (
            {
              id: idx,
              buildingId: building.id,
              name: building.name,
              referenceAmount: formatBuildingRefAmount(building),
              location: building.locationRef.name,
              lastChange: building.lastChange,
              description: building.description,
              status: building.status || InfiniteEntityStatus.PRELIMINARY
            }
          ))
        setState({ ...state, buildingsRow: rows, initialBuildingsRow: rows })

      }
      if (buildings)
        formatBuildings(buildings)
    }, [buildings])

    const handleSelection = (selectionModel: GridRowId[]) => {
      setState({ ...state, selectedRowsID: selectionModel })
    }

    const handleDoucleClick = (param: GridRowParams) => {
      history.push(`/buildings/${param.row.buildingId as string}`)
    }

    const handleRowLCA = (params: GridCellParams) => {
      setState({ ...state, selectedRowsID: [params.row.id] })
      onCalculationOpen()
    }

    return (
      <>
        <Flex h="69vh" w="100%">
          <DataGrid
            rows={buildingsRow}
            columns={columns}
            checkboxSelection
            autoPageSize
            pagination
            density="compact"
            onSelectionModelChange={handleSelection}
            onRowDoubleClick={handleDoucleClick}
            components={{
              Toolbar: CustomToolbar
            }}
            selectionModel={selectedRowsID}
            disableSelectionOnClick />
        </Flex>
      </>
    );
  };

const CustomToolbar: FC = () => {
  return (
    <Flex>
      <Text ml={5} fontSize={"lg"}>INFINITE building library</Text>
    </Flex>
  );
}

export default DefaultBuildingLibrary
