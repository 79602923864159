/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CheckCircleIcon } from "@chakra-ui/icons"
import { Box, Flex, HStack, VStack } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/react"
import {
  MenuItem,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField
} from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import { getProviderByFlow } from "../../api/olca.service"
import {
  useHandleLifeCycleStagesProps
} from "../../hook/handleInfiniteEntityLifeCycleStages"
import {
  useHandleModifyEntity
} from "../../hook/handleInfiniteEntityModification"
import { LifeCycleStageCategory, Provider } from "../../store/schema"
import { LCSName, Role } from "../../store/types"
import { KitLifeCycleTable } from "./KitLifeCycleTable"

export const KitLifeCycle: FC<{
  handleModifyKit: useHandleModifyEntity,
  handleLifeCycleStages: useHandleLifeCycleStagesProps,
  onSave: () => void,
  touched: boolean
}> = ({
  handleModifyKit,
  handleLifeCycleStages,
  onSave,
  touched
}) => {
    const [
      selectedStagesCategory,
      setSelectedStagesCategory
    ] = handleLifeCycleStages.selectedStagesCategory
    const [
      activeStep,
      setActiveStepAndStage
    ] = handleLifeCycleStages.activeStepAndStage
    const [
      currentProcess,
      setCurrentProcess
    ] = handleLifeCycleStages.currentProcess
    const input = currentProcess.exchanges?.filter(e => e.isInput) || []
    const output = currentProcess.exchanges?.filter(e => !e.isInput) || []
    const [providers, setProviders] = useState<Provider[]>([])
    const [description, setDescription] = handleLifeCycleStages.description
    const [
      referenceAmount,
      setReferenceAmount
    ] = handleModifyKit.referenceAmount
    const [unitRef, setUnitRef] = handleModifyKit.unitRef
    const isModifiedEnabled = handleModifyKit.isModificationEnabled
    const units = handleModifyKit.units

    const currentLCS = handleLifeCycleStages.currentLCS

    const onRefAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setReferenceAmount(parseFloat(event.target.value))
    };
    const onUnitRefChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnitRef(event.target.value)
    }

    const handleStep = (stage: LifeCycleStageCategory, step: number) => {
      setActiveStepAndStage(stage, step)
    }

    const getFlowProviders = async () => {
      const promises = [...input, ...output].map(component =>
        getProviderByFlow(component.flow?.['@id'] as string)
      );
      const results = await Promise.all(promises);
      const providerList: Provider[] = [];
      results.forEach(result =>
        result.forEach(provider =>
          providerList.push(provider)
        )
      );
      setProviders(providerList);
    };

    useEffect(() => {
      void getFlowProviders();
    }, [currentProcess.exchanges]);

    return (
      <>
        <VStack w="100wh">
          <HStack alignSelf="flex-start" mb={3}>
            <Box
              as="form"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="standard-basic"
                label="Reference amount"
                value={referenceAmount}
                onChange={onRefAmountChange}
                type="number"
                disabled={!isModifiedEnabled}
              />
            </Box>
            <form noValidate autoComplete="off">
              <TextField
                id="standard-select-currency"
                select
                label="Unit"

                value={unitRef.name}
                onChange={onUnitRefChange}
                disabled={!isModifiedEnabled}
              >
                {units.map((unit) => (
                  <MenuItem key={unit['@id']} value={unit.name}>
                    {unit.isReferenceUnit && <b>{unit.name}</b>}
                    {!unit.isReferenceUnit && unit.name}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </HStack>
          <HStack w="100%">

            <Flex alignSelf="flex-start" w="13%">
              <Flex >
                <Stepper
                  nonLinear activeStep={activeStep}
                  orientation="vertical"
                >
                  {selectedStagesCategory.map((stage, index) => (
                    <Step key={index}>
                      <StepButton onClick={() => handleStep(stage, index)}>
                        <StepLabel>{LCSName[stage]}</StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Flex>
            </Flex>
            <VStack w="87%">

              <HStack w="100%" mb={5}>
                <Box flex={1}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Life cycle stage description"
                    multiline
                    rows={2}
                    value={description}
                    variant="outlined"
                    onChange={(e) => setDescription(e.target.value)}
                    size="small"
                    style={{ width: "100%" }}
                    disabled={!isModifiedEnabled}
                  />
                </Box>
              </HStack>
              <VStack w="100%" >
                <Box w="100%" h="40vh" mb={10}>
                  <KitLifeCycleTable
                    role={Role.INPUT}
                    components={input}
                    providers={providers}
                    handleModifyKit={handleModifyKit}
                    handleLifeCycleStages={handleLifeCycleStages}
                  />
                </Box>
                <Box w="100%" h="40vh" mt={10}>
                  <KitLifeCycleTable
                    role={Role.OUTPUT}
                    components={output}
                    providers={providers}
                    handleModifyKit={handleModifyKit}
                    handleLifeCycleStages={handleLifeCycleStages}
                  />
                </Box>
              </VStack>
              <Flex pt={10} pb={10} alignSelf="flex-end" >
                <Button
                  disabled={!touched}
                  alignSelf="flex-end"
                  mr={5}
                  leftIcon={<CheckCircleIcon color={"green"} />}
                  onClick={onSave}
                >
                  Save modification
                </Button>
              </Flex>
            </VStack>
          </HStack>
        </VStack>
      </>
    )
  }
