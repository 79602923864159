/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import _ from 'lodash';
import {
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import {
  DataGrid,
  GridAddIcon,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import { formatKitRefAmount, useKit } from '../../services/kits.service';
import { useGlobalState } from '../../store/globalState';
import { InfiniteEntityStatus, InfiniteKit } from '../../store/schema';
import { Delete, Info, RunLCA, Download, Upload } from '../Icons/icons';
import AddKit from '../Modal/AddKit';
import DeleteComponent from '../Modal/DeleteComponent';
import StatusChip from '../StatusChipMenu/StatusChip';
import CreateBIMPResource from '../Modal/CreateBIMPResource';

type KitRow = {
  id: number,
  kitId: number,
  name: string,
  description: string,
  lastChange: string,
  status: InfiniteEntityStatus
}
type State = {
  kitsRow: GridRowsProp,
  initialKitsRow: KitRow[],
  selectedRowIds: GridRowId[],
}
const UserKitLibrary: FC<{
  searchString: string,
  setKitIds: Dispatch<SetStateAction<number[]>>,
  onCalculationOpen: () => void
}> = ({
  searchString,
  setKitIds,
  onCalculationOpen
}) => {
    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose
    } = useDisclosure()

    const { deleteCustomKit } = useKit()
    const toast = useToast()
    const history = useHistory()
    const [globalState] = useGlobalState("globalState")
    const { kits } = globalState.user
    const [state, setState] = useState<State>({
      kitsRow: [],
      initialKitsRow: [],
      selectedRowIds: []
    })
    const { kitsRow, initialKitsRow, selectedRowIds } = state
    const isEditEnabled = true
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [kitLCIAResult, setKitLCIAResult] = useState("");
    const [resultFileName, setResultFileName] = useState("");

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 6 / 24 },
      { field: 'referenceAmount', headerName: 'Reference amount', flex: 4 / 24 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 3 / 24,
        renderCell: (params: GridCellParams) => (
          <StatusChip status={params.row.status} />
        )
      },
      { field: 'description', headerName: 'Description', flex: 6 / 24 },
      {
        field: 'lastChange',
        headerName: 'Last change',
        flex: 3 / 24,
        type: "date",
        valueFormatter: (params: GridValueFormatterParams) => {
          return new Date(params.value as Date).toLocaleDateString()
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 4 / 24,
        align: "right",
        disableColumnMenu: true,
        headerAlign: "right",
        renderCell: (params: GridCellParams) => {
          const selectedKitId = params.row.kitId as number;
          const selectedKit = kits.find(k => k.id === selectedKitId);
          const kitLCIAResult = selectedKit?.lciaResult;
          const resultFileName = _.replace(_.trim(selectedKit?.name), /\s/g, '_');
          return (
            <HStack>
              <Link as={ReachLink} to={`/kits/${selectedKitId}`}>
                <Info />
              </Link>
              {isEditEnabled &&
                <Link
                  as={ReachLink}
                  to="#"
                  onClick={() => handleRowDelete(params)}
                >
                  <Delete color />
                </Link>
              }
              <Link as={ReachLink} to="#" onClick={() => handleRowLCA(params)}>
                <RunLCA color />
              </Link>
              {kitLCIAResult &&
                <>
                  <Link
                    as="a"
                    href={`data:text/csv,${encodeURI(kitLCIAResult)}`}
                    download={resultFileName}
                  >
                    <Download />
                  </Link>
                  <Link as="button" onClick={() => {
                    setResultFileName(resultFileName);
                    setKitLCIAResult(kitLCIAResult);
                    setUploadModalOpen(true);
                  }}>
                    <Upload />
                  </Link>
                </>
              }
            </HStack>
          );
        }
      }
    ];

    useEffect(() => {
      const kitIds = selectedRowIds.map(rowId => {
        const kitId = (kitsRow.find(row => row.id === rowId) as KitRow).kitId
        return kitId
      })
      setKitIds(kitIds)
    }, [selectedRowIds])

    useEffect(() => {
      /**
        * Filter the kits on name, description or creation date.
        * It is case insensitive
        * @returns The filtered rows array
        */
      const filterRows = () => {
        const formatedSearch = searchString.toLowerCase()
        return initialKitsRow.filter(row =>
          row.name.toLowerCase().includes(formatedSearch)
        )
      }
      setState({ ...state, kitsRow: filterRows() })
    }, [searchString])

    useEffect(() => {
      const formatKits = (kits: InfiniteKit[]) => {
        const rows: KitRow[] = kits.map((kit: InfiniteKit, idx: number) => (
          {
            id: idx,
            kitId: kit.id,
            name: kit.name,
            referenceAmount: formatKitRefAmount(kit),
            lastChange: kit.lastChange,
            description: kit.description,
            status: kit.status || InfiniteEntityStatus.PRELIMINARY
          }
        ))
        setState({ ...state, kitsRow: rows, initialKitsRow: rows })
      }
      if (kits)
        formatKits(kits)
    }, [globalState.user.kits])

    const handleSelection = (selectionModel: GridRowId[]) => {
      setState({ ...state, selectedRowIds: selectionModel })
    }

    const handleDoucleClick = (param: GridRowParams) => {
      history.push(`/kits/${param.row.kitId as string}`)
    }

    const handleDelete = async () => {
      try {
        const kitRowsToDelete =
          kitsRow.filter(row => selectedRowIds.includes(row.id))
        const kitIdsToDelete = kitRowsToDelete.map(row => row.kitId as number)
        await deleteCustomKit(kitIdsToDelete)
        setState({
          ...state,
          selectedRowIds: [],
          kitsRow: [
            ...kitsRow.filter(row => kitRowsToDelete.includes(row.id))
          ]
        })
        setKitIds([])
        onDeleteClose()
      } catch (error) {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    const handleRowDelete = (params: GridCellParams) => {
      setState({ ...state, selectedRowIds: [params.row.id] })
      onDeleteOpen()
    }

    const handleRowLCA = (params: GridCellParams) => {
      setState({ ...state, selectedRowIds: [params.row.id] })
      onCalculationOpen()
    }

    return (
      <>
        <Flex h="69vh">
          <DataGrid
            rows={kitsRow}
            columns={columns}
            checkboxSelection
            autoPageSize
            pagination
            density="compact"
            onSelectionModelChange={handleSelection}
            onRowDoubleClick={handleDoucleClick}
            components={{
              Toolbar: () => CustomToolbar({
                selectedRowIds,
                onDeleteOpen,
                isEdit: isEditEnabled
              })
            }}
            selectionModel={selectedRowIds}
            disableSelectionOnClick />
        </Flex>
        <DeleteComponent
          handleDelete={handleDelete}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
        <CreateBIMPResource
          isOpen={uploadModalOpen}
          setIsOpen={setUploadModalOpen}
          lciaResult={kitLCIAResult}
          resultFileName={resultFileName}
        />
      </>
    );
  };

const CustomToolbar: FC<{
  selectedRowIds: GridRowId[],
  onDeleteOpen: () => void,
  isEdit: boolean
}> = ({
  selectedRowIds,
  onDeleteOpen,
  isEdit
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
      <>
        <Flex>
          <Text ml={5} fontSize={"lg"}>User kit library</Text>
          <Spacer />
          <GridToolbarContainer>

            {isEdit &&
              <IconButton
                mr={2}
                colorScheme="green"
                size="xs"
                aria-label="Search database"
                icon={<GridAddIcon />}
                onClick={onOpen}
              />
            }
            {isEdit &&
              <IconButton
                mr={2}
                isDisabled={selectedRowIds.length === 0}
                colorScheme="red"
                size="xs"
                aria-label="Search database"
                icon={<Delete />}
                onClick={onDeleteOpen}
              />
            }
          </GridToolbarContainer>
        </Flex>
        <AddKit isOpen={isOpen} onClose={onClose} />
      </>
    );
  }

export default UserKitLibrary
