import { decode } from 'jwt-simple';
import { createGlobalState } from 'react-hooks-global-state';
import {
  LOCAL_STORAGE_AUTHSTATE_KEY,
  LOCAL_STORAGE_SAVESTATE_KEY
} from '../constants';
import { JWT_KEY } from '../utils/secretKey';
import { InfiniteUser } from './schema';
import { AuthState, GlobalState } from './types';

export const anonymousUser: InfiniteUser = {
  id: -1,
  email: "",
  firstName: "",
  isActive: false,
  isSuperuser: false,
  hasEcoinvent: true,
  kits: [],
  buildings: [],
  lastName: ""
}

const saveState = localStorage.getItem(LOCAL_STORAGE_SAVESTATE_KEY)
let isSave = false
if (saveState) {
  isSave = JSON.parse(saveState) as boolean
}

let authState: AuthState = { isAuthenticated: false }


const localStoredSessionToken =
  localStorage.getItem(LOCAL_STORAGE_AUTHSTATE_KEY)
try {
  if (localStoredSessionToken) {
    const serializedSessionState =
      decode(localStoredSessionToken, JWT_KEY) as string
    if (serializedSessionState) {
      const localStoredSessionState =
        JSON.parse(serializedSessionState) as AuthState
      authState = localStoredSessionState
    }
  }
} catch {
  authState = { isAuthenticated: false }
}


const firstState: { authState: AuthState, globalState: GlobalState } = {
  authState: authState,
  globalState: {
    isAuthStateSave: isSave,
    kits: [],
    buildings: [],
    user: anonymousUser,
    customLifeCycleStages: [],
    defaultLifeCycleStages: [],
    flowProperties: [],
    locations: [],
    methods: [],
    flows: [],
    units: [],
    results: [],
    selectedStageIndex: [],
    processes: [],
  }
}

export const { useGlobalState } = createGlobalState(firstState)
