import { AddIcon, DeleteIcon, InfoOutlineIcon } from "@chakra-ui/icons"
import React, { FC } from "react"
import { BsPencil } from "react-icons/bs"
import { IoMdArrowDroprightCircle } from "react-icons/io"
import { MdContentCopy } from "react-icons/md"
import { FiDownload, FiUpload} from "react-icons/fi"

export const Add: FC<{ color?: boolean }> = ({ color = false }) => {
  return <AddIcon color={color ? "green" : ""} />
}

export const Edit: FC<{ color?: boolean }> = ({ color = false }) => {
  return <BsPencil color={color ? "orange" : ""} />
}

export const Delete: FC<{ color?: boolean }> = ({ color = false }) => {
  return <DeleteIcon color={color ? "red.500" : ""} />
}

export const RunLCA: FC<{
  color?: boolean,
  size?: "big" | "small"
}> = ({
  color = true,
  size = "small"
}) => {
    return <IoMdArrowDroprightCircle
      color={color ? "green" : ""}
      size={size === "big" ? 20 : 17}
      style={{ marginTop: size === "big" ? 0 : 2 }}
    />
  }

export const Info: FC = () => {
  return <InfoOutlineIcon />
}

export const Copy: FC = () => {
  return <MdContentCopy />
}

export const Download: FC = () => {
  return <FiDownload />
}

export const Upload: FC = () => {
  return <FiUpload />
}
