/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/display-name */
import { Box, Flex, HStack, Spacer, useToast } from "@chakra-ui/react"
import { Tab, Tabs, TextField } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useHandleInfiniteEntityLifeCycleStages
} from "../../hook/handleInfiniteEntityLifeCycleStages"
import useHandleModifyEntity from "../../hook/handleInfiniteEntityModification"
import { useGlobalState } from "../../store/globalState"
import { InfiniteBuilding, InfiniteCustomBuilding } from "../../store/schema"
import { anonymousBuilding } from "../../store/types"
import ChipSelect from "../StatusChipMenu/ChipSelect"
import FileUpload from "../Upload/FileUpload"
import BuildingDetails from "./BuildingDetails"
import { BuildingLifeCycle } from "./BuildingLifeCycle"
import BuildingSocialAspectTab from "./BuildingSocialAspectTab"

const BuildingPage: FC = () => {
  const { buildingId } = useParams<{ buildingId: string }>()
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const { buildings, user } = globalState
  const [building, setBuilding] = useState<
    InfiniteBuilding | InfiniteCustomBuilding
  >(anonymousBuilding)
  const toast = useToast()
  const handleModifyBuilding = useHandleModifyEntity(building, setBuilding)
  const handleLifeCycleStages = useHandleInfiniteEntityLifeCycleStages(building)
  const isModificationEnabled = handleModifyBuilding.isModificationEnabled
  const buildingDetailsTouched = handleModifyBuilding.touched[0]
  const [status, setStatus] = handleModifyBuilding.status
  const LCSTouched = handleLifeCycleStages.touched
  const [name, setName] = handleModifyBuilding.name

  useEffect(() => {
    if (!buildings || (building && building.id !== -1)) return
    let findBuilding = buildings?.find(
      (building) => building.id === parseInt(buildingId)
    ) as InfiniteBuilding
    if (!findBuilding) {
      findBuilding = user.buildings?.find(
        (building) => building.id === parseInt(buildingId)
      ) as InfiniteCustomBuilding
    }
    if (findBuilding) setBuilding(findBuilding)
  }, [buildings, user.buildings])

  const onSave = () => {
    if (handleLifeCycleStages.touched) handleLifeCycleStages.onSave()
    if (handleModifyBuilding.touched[0]) handleModifyBuilding.onSave()
  }

  const [value, setTabIndex] = useState(0)

  const handleChange = (newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Box m={5} h="100vh" w="96vw">
      <HStack>
        <Box
          as="form"
          sx={{
            "& .MuiTextField-root": { width: "75ch" },
          }}
          noValidate
          autoComplete="off"
          mb={4}
        >
          <TextField
            id="standard-basic"
            label="Building name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            size="medium"
            inputProps={{ style: { fontSize: 40 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
            disabled={!isModificationEnabled}
          />
        </Box>
        <Spacer />
        <ChipSelect
          defaultStatus={handleModifyBuilding.status[0]}
          handleStatus={handleModifyBuilding.status[1]}
          isModificationEnabled={isModificationEnabled}
        />
      </HStack>
      <Box sx={{ borderBottom: 1, borderColor: "" }}>
        <Tabs
          value={value}
          onChange={(e, v) => handleChange(v)}
          aria-label="basic tabs example"
        >
          <Tab label="Building details" {...a11yProps(0)} />
          <Tab label="Building life cycle" {...a11yProps(1)} />
          <Tab label="Document" {...a11yProps(2)} />
          <Tab label="Social aspects" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {building.id !== -1 && (
          <BuildingDetails
            handleModifyBuilding={handleModifyBuilding}
            handleLifeCycleStages={handleLifeCycleStages}
            onSave={onSave}
            touched={
              buildingDetailsTouched || (LCSTouched)
            }
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {building.id !== -1 && (
          <>
            <Flex h="50vh" justify="center">
              <Box w="98vw" h="100%">
                <BuildingLifeCycle
                  handleModifyBuilding={handleModifyBuilding}
                  handleLifeCycleStages={
                    handleLifeCycleStages
                  }
                  onSave={onSave}
                  touched={
                    buildingDetailsTouched ||
                    (LCSTouched)
                  }
                />
              </Box>
            </Flex>
          </>
        )}
      </TabPanel>
      <TabPanel
        value={value}
        index={2}>
        <FileUpload
          entity={building}
          onSave={onSave}
          handleModifyEntity={handleModifyBuilding}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BuildingSocialAspectTab
          handleModifyBuilding={handleModifyBuilding}
          handleLifeCycleStages={handleLifeCycleStages}
        />
      </TabPanel>
    </Box>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default BuildingPage
