import { BIMP_AUTH_TOKEN } from "../../components/Modal/CreateBIMPResource";
import configData from "../config.json";
import axios from "axios";
import {
  BIMPCategory,
  BIMPScope,
  BIMPTipology,
  BIMPCreateResourceRequestBody
} from "../../store/schema";

const resourceService = axios.create({
  baseURL: configData.BIMP_API_URL + "/resources",
  headers: {
    "accept": "application/json",
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${sessionStorage.getItem(BIMP_AUTH_TOKEN) || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getBIMPScopes = async (
  tokenUser: string,
  tokenProject: string,
  role: string
): Promise<BIMPScope[]> => {
  const response = await resourceService.get<string>(
    "/scope", {
      params: {
        tokenUser,
        tokenProject,
        role
      }
    }
  );
  return JSON.parse(response.data);
};

export const getBIMPCategories = async (
  tokenUser: string,
  tokenProject: string,
  tokenScope: string,
  role: string
): Promise<BIMPCategory[]> => {
  const response = await resourceService.get<string>(
    "/categories", {
      params: {
        tokenUser,
        tokenProject,
        tokenScope,
        role
      }
    }
  );
  return JSON.parse(response.data);
};

export const getBIMPTipologies = async (
  tokenUser: string,
  tokenProject: string,
  tokenScope: string,
  tokenCategories: string,
  role: string
): Promise<BIMPTipology[]> => {
  const response = await resourceService.get<string>(
    "/tipologies", {
      params: {
        tokenUser,
        tokenProject,
        tokenScope,
        tokenCategories,
        role
      }
    }
  );
  return JSON.parse(response.data);
};

export const createBIMPResource = async (
  resource: BIMPCreateResourceRequestBody
): Promise<string> => {
  const response = await resourceService.post<string>(
    "/resource",
    resource
  );
  return response.data;
};
