/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Select,
  Spacer,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { TextField } from '@material-ui/core';
import {
  DataGrid,
  GridAddIcon,
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
  GridEditRowsModel,
  GridRenderEditCellParams,
  GridRowId,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  useHandleLifeCycleStagesProps
} from '../../hook/handleInfiniteEntityLifeCycleStages';
import {
  useHandleModifyEntity
} from '../../hook/handleInfiniteEntityModification';
import { getComponentType } from '../../services/components.service';
import { useGlobalState } from '../../store/globalState';
import {
  Exchange,
  InfiniteEntityRef,
  Provider,
  Ref
} from '../../store/schema';
import { Role } from '../../store/types';
import { roleToString } from '../../utils/utils';
import { Delete } from '../Icons/icons';
import AddComponent from '../Modal/AddComponent';
import DeleteComponent from '../Modal/DeleteComponent';

type ComponentDataRow = {
  id: number,
  componentId: number,
  amount: number,
  unit: string,
  type: string,
  location: string,
  provider: string,
  description: string,
  flowProperty: InfiniteEntityRef,
  flowId: string,
}
export const KitLifeCycleTable: FC<{
  role: Role,
  components: Exchange[],
  providers: Provider[],
  handleModifyKit: useHandleModifyEntity,
  handleLifeCycleStages: useHandleLifeCycleStagesProps
}> = ({
  role,
  components,
  providers,
  handleModifyKit,
  handleLifeCycleStages
}) => {
    const kit = handleModifyKit.infiniteEntity
    const deleteComponent = handleLifeCycleStages.deleteComponent
    const [rows, setRows] = useState<ComponentDataRow[]>([])
    const [
      editRowsModel,
      setEditRowsModel
    ] = useState<GridEditRowsModel>({})
    const {
      isOpen: isAddOpen,
      onOpen: onAddOpen,
      onClose: onAddClose
    } = useDisclosure()
    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose
    } = useDisclosure()
    const isModificationEnabled = handleModifyKit.isModificationEnabled
    const [selectedRowsId, setSelectedRowsId] = useState<GridRowId[]>([])
    const toast = useToast()
    const [{ flowProperties }] = useGlobalState("globalState")
    const updateProcess = handleLifeCycleStages.currentProcess[2]

    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Name',
        flex: 6 / 24
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 3 / 24,
        editable: true,
        type: "number",
      },
      {
        field: 'unit',
        headerName: 'Unit',
        flex: 3 / 24,
        editable: true,
        renderCell: (params: GridCellParams) =>
          ((params.row) as ComponentDataRow).unit,
        renderEditCell: (params: GridRenderEditCellParams) => {
          const kitComponentRow = params.row as ComponentDataRow
          const [unit, setUnit] = useState(kitComponentRow.unit)
          useEffect(() => {
            if (unit != kitComponentRow.unit) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: kitComponentRow.unit
              })
            }
          }, [unit])
          const flowProperty = flowProperties.find(fp =>
            fp['@id'] === kitComponentRow.id.toString()
          ) as Ref
          return (
            <form
              noValidate
              autoComplete="off"
              style={{ width: "100%" }}
            >
              <TextField
                id="standard-select-currency"
                select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                style={{ width: "100%" }}
              >
              </TextField>
            </form>
          )
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 3 / 24
      },
      {
        field: 'provider',
        headerName: 'Provider',
        flex: 14 / 24,
        editable: true,
        renderCell: (params: GridCellParams) => params.row.provider,
        renderEditCell: (params: GridRenderEditCellParams) => {
          const { id, field } = params;
          const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
            params.api.setEditCellValue({
              id,
              field,
              value: event.target.value
            });
          };
          const providers = filterProviderByFlowId(params.row.flowId);
          return (
            <Select
              placeholder='Please select a provider'
              value={params.value?.toString()}
              onChange={onChange}>
              {providers.map((provider, index) =>
                <option
                  key={index}
                  value={getProviderName(provider)}
                >
                  {getProviderName(provider)}
                </option>
              )}
            </Select>
          )
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 6 / 24,
        editable: true
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 3 / 24,
        align: "right",
        disableColumnMenu: true,
        headerAlign: "right",
        renderCell: (params: GridCellParams) => (
          <HStack>
            <IconButton
              mr={2}
              isDisabled={!isModificationEnabled}
              colorScheme={"white"}
              size="xs"
              aria-label="Search database"
              icon={<Delete color />}
              onClick={() => handleRowDelete(params)}
            />
          </HStack>
        )
      }
    ];

    const getProviderName = (provider: Provider) => {
      if (!provider.provider.location)
        return provider.provider.name;
      return `${provider.provider.name} - ${provider.provider.location}`;
    };

    const showProviderNameWithLocation = (exchange: Exchange) => {
      if (!exchange.defaultProvider)
        return "";
      if (!exchange.defaultProvider.location)
        return exchange.defaultProvider.name;
      return `${exchange.defaultProvider.name} - ${exchange.defaultProvider.location}`;
    };

    const handleCellEditCommit = useCallback(
      ({ id, field, value }: GridCellEditCommitParams) => {
        const row = rows.find(r => r.id === id) as ComponentDataRow
        const exchange = components.find(c =>
          c.flow?.['@id'] === row.flowId
        ) as Exchange
        if (field === 'amount') {
          exchange.amount = value as number
        }
        if (field === 'description')
          exchange.description = value as string
        if (field === 'provider') {
          const provider = providers.find(
            p => getProviderName(p) === value
          );
          if (provider)
            exchange.defaultProvider = provider.provider;
        }
        updateProcess(exchange)
      },
      [rows],
    );

    const handleRowDelete = (params: GridCellParams) => {
      setSelectedRowsId([params.row.id])
      onDeleteOpen()
    }

    const handleRowLCA = (params: GridCellParams) => {
      setSelectedRowsId([params.row.id])
    }

    const formatData = () => {
      const rows = components?.map((exchange: Exchange, idx: number) => (
        {
          id: idx,
          componentId: exchange.internalId as number, // FIXME : there is no ids for exchanges
          name: exchange.flow?.name,
          description: exchange.description,
          amount: exchange.amount,
          unit: exchange.unit?.name,
          type: getComponentType(exchange),
          location: exchange.flow?.location,
          provider: showProviderNameWithLocation(exchange),
          flowProperty: {
            id: exchange.flowProperty?.['@id'],
            name: exchange.flowProperty?.name
          },
          flowId: exchange.flow?.['@id']
        } as ComponentDataRow
      ))
      setRows(rows)
    }

    const filterProviderByFlowId = (flowId: string) => {
      return providers.filter(provider => provider.flow['@id'] === flowId);
    };

    useEffect(() => {
      if (components) {
        formatData()
      }
    }, [kit, components])


    const handleDelete = () => {
      try {
        const tmpRows = rows.filter(row => selectedRowsId.includes(row.id))
        const componentsIds = tmpRows.map(row => row.componentId)

        deleteComponent(componentsIds)

      } catch (error) {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    return (
      <>
        <DataGrid
          rows={rows}
          columns={columns}
          checkboxSelection={isModificationEnabled}
          autoPageSize
          pagination
          density="compact"
          rowsPerPageOptions={[]}
          disableSelectionOnClick
          components={{
            Toolbar: () => CustomToolbar({
              role,
              onOpen: onAddOpen,
              onDeleteOpen: onDeleteOpen,
              selectedRowsId,
              isModificationEnabled
            }),
          }}
          selectionModel={selectedRowsId}
          onSelectionModelChange={(selectionModel) =>
            setSelectedRowsId(selectionModel)
          }
          editRowsModel={editRowsModel}
          onEditRowsModelChange={(model) => setEditRowsModel(model)}
          onCellEditCommit={handleCellEditCommit}

        />
        <AddComponent
          isOpen={isAddOpen}
          onClose={onAddClose}
          role={role}
          handleLifeCycleStage={handleLifeCycleStages}
        />
        <DeleteComponent
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          handleDelete={handleDelete}
        />
      </>
    )
  }

const CustomToolbar: FC<{
  role: Role,
  onOpen: () => void,
  onDeleteOpen: () => void,
  selectedRowsId: GridRowId[],
  isModificationEnabled: boolean
}> = ({
  role,
  onOpen,
  onDeleteOpen,
  selectedRowsId,
  isModificationEnabled
}) => {
    return (
      <Flex>
        <Box ml={5}>
          <b><h2 style={{ fontSize: "large" }}>{roleToString(role)}</h2></b>
        </Box>
        <Spacer />
        <GridToolbarContainer>
          <IconButton
            mr={2}
            isDisabled={!isModificationEnabled}
            colorScheme="green"
            size="xs"
            aria-label="Search database"
            icon={<GridAddIcon />}
            onClick={onOpen}
          />
          <IconButton
            mr={2}
            isDisabled={!isModificationEnabled || selectedRowsId.length === 0}
            colorScheme="red"
            size="xs"
            aria-label="Search database"
            icon={<Delete />}
            onClick={onDeleteOpen}
          />
        </GridToolbarContainer>
      </Flex>
    );
  }
