/* eslint-disable react-hooks/rules-of-hooks */
import { Heading, HStack, Spacer } from "@chakra-ui/react"
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid"
import { ChartData } from "chart.js"
import React, { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  LOCAL_STORAGE_CALC_INFO_KEY,
  LOCAL_STORAGE_CALC_RESULT_KEY,
  LOCAL_STORAGE_CALC_STAGES_KEY
} from "../../constants"
import { useGlobalState } from "../../store/globalState"
import { LifeCycleStageCategory, Ref, SavedResult, SimpleResult } from "../../store/schema"
import {
  CalculationInfo,
  Dataset,
  InfiniteEntity,
  LCSName
} from "../../store/types"
import { getPaired12Color, LCSColors } from "../../utils/colors"
import {
  deepCopy,
  formatInfiniteEntityName,
  formatInfiniteEntityRefAmount,
  formatNumber
} from "../../utils/utils"
import { CategoryChart } from "./CategoryChart"
import { BarChart } from "./Chart"
import { ImpactCategoryChart } from "./ImpactCategoryChart"
import { useKit } from "../../services/kits.service"
import { json2csv } from "json-2-csv"

type State = {
  activeStep: number
  calculationResults: SimpleResult[][]
  stages: Stage[]
  chartData: ChartData<"bar">
  impactChartData: ChartData<"bar">
  showTotal: boolean,
  impactCategories: Ref[]
}
type Stage = {
  category: LifeCycleStageCategory
  idx: number
}
type Row = {
  id: number
  impactCategory: string
  impactResult: number
  unit: string
}

export const Results: FC = () => {
  const [globalState] = useGlobalState("globalState")
  const infiniteEntity = globalState.calculationInfo?.infiniteEntities[0];
  const { updateCustomKit } = useKit();
  const { selectedStageIndex } = globalState
  let { results, calculationInfo } = globalState
  if (!results.length)
    results = getResultsFromStorage();
  if (!results.length) {
    useHistory().push("/")
    return <></>
  }
  if (!calculationInfo)
    calculationInfo = getInfoFromStorage();

  const [state, setState] = useState<State>({
    activeStep: 0,
    calculationResults: results,
    stages: [],
    chartData: {
      labels: [],
      datasets: [],
    },
    impactChartData: {
      labels: [],
      datasets: [],
    },
    showTotal: true,
    impactCategories: []
  })
  const setShowTotal = () =>
    setState({
      ...state,
      showTotal: true,
      activeStep: 0,
    })

  const {
    activeStep,
    calculationResults,
    stages,
    chartData,
    showTotal,
    impactChartData,
    impactCategories
  } = state
  const handleStep = (step: number) => {
    setState({ ...state, activeStep: step, showTotal: false })
  }

  function getInfoFromStorage() {
    let info = undefined;
    const jsonInfo = localStorage.getItem(LOCAL_STORAGE_CALC_INFO_KEY);
    if (jsonInfo)
      info = JSON.parse(jsonInfo) as CalculationInfo;
    return info;
  }

  function getResultsFromStorage() {
    let results: SimpleResult[][] = [];
    const jsonResults = localStorage.getItem(LOCAL_STORAGE_CALC_RESULT_KEY);
    if (jsonResults)
      results = JSON.parse(jsonResults) as SimpleResult[][];
    return results;
  };

  function getStagesFromStorage() {
    let stages: Stage[] = [];
    const jsonStages = localStorage.getItem(LOCAL_STORAGE_CALC_STAGES_KEY);
    if (jsonStages)
      stages = JSON.parse(jsonStages) as Stage[];
    return stages;
  }

  const getStages = (): Stage[] => {
    const stages: Stage[] = []
    Object.values(LifeCycleStageCategory).forEach((category, idx) => {
      if (selectedStageIndex.includes(idx))
        stages.push({
          category,
          idx: idx + 1,
        })
    })
    return stages
  }

  // Add as first SimpleResult the total result, that sums up every LCS results
  const computTotalResults = (): SimpleResult[][] => {
    return results.map((result) => {
      const simpleResultsCopy = result.slice()
      const totalSimpleResult = deepCopy(simpleResultsCopy[0])
      for (const simpleResult of simpleResultsCopy.slice(1)) {
        simpleResult.impactResults?.forEach((impactResult, idx) => {
          if (
            totalSimpleResult.impactResults &&
            totalSimpleResult.impactResults[idx]
          ) {
            const totalResult = totalSimpleResult.impactResults[idx]
            if (!totalResult.amount) totalResult.amount = 0
            totalResult.amount += impactResult?.amount || 0
          }
        })
      }
      simpleResultsCopy.unshift(totalSimpleResult)
      return simpleResultsCopy
    })
  }

  // Add label to chartData
  const addLabelToChartData = (data: ChartData<"bar">): ChartData<"bar"> => {
    if (data.labels?.length) return data;
    data.labels = calculationResults[0][0].impactResults?.map((result) => [
      result.impactCategory?.name as string,
      " ( " + (result.impactCategory?.refUnit as string) + " )",
    ])
    return data
  }

  const formatChartData = () => {
    const newChartData = addLabelToChartData(chartData)
    calculationResults.forEach((calculationResult, resultIdx) => {
      calculationResult.slice(1).forEach((simpleResult, idx) => {
        simpleResult.impactResults?.forEach((impactResult) => {
          const dataset = newChartData.datasets.find(
            (dataset: Dataset) => {
              const currentResultIdx = parseInt(
                dataset.stack?.split(" ")[1] as string
              )
              return (
                dataset.label === stages[idx].category &&
                currentResultIdx - 1 === resultIdx
              )
            }
          )
          if (dataset) {
            dataset.data.push(impactResult.amount as number)
          } else {
            const dataset: Dataset = {
              label: stages[idx].category,
              data: [impactResult.amount as number],
              backgroundColor: LCSColors.get(
                stages[idx].category
              ),
              stack: formatInfiniteEntityName(
                calculationInfo?.infiniteEntities[
                resultIdx
                ] as InfiniteEntity,
                resultIdx
              ),
            }
            newChartData.datasets.push(dataset)
          }
        })
      })
    })

    const newImpactChartData = addLabelToChartData(impactChartData)

    calculationResults.forEach((calculationResult, resultIdx) => {
      const simpleResult = calculationResult[0]
      simpleResult.impactResults?.forEach((impactResult) => {
        const dataset = newImpactChartData.datasets[resultIdx]

        if (dataset) {
          dataset.data.push(impactResult.amount as number)
        } else {
          const entityName = formatInfiniteEntityName(
            calculationInfo?.infiniteEntities[
            resultIdx
            ] as InfiniteEntity,
            resultIdx
          )
          const dataset: Dataset = {
            label: entityName,
            data: [impactResult.amount as number],
            backgroundColor: getPaired12Color(resultIdx),
          }
          newImpactChartData.datasets.push(dataset)
        }
      })
    })

    setState({
      ...state,
      impactChartData: newImpactChartData,
      chartData: newChartData,
      impactCategories: calculationResults[0][0]?.impactResults?.map(
        r => r.impactCategory
      ) as Ref[]
    })
  }

  const formatRows = (): Row[][] => {
    return calculationResults.reduce((acc, kitResults, kitIdx) => {
      // Kit iteration
      kitResults.forEach((lcsResult, lcsIdx) => {
        // LCS iteration
        let rows = acc[lcsIdx]
        if (rows) {
          // If rows already exist, add the impact of the
          // current kit results, as a new column in the rows
          rows = lcsResult.impactResults?.map(
            (impactResult, impactResultIdx) => {
              let row = rows[impactResultIdx]
              row = {
                ...row,
                [`impactResult${kitIdx}`]:
                  formatNumber(impactResult.amount as number),
              }
              return row
            }
          ) as Row[]
        } else {
          // Creation of rows, that contains the impact results
          // of the current kit results
          rows = lcsResult?.impactResults?.map(
            (impactResult, impactResultIdx) =>
              ({
                id: impactResultIdx,
                impactCategory: impactResult?.impactCategory
                  ?.name as string,
                unit: impactResult.impactCategory
                  ?.refUnit as string,
                [`impactResult${kitIdx}`]: formatNumber(
                  impactResult?.amount as number),
              } as Row)
          ) as Row[]
        }
        acc[lcsIdx] = rows
      })
      return acc
    }, [] as Row[][])
  }

  const resultToSavedResult = (
    totalResults: SimpleResult[][]
  ): SavedResult[] | undefined => {
    if (!totalResults || !totalResults[0] || !totalResults[0][0] ||
      !totalResults[0][0].impactResults)
      return;
    let savedResult: SavedResult[] = [];
    savedResult = totalResults[0][0].impactResults.map(result => ({
      "Impact Method": result.impactCategory?.category,
      "Impact Category": result.impactCategory?.name,
      "Impact Value": result.amount,
      "Unit": result.impactCategory?.refUnit
    }));
    return savedResult;
  };

  const saveTotalResults = async (totalResults: SimpleResult[][]) => {
    const savedResult = resultToSavedResult(totalResults);
    if (!savedResult || !infiniteEntity)
      return;
    json2csv(savedResult, (
      error: Error | undefined,
      csvResult: string | undefined
    ) => {
      if (error) {
        console.log("Failed to convert json to csv: ", error.message);
        return;
      }
      infiniteEntity.lciaResult = csvResult as string;
      updateCustomKit(infiniteEntity.id, infiniteEntity);
    });
  }

  useEffect(() => {
    let stageList = getStages()
    if (stageList.length > 0)
      localStorage.setItem(
        LOCAL_STORAGE_CALC_STAGES_KEY,
        JSON.stringify(stageList)
      );
    else
      stageList = getStagesFromStorage();
    const totalResults = computTotalResults();
    saveTotalResults(totalResults);
    setState({
      ...state,
      calculationResults: totalResults,
      stages: stageList,
    })
  }, [])

  useEffect(() => {
    if (stages.length > 0) {
      formatChartData()
    }
  }, [stages])

  const columns: GridColDef[] = [
    { field: "impactCategory", headerName: "Impact category", flex: 0.3 },
    ...results.map(
      (_, idx) =>
        ({
          field: `impactResult${idx}`,
          headerName: `Impact result for Kit ${idx + 1}`,
          flex: 0.5 / results.length,
          type: "number",
        } as GridColDef)
    ),
    { field: "unit", headerName: "Unit", flex: 0.2 },
  ]

  // Contains the impact results for each stage
  const rowsList = formatRows()

  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (newValue: number) => {
    setTabIndex(newValue)
  }
  const [collapsed, setCollapsed] = useState(true)
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item sm={12}>
          <Typography variant="h3"> Calculation results </Typography>
        </Grid>
        <Grid item sm={12}>
          <Box style={{ borderBottom: 1, borderColor: "" }}>
            <Tabs
              value={tabIndex}
              onChange={(_, v) => handleChange(v)}
              aria-label="basic tabs example"
            >
              <Tab label="Tables" />
              <Tab label="Charts" />
            </Tabs>
          </Box>
        </Grid>
        <TabPanel value={tabIndex} index={0}>
          <Grid container style={{ height: "100%", marginTop: 20 }}>
            <Grid item sm>
              <Paper elevation={5} style={{ padding: 20 }}>
                <HStack>
                  <Heading size="md">
                    General information
                  </Heading>
                  <Spacer />
                  <Button
                    onClick={() =>
                      setCollapsed(
                        (prevState) => !prevState
                      )
                    }
                  >
                    {collapsed ? (
                      <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                      )}
                  </Button>
                </HStack>
                <Collapse in={collapsed}>
                  <List>
                    <ListItem>
                      <b>Impact assessment method:</b>
                      {` ${calculationInfo
                        ?.assessmentMethod
                        .name as string
                        }`}
                    </ListItem>
                  </List>
                  <Grid container spacing={5}>
                    {calculationInfo?.infiniteEntities.map(
                      (entity, idx) => (
                        <Grid
                          item
                          sm={3}
                          key={entity.id}
                        >
                          <Paper elevation={10}>
                            <List>
                              <ListItem>
                                <b>Name:</b>
                                {`  ${entity.name}`}
                              </ListItem>
                              <ListItem>
                                <b>Alias:</b>
                                {` ${calculationInfo?.isInfiniteKit
                                  ? "Kit"
                                  : "Building"
                                  } ${idx + 1}`}
                              </ListItem>
                              <ListItem>
                                <b>
                                  Reference
                                  amount:
                                </b>
                                {` ${formatInfiniteEntityRefAmount(
                                  entity
                                )}`}
                              </ListItem>
                            </List>
                          </Paper>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Collapse>
              </Paper>
            </Grid>
          </Grid>

          <Grid container style={{ height: "100%", marginTop: 20 }}>
            <Grid item>
              <Button
                color={showTotal ? "primary" : undefined}
                variant="contained"
                onClick={setShowTotal}
              >
                Total Result
              </Button>
              <Stepper
                nonLinear
                activeStep={showTotal ? -1 : activeStep}
                orientation="vertical"
              >
                {stages.map((stage, index) => (
                  <Step key={index + 1}>
                    <StepButton
                      onClick={() => handleStep(index)}
                    >
                      <StepLabel>
                        {LCSName[stage.category]}
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            <Grid item xs={10} style={{ height: "100%" }}>
              <DataGrid
                autoHeight
                style={{ top: "0px", height: "100%" }}
                columns={columns}
                rows={rowsList[showTotal ? 0 : activeStep + 1]}
                density="compact"
                autoPageSize
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Grid container>
            {/* Chart for total impacts for one entity */}
            {chartData.labels &&
              results.length === 1 && (
                <Grid item xs={12}>
                  <Paper style={{ width: "100%" }}>
                    <BarChart
                      data={chartData}
                      comparison={results.length > 1}
                      selectedStagesNumber={stages.length}
                    />

                  </Paper>
                </Grid>
              )}
            {/* Charts for total impacts for entity comparisons */}
            {results.length > 1 && (
              <><Grid item xs={12}>
                {impactChartData.labels?.length && (
                  <ImpactCategoryChart
                    data={impactChartData} />
                )}
              </Grid>
                <Grid item xs={12}>
                  {impactChartData.labels?.length && (
                    <CategoryChart
                      data={chartData}
                      impactCategories={impactCategories}
                      selectedStagesNumber={stages.length} />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </TabPanel>
      </Grid>
    </Container>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Grid
          item
          style={{ padding: 3, height: "100%", width: "100%" }}
        >
          {children}
        </Grid>
      )}
    </Grid>
  )
}
