/* eslint-disable indent */
import axios from "axios";
import {
  BuildingSocialAspect,
  CalculationSetup,
  CalculationState,
  InfiniteBuilding,
  InfiniteBuildingUpdate,
  InfiniteCustomBuilding,
  InfiniteEntityCalculationStates,
  InfiniteEntityType,
  InfiniteLifeCycleStage,
  LifeCycleStageCategory,
  SocialAspectResp,
  SocialStakeHolder,
} from "../store/schema";
import configData from "./config.json";

const buildingService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/buildings",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getDefaultBuildings = async (
  skip?: number,
  limit?: number
): Promise<InfiniteBuilding[]> => {
  const response = await buildingService.get<InfiniteBuilding[]>(
    `/?skip=${skip || 0}&limit=${limit || 100
    }&type=${InfiniteEntityType.DEFAULT.toLowerCase()}`
  );
  return response.data;
};

export const getBuilding = async (id: number): Promise<InfiniteBuilding> => {
  const response = await buildingService.get<InfiniteBuilding>(`/${id}`);
  return response.data;
};

export const getCustomBuildings = async (
  skip?: number,
  limit?: number
): Promise<InfiniteCustomBuilding[]> => {
  const response = await buildingService.get<InfiniteCustomBuilding[]>(
    `/?skip=${skip || 0}&limit=${limit || 100
    }&type=${InfiniteEntityType.CUSTOM.toLowerCase()}`
  );
  return response.data;
};

export const createCustomBuilding = async (
  name: string,
  description: string | undefined,
  referenceAmount: number,
  unitRefId: string,
  flowPropertyRefId: string,
  locationRefId: string,
  lifeCycleStages: InfiniteLifeCycleStage[]
): Promise<InfiniteCustomBuilding> => {
  const response = await buildingService.post<InfiniteCustomBuilding>("/", {
    name,
    description,
    referenceAmount,
    unitRefId,
    flowPropertyRefId,
    locationRefId,
    lifeCycleStages,
    type: InfiniteEntityType.CUSTOM,
  });
  return response.data;
};

export const updateBuilding = async (
  id: number,
  buildingUpdate: InfiniteBuildingUpdate
): Promise<InfiniteCustomBuilding> => {
  const response = await buildingService.put<InfiniteCustomBuilding>(
    `/${id}`,
    buildingUpdate
  );
  return response.data;
};

export const deleteBuilding = async (id: number): Promise<void> => {
  await buildingService.delete(`/${id}`);
};

export const getLCSs = async (
  id: number
): Promise<InfiniteLifeCycleStage[]> => {
  const response = await buildingService.get<InfiniteLifeCycleStage[]>(
    `/${id}/life-cycle-stages`
  );
  return response.data;
};

export const createLCS = async (
  buildingId: number,
  name: string,
  category: LifeCycleStageCategory,
  processRefId: string
): Promise<InfiniteBuilding> => {
  const response = await buildingService.post<InfiniteBuilding>(
    `/${buildingId}/life-cycle-stages`,
    {
      name,
      category,
      processRefId,
    }
  );
  return response.data;
};

export const getLCS = async (
  id: number,
  category: LifeCycleStageCategory
): Promise<InfiniteLifeCycleStage> => {
  const response = await buildingService.get<InfiniteLifeCycleStage>(
    `/${id}/life-cycle-stages/${category}`
  );
  return response.data;
};

export const deleteLCS = async (
  id: number,
  category: LifeCycleStageCategory
): Promise<void> => {
  await buildingService.delete(`/${id}/life-cycle-stages/${category}`);
};

export const calculateBuilding = async (
  id: number,
  calculationSetup: CalculationSetup
): Promise<InfiniteEntityCalculationStates> => {
  const response = await buildingService.post<InfiniteEntityCalculationStates>(
    `/${id}/calculate`,
    calculationSetup
  );
  return response.data;
};

export const calculateLCS = async (
  id: number,
  category: LifeCycleStageCategory,
  calculationSetup: CalculationSetup
): Promise<CalculationState> => {
  const response = await buildingService.post<CalculationState>(
    `/${id}/life-cycle-stages/${category}/calculate`,
    calculationSetup
  );
  return response.data;
};

export const addLCSToBuilding = async (
  buildingId: number,
  kitId: number,
  category: LifeCycleStageCategory,
  amount?: number
): Promise<void> => {
  let query = `/${buildingId}/add/kit/${kitId}/life-cycle-stages/${category}`;
  if (amount) query += `?amount=${amount}`;
  await buildingService.post(query);
};

export const addKitToBuilding = async (
  buildingId: number,
  kitId: number,
  amount?: number
): Promise<InfiniteBuilding> => {
  let query = `/${buildingId}/add/kit/${kitId}`;
  if (amount) query += `?amount=${amount}`;
  const response = await buildingService.post<InfiniteBuilding>(query);
  return response.data;
};

export const getBuildingSocialAspectByLCSCategory =
  async (id: number, category: string): Promise<BuildingSocialAspect[]> => {
    const response = await buildingService.get<BuildingSocialAspect[]>(
      `/${id}/life-cycle-stages/${category}/social-aspects`
    );
    return response.data;
  };

export const createBuildingSocialAspectByLCSCategory = async (
  id: number,
  category: LifeCycleStageCategory,
  stakeholder: SocialStakeHolder,
  socialIndicatorId: number,
  value: number,
  unit?: string,
  description?: string
): Promise<SocialAspectResp> => {
  const response = await buildingService.post<SocialAspectResp>(
    `/${id}/life-cycle-stages/${category}/social-aspects`, {
    stakeholder,
    value,
    unit,
    socialIndicatorId,
    description
  });
  return response.data;
};
