import { CheckCircleIcon } from "@chakra-ui/icons"
import { Button, HStack, Spacer } from "@chakra-ui/react"
import { List, ListItem } from "@material-ui/core"
import React, { FC, useState } from "react"
import { IoMdAddCircleOutline } from "react-icons/io"
import { createTechSpec, deleteTechSpec } from "../../api/kit.service"
import {
  useHandleModifyEntity
} from "../../hook/handleInfiniteEntityModification"
import { useGlobalState } from "../../store/globalState"
import { InfiniteKit, TechnicalSpecs } from "../../store/schema"
import { InfiniteEntity } from "../../store/types"
import TechSpec from "./techSpec"

const TechnicalSpecifications: FC<{
  entity: InfiniteEntity
  setKit: React.Dispatch<React.SetStateAction<InfiniteKit>>
  handleModifyEntity: useHandleModifyEntity
  onSave: () => void
}> = ({ entity, setKit, handleModifyEntity, onSave }) => {
  const [
    techSpecs,
    setTechSpecs
  ] = useState<TechnicalSpecs[]>(entity.technicalSpecs)
  const [
    newTechSpecs,
    setNewTechSpecs
  ] = useState<Omit<TechnicalSpecs, "kitId">[]>([])
  const [
    removedTechSpecs,
    setRemovedTechSpecs
  ] = useState<TechnicalSpecs[]>([])
  const isModificationEnabled = handleModifyEntity.isModificationEnabled
  const [touched, setTouched] = handleModifyEntity.touched
  const [globalState] = useGlobalState("globalState");
  const addTechSpec = () => {
    setTechSpecs([...techSpecs, {
      id: Math.floor(Math.random() * 1e20),
      name: "",
      description: "",
    }])
    setTouched(true)
  }

  const removeTechSpec = (
    ts: TechnicalSpecs | Omit<TechnicalSpecs, "kitId">
  ) => {
    if (isKitTechSpec(ts)) {
      //setRemovedTechSpecs([...removedTechSpecs, ts])
      setTechSpecs(techSpecs.filter((t) => t.id !== ts.id))
    }
    setNewTechSpecs(newTechSpecs.filter((t) => t.id !== ts.id))
    setTouched(true)
  }

  const getUpdatedTechnicalSpecs = () => {
    //get tech specs for this kit from global state
    let globalTS = globalState.user.kits.find(
      kit => kit.id === entity.id
    )?.technicalSpecs;
    if (globalTS) {
      //filter out removed tech specs
      globalTS = globalTS.filter((techSpec => {
        for (const ts of removedTechSpecs)
          if (ts.id === techSpec.id)
            return false;
        return true;
      }));
      //add newly added tech specs
      globalTS = [...globalTS, ...newTechSpecs];
    }
    return globalTS;
  };

  const handleSave = async () => {
    const promises: Promise<void | InfiniteKit>[] = []
    entity.technicalSpecs.forEach(
      (ts) => promises.push(deleteTechSpec(ts.kitId as number, ts.id))
    )
    techSpecs.forEach(
      (ts) => promises.push(createTechSpec(entity.id, ts.name, ts.description))
    )
    await Promise.all(promises)
    onSave();
    setKit({
      ...entity as InfiniteKit,
      name: handleModifyEntity.name[0],
      technicalSpecs: techSpecs
    });
    setTouched(false)
  }

  return (
    <>
      <Button
        leftIcon={<IoMdAddCircleOutline />}
        onClick={addTechSpec}
        disabled={!isModificationEnabled}
      >
        Add a technical specification
      </Button>
      <List>
        {[...techSpecs, ...newTechSpecs].map((ts) => (
          <ListItem key={ts.id}>
            <TechSpec
              techSpec={ts}
              techSpecs={techSpecs}
              setTechSpecs={setTechSpecs}
              newTechSpecs={newTechSpecs}
              setNewTechSpecs={setNewTechSpecs}
              isKitTechSpec={isKitTechSpec(ts)}
              handleDelete={() => removeTechSpec(ts)}
              isModificationEnabled={isModificationEnabled}
              setTouched={setTouched}
            />
          </ListItem>
        ))}
      </List>
      <HStack>
        <Spacer />
        <Button
          disabled={!touched}
          alignSelf="flex-end"
          mr={5}
          leftIcon={<CheckCircleIcon color={"green"} />}
          onClick={async () => await handleSave()}
        >
          Save modification
        </Button>
      </HStack>
    </>
  )
}

function isKitTechSpec(
  ts: TechnicalSpecs | Omit<TechnicalSpecs, "kitId">
): ts is TechnicalSpecs {
  return "kitId" in ts
}

export default TechnicalSpecifications
