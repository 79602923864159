import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useAuthentication } from '../services/authentication.service';
import { useGlobalState } from '../store/globalState';

const SignOut: FC = () => {
  const { isAuthenticated } = useGlobalState("authState")[0]
  const history = useHistory();
  const { signout } = useAuthentication();

  useEffect(() => {
    signout()
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/")
    }
  }, [isAuthenticated])
  return <></>
}

export default SignOut;
