/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/rules-of-hooks */
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure, useTheme
} from "@chakra-ui/react"
import { makeStyles } from "@material-ui/core"
import React, { FC, ReactNode } from "react"
import { GoSignIn, GoSignOut } from "react-icons/go"
import { Link as ReachLink, useHistory } from "react-router-dom"
import Logo from "../assets/INFINITE.svg"
import { useGlobalState } from "../store/globalState"

type NavLinkProps = {
  children: ReactNode
  path?: string
}
const NavLink: FC<NavLinkProps> = ({ children, path }) => {
  if (path) {
    return (
      <Link
        as={ReachLink}
        to={path}
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <Box
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        {children}
      </Box>
    )
  }
}

const Navbar: FC = () => {
  const { isAuthenticated } = useGlobalState("authState")[0]
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()
  const theme = useTheme()

  const useStyles = makeStyles(() => ({
    root: { // Disable hover effect on logo
      "& a:hover": {
        textDecoration: "none",
        background: "none"
      },
      "& a:focus": {
        boxShadow: "none"
      }
    }
  }))
  const classes = useStyles()
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex
          h={"52px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box className={classes.root}>
              <NavLink path={"/"} >
                <Image src={Logo} height={"45"} />
              </NavLink>
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              <NavLink path={"/buildings"}>{"Buildings"}</NavLink>
              <NavLink path={"/kits"}>{"Kits"}</NavLink>
            </HStack>
          </HStack>

          <Flex alignItems={"center"}>
            {isAuthenticated && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                >
                  <Avatar
                    size={"sm"}
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem as={ReachLink} to="/profile">
                    <NavLink key={"profile"}>
                      <HStack spacing="7px">
                        <Box pt="10px">
                          <Avatar
                            w={"15px"}
                            h={"15px"}
                            bg={"gray.600"}
                          />
                        </Box>
                        <Box>Profile</Box>
                      </HStack>
                    </NavLink>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem as={ReachLink} to="/signout">
                    <NavLink key={"signout"}>
                      <HStack spacing="7px">
                        <Box pt="5px">
                          <GoSignOut
                            color={
                              theme.colors.gray[
                              "600"
                              ] as string
                            }
                          />
                        </Box>
                        <Box>Sign Out</Box>
                      </HStack>
                    </NavLink>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            {!isAuthenticated && (
              <Button
                variant={"solid"}
                colorScheme={"blue"}
                size={"sm"}
                mr={4}
                leftIcon={<GoSignIn />}
                onClick={() => history.push("/signin")}
              >
                Sign In
              </Button>
            )}
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <NavLink path={"/buildings"}>{"Buildings"}</NavLink>
              <NavLink path={"/kits"}>{"Kits"}</NavLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  )
}

export default Navbar
