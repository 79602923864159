import React, { FC } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import BuildingPage from "./components/Building/Building"
import BuildingLibraries from "./components/Building/BuildingLibraries"
import Home from "./components/Home"
import KitPage from "./components/Kit/Kit"
import KitLibraries from "./components/Kit/KitLibraries"
import Navbar from "./components/Navbar"
import NotFoundPage from "./components/NotFoundPage"
import Profile from "./components/Profile"
import { Results } from "./components/Results/Results"
import Login from "./components/SignIn"
import SignOut from "./components/SignOut"
import useHandleAuthenticationState from "./hook/handleState"
import PrivateRoute from "./hook/Route"

export const App: FC = () => {
  useHandleAuthenticationState()

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/signin' component={Login} />
        <PrivateRoute exact path='/signout' >
          <SignOut />
        </PrivateRoute>
        <PrivateRoute exact path='/profile' >
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path='/kits'  >
          <KitLibraries />
        </PrivateRoute>
        <PrivateRoute exact path='/buildings'  >
          <BuildingLibraries />
        </PrivateRoute>
        <PrivateRoute exact path='/buildings/:buildingId' >
          <BuildingPage />
        </PrivateRoute>
        <PrivateRoute exact path='/kits/:kitId' >
          <KitPage />
        </PrivateRoute>
        <Route exact path="/results">
          <Results />
        </Route>
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </>
  )
}
