/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as ChakraSelect,
  Spinner,
  useToast,
  VStack
} from "@chakra-ui/react"
import {
  Backdrop,
  FormControl,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core"
import {
  DataGrid,
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
  GridEditRowsModel,
  GridRenderEditCellParams,
  GridSelectionModel
} from "@mui/x-data-grid"
import React, { FC, useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
  calculate,
  deleteCalculation,
  getFlowProperty,
  getProcessDescriptor,
  getUnitGroup
} from "../../api/olca.service"
import { useGlobalState } from "../../store/globalState"
import {
  CalculationSetup,
  CalculationState,
  CalculationType,
  InfiniteEntityRef,
  LifeCycleStageCategory,
  Ref,
  UnitGroup
} from "../../store/schema"
import {
  anonymousKit,
  dummyRef,
  InfiniteEntity,
  LCSName
} from "../../store/types"
import { getErrorMessage, unique } from "../../utils/utils"
import { RunLCA } from "../Icons/icons"
type UnitRow = {
  id: number
  entityId: number
  name: string
  amount: number
  unit: InfiniteEntityRef
  flowProperty: InfiniteEntityRef
}

export const Calculation: FC<{
  isOpen: boolean
  onClose: () => void
  infiniteEntitiesIds: number[]
  selectedStages?: GridSelectionModel
  isInfiniteKit?: boolean
}> = ({
  isOpen,
  onClose,
  infiniteEntitiesIds,
  selectedStages,
  isInfiniteKit = true,
}) => {
    const [globalState, setGlobalState] = useGlobalState("globalState")
    const { methods, kits, buildings, user, units } = globalState
    const [selectedInfiniteEntities, setInfiniteEntities] = useState<
      InfiniteEntity[]
    >([anonymousKit])

    const [unitRows, setUnitRows] = useState<UnitRow[]>([])
    const [selectedRows, setSelectedRows] = useState([
      0, 1, 2, 3, 4, 5, 6,
    ] as GridSelectionModel)
    const [method, setMethod] = useState<Ref>(dummyRef)
    const history = useHistory()
    const columns: GridColDef[] = [
      { field: "lcs", headerName: "Life cycle stages", flex: 1 },
    ]
    const [isCalculationRunning, setIsCalculationRunning] = useState(false)
    const toast = useToast()
    const rows = Object.values(LifeCycleStageCategory)
      .map((stage, idx) => ({
        id: idx,
        lcs: LCSName[stage],
      }))

    const [unitGroups, setUnitGroups] = useState<Map<string, UnitGroup>>(
      new Map()
    )

    const [
      editRowsModel,
      setEditRowsModel
    ] = useState<GridEditRowsModel>({})
    const [{ flowProperties }] = useGlobalState("globalState")
    const unitColumns: GridColDef[] = [
      { field: "name", headerName: "Name", flex: 1 / 3 },
      {
        field: "amount",
        headerName: "Reference amount",
        flex: 1 / 3,
        editable: true,
      },
      {
        field: "unit",
        headerName: "Unit",
        flex: 1 / 3,
        editable: true,

        renderCell: (params: GridCellParams) =>
          (params.row as UnitRow).unit.name,
        renderEditCell: (params: GridRenderEditCellParams) => {
          const row = params.row as UnitRow
          const [unit, setUnit] = useState(row.unit)

          const unitGroup = unitGroups.get(
            row.flowProperty.id
          ) as UnitGroup

          const handleChange = (e: React.ChangeEvent<
            HTMLTextAreaElement | HTMLInputElement
          >) => {
            const refUnit = units.find(
              u => u.name === e.target.value
            ) as Ref
            setUnit({
              id: refUnit["@id"],
              name: refUnit.name
            } as InfiniteEntityRef)
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: {
                id: refUnit["@id"],
                name: refUnit.name
              } as InfiniteEntityRef,
            })
            params.api.commitCellChange({
              id: row.id,
              field: "unit"
            })
          }
          return (
            <FormControl fullWidth >
              <TextField
                id="standard-select-currency"
                select
                label="Unit"
                value={unit.name}
                onChange={handleChange}
              >
                {unitGroup.units?.filter(unique).map((unit) => (
                  <MenuItem key={unit["@id"]} value={unit.name}>
                    {unit.isReferenceUnit && <b>{unit.name}</b>}
                    {!unit.isReferenceUnit && unit.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )
        },
      },
    ]

    const handleCellEditCommit = useCallback(
      ({ id, field, value }: GridCellEditCommitParams) => {
        const row = unitRows.find((r) => r.id === id) as UnitRow
        const entity = selectedInfiniteEntities.find(
          (e) => e.id === row?.entityId
        ) as InfiniteEntity
        if (field === "amount") {
          entity.referenceAmount = value as number
        }
        if (field === "unit") {
          entity.unitRef = value as InfiniteEntityRef
        }
        setInfiniteEntities([...selectedInfiniteEntities])
      },
      [rows]
    )

    const getInfinteEntity = (infiniteEntityId: number): InfiniteEntity => {
      const defaultInfiniteEntity = (isInfiniteKit ? kits : buildings)
        .find((infiniteEntity: InfiniteEntity) =>
          infiniteEntity.id === infiniteEntityId
        ) as InfiniteEntity | undefined
      if (defaultInfiniteEntity) return defaultInfiniteEntity
      const infiniteCustomEntity = (
        isInfiniteKit ? user.kits : user.buildings
      ).find(
        (infiniteEntity: InfiniteEntity) =>
          infiniteEntity.id === infiniteEntityId
      ) as InfiniteEntity
      return infiniteCustomEntity
    }

    useEffect(() => {
      const infiniteEntities = infiniteEntitiesIds.map(
        (infiniteEntityId) => getInfinteEntity(infiniteEntityId)
      )
      const infiniteEntitiesCopy: InfiniteEntity[] =
        JSON.parse(JSON.stringify(infiniteEntities));
      if (!isCalculationRunning) {
        setInfiniteEntities(infiniteEntitiesCopy);
      }
      const unitGroupsMap = new Map<string, UnitGroup>()
      const fetchUniGroups = async () => {
        const fpPromisses = infiniteEntities.map(
          async (entity) =>
            await getFlowProperty(entity.flowPropertyRef.id)
        )
        const fps = await Promise.all(fpPromisses)
        const unitGroupsPromisses = fps.map((fp) =>
          getUnitGroup(fp.unitGroup?.["@id"] || "")
        )
        const unitGroups = await Promise.all(unitGroupsPromisses)
        fps.map((fp, idx) => {
          unitGroupsMap.set(fp["@id"], unitGroups[idx])
        })
        setUnitGroups(unitGroupsMap)
      }
      void fetchUniGroups()
      if (!isCalculationRunning) {
        setUnitRows([
          ...infiniteEntities.map(
            (entity, idx) =>
            ({
              id: idx,
              entityId: entity.id,
              name: entity.name,
              amount: entity.referenceAmount,
              unit: entity.unitRef,
              flowProperty: entity.flowPropertyRef,
            } as UnitRow)
          ),
        ])
      }
    }, [infiniteEntitiesIds])

    useEffect(() => {
      if (selectedStages) setSelectedRows(selectedStages)
    }, [selectedStages])

    useEffect(() => {
      if (methods.length) {
        setMethod(methods[0])
      }
    }, [methods])
    const handleChange = (methodName: string) => {
      setMethod(methods.find((m) => m.name === methodName) as Ref)
    }

    const isEntitiesCalculationSuccess =
      (calculationStates: CalculationState[][]): boolean => {
        let isSuccess = true;
        calculationStates.forEach((entity: CalculationState[]) => {
          entity.forEach((lifeCycleStage: CalculationState) => {
            if (!lifeCycleStage["@id"] || !lifeCycleStage.time) {
              isSuccess = false;
            }
          });
        });
        return isSuccess;
      }

    const deleteCalculationForFailedPromises =
      (calculationStates: CalculationState[][]) => {
        calculationStates.forEach((entity: CalculationState[]) => {
          entity.forEach((lifeCycleStage: CalculationState) => {
            if (lifeCycleStage["@id"] && lifeCycleStage.time) {
              void deleteCalculation(lifeCycleStage["@id"]);
            }
          });
        });
      }

    const runCalculation = async () => {
      try {
        setIsCalculationRunning(true)
        setGlobalState({
          ...globalState,
          calculationInfo: undefined,
          results: [],
        })
        const calculationsSetups = await Promise.all(
          selectedInfiniteEntities.map(async (InfiniteEntity) => {
            const unitRef = units.find(
              u => u["@id"] === InfiniteEntity.unitRef.id
            ) as Ref
            const fpRef = flowProperties.find(fp =>
              fp["@id"] === InfiniteEntity.flowPropertyRef.id
            ) as Ref
            const promiseProcesses = InfiniteEntity.lifeCycleStages
              .filter((_, idx) => selectedRows.includes(idx))
              .map((lcs) => getProcessDescriptor(lcs.processRefId))
            const processes = await Promise.all(promiseProcesses)
            return processes.map((p) => {
              const calculationSetup: CalculationSetup = {
                calculationType:
                  CalculationType.SIMPLE_CALCULATION as CalculationType,
                impactMethod: method,
                process: p,
                amount: InfiniteEntity.referenceAmount,
                unit: unitRef,
                flowProperty: fpRef,
              }
              return calculationSetup
            })
          })
        )
        const calculationStates = await Promise.all(
          calculationsSetups.map(async (calculationSetups) => {
            return Promise.all(
              calculationSetups.map((calculationSetup) => {
                return calculate(calculationSetup)
                  .catch((error) => error)
              })
            )
          })
        )
        if (!isEntitiesCalculationSuccess(calculationStates)) {
          deleteCalculationForFailedPromises(calculationStates);
          throw new Error("Error processing calculation");
        }
        toast.closeAll()
        toast({
          position: "top",
          title: "Calculation in progress",
          description:
            "The calculation has started. You will be redirected \
                        to the result page once it is done",
          status: "info",
          duration: 15000,
          isClosable: true,
        })
        setIsCalculationRunning(false)
        setGlobalState({
          ...globalState,
          calculationInfo: {
            calculationStates,
            assessmentMethod: method,
            isInfiniteKit,
            infiniteEntities: selectedInfiniteEntities,
          },
          selectedStageIndex: selectedRows,
        })
        onClose()
      } catch (error) {
        const errorMsg = getErrorMessage(error)
        setIsCalculationRunning(false)
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: errorMsg,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={"8xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Calculation setup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w="100%" spacing={20}>
              <VStack align="left" w={"100%"}>
                <Typography>
                  Select the Life cycle stages to be calculated
                </Typography>
                <DataGrid
                  style={{ top: "0px", width: "100%" }}
                  autoHeight
                  selectionModel={selectedRows}
                  onSelectionModelChange={(e) =>
                    setSelectedRows(e)
                  }
                  columns={columns}
                  rows={rows}
                  checkboxSelection
                  hideFooter
                  density="compact"
                  disableColumnMenu
                />
              </VStack>
              <VStack
                w={"100%"}
                h={"100%"}
                alignSelf="flex-start"
                spacing={5}
              >
                <Box alignSelf="start">
                  <Typography>
                    Select the impact assessment method
                  </Typography>
                  <ChakraSelect
                    value={method.name}
                    onChange={(e) =>
                      handleChange(e.target.value)
                    }
                  >
                    {methods
                      .sort((a, b) =>
                        (a.name as string).localeCompare(
                          b.name as string
                        )
                      )
                      .map((method) => (
                        <option
                          key={method["@id"]}
                          placeholder={method.name}
                          value={method.name}
                        >
                          {method.name}
                        </option>
                      ))}
                  </ChakraSelect>
                </Box>
                <Flex w="100%" h="300">
                  <DataGrid
                    rows={unitRows}
                    columns={unitColumns}
                    onEditRowsModelChange={(model) =>
                      setEditRowsModel(model)
                    }
                    editRowsModel={editRowsModel}
                    onCellEditCommit={handleCellEditCommit}
                  />
                </Flex>
              </VStack>
            </HStack>

            <Backdrop
              style={{ color: "#fff", zIndex: 1401 }}
              open={isCalculationRunning}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Backdrop>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              alignSelf="flex-end"
              onClick={runCalculation}
              leftIcon={<RunLCA size="big" />}
              disabled={selectedRows.length === 0}
            >
              Run Calculation
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
