import { ChakraProvider } from "@chakra-ui/react"
import { MuiThemeProvider } from "@material-ui/core"
import { createTheme } from '@material-ui/core/styles'
import * as React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"

const theme = createTheme({
  overrides: {
    // FIXME: this should work, but it doesn't
    // MuiPopover:{
    //     root:{
    //         zIndex: 99999
    //     }
    // },
    MuiInputBase: {
      root: {
        "& .Mui-disabled": {
          // Make disabled field appears black instead of grey
          color: "rgba(0, 0, 0, 1)!important" // (default alpha is 0.38)
        }
      }
    },
    MuiTabs: {
      indicator: {
        background: "blue"
      }

    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter >
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

