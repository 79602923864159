import { LifeCycleStageCategory } from "../store/schema"

// colorscheme Paired12 from chartjs-plugin-colorschemes
// For other schemes, looke here : https://github.com/MaximBelov/chartjs-plugin-colorschemes/blob/master/src/colorschemes/colorschemes.brewer.js
const Paired12 = [
  "#a6cee3",
  "#1f78b4",
  "#b2df8a",
  "#33a02c",
  "#fb9a99",
  "#e31a1c",
  "#fdbf6f",
  "#ff7f00",
  "#cab2d6",
  "#6a3d9a",
  "#ffff99",
  "#b15928",
]

const colors = new Map<number, string>()
for (let i = 0; i < Paired12.length; i++) {
  colors.set(i, Paired12[i])
}

export const getPaired12Color = (index: number): string => colors.get(index % colors.size) as string

// Color for each life cycle stage
export const LCSColors = new Map<LifeCycleStageCategory | string, string>([
  [LifeCycleStageCategory.END_OF_LIFE, "rgba(255, 99, 132, 0.5)"],
  [LifeCycleStageCategory.INSTALLATION, "rgba(255, 159, 64, 0.5)"],
  [LifeCycleStageCategory.MAINTENANCE, "rgba(255, 205, 86, 0.5)"],
  [LifeCycleStageCategory.MANUFACTURING, "rgba(75, 192, 192, 0.5)"],
  [LifeCycleStageCategory.PACKAGING, "rgba(54, 162, 235, 0.5)"],
  [LifeCycleStageCategory.TRANSPORT, "rgba(153, 102, 255, 0.5)"],
  [LifeCycleStageCategory.USE, "rgba(255, 0, 255, 0.5)"],
])



