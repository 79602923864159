import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
  VStack
} from '@chakra-ui/react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { FC, useEffect, useState } from 'react';
import { addKitToBuilding, getBuilding } from '../../../api/building.service';
import {
  useHandleLifeCycleStagesProps
} from '../../../hook/handleInfiniteEntityLifeCycleStages';
import useSearchBar from '../../../hook/SearchBar';
import { useGlobalState } from '../../../store/globalState';
import { InfiniteBuilding, InfiniteKit } from '../../../store/schema';
import SearchBar from '../../SearchBar';

type KitRow = {
  id: number,
  kitId: number,
  name: string,
  description: string,
  unit: string
}
const AddKits: FC<{
  isOpen: boolean,
  onClose: () => void,
  handleLifeCycleStage: useHandleLifeCycleStagesProps,
  infiniteBuilding: InfiniteBuilding
}> = ({
  isOpen,
  onClose,
  handleLifeCycleStage,
  infiniteBuilding
}) => {
    const [
      currentProcess,
      setCurrentProcess,
      _,
      addExchanges
    ] = handleLifeCycleStage.currentProcess
    const { addKitsToBuilding } = handleLifeCycleStage
    const [globalState, setGlobalState] = useGlobalState("globalState")
    const [search, setSearch] = useSearchBar()
    const [kitRows, setKitRows] = useState<KitRow[]>([])
    const [initialKitRows, setInitialKitRows] = useState<KitRow[]>([])
    const {
      kits: defaultKits,
      user: { kits: userKits }
    } = useGlobalState("globalState")[0]
    const toast = useToast()
    const kits = [...defaultKits, ...userKits]
    const [selectedRowsID, setSelectedRowsID] = useState<GridRowId[]>([])
    const [amount, setAmount] = useState<number>(1)

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 3 / 8 },
      { field: 'unit', headerName: 'Unit', flex: 1 / 4 },
      { field: 'description', headerName: 'Description', flex: 3 / 8 },
    ];

    useEffect(() => {
      const formatKits = (kits: (InfiniteKit)[]) => {
        const rows = kits.map((kit: InfiniteKit, idx: number) => (
          {
            id: idx,
            kitId: kit.id,
            name: kit.name,
            description: kit.description,
            unit: kit.unitRef.name
          } as KitRow
        ))
        setKitRows(rows)
        setInitialKitRows(rows)
      }
      if (kits && kits.length > 0 && initialKitRows.length === 0) {
        formatKits(kits)
      }
    }, [kits])


    const handleSelection = (selectionModel: GridRowId[]) => {
      setSelectedRowsID(selectionModel)
    }

    useEffect(() => {
      if (search)
        setKitRows(initialKitRows.filter(row => row.name.includes(search)))
    }, [search])

    const addExchangesToKit = async () => {
      try {
        const kitIds = selectedRowsID.map(
          rowId => kitRows.find(row => row.id === rowId)?.kitId as number
        )
        const promises = kitIds.map(async kitId => {
          await addKitToBuilding(infiniteBuilding.id, kitId, amount)
        })
        await Promise.all(promises)
        const updatedBuilding = await getBuilding(infiniteBuilding.id)
        setGlobalState({
          ...globalState,
          buildings: [
            ...globalState.buildings.map(
              b => b.id === infiniteBuilding.id ? updatedBuilding : b
            )
          ]
        })
        toast.closeAll()
        toast({
          position: "top",
          title: "Success",
          description: "The Kit(s) have been added to the building",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      } catch (error) {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    const handleClose = () => {
      setSelectedRowsID([])
      onClose()
    }

    const handleAddKit = async () => {
      await addExchangesToKit()
      setSelectedRowsID([])
      onClose()
      window.location.reload()
    }

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add kits</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex >
              <VStack w="100%">
                <SearchBar
                  style={{ width: "100%" }}
                  search={search}
                  setSearch={setSearch}
                  placeholder={"Component name"}
                />
                <Box w="100%" h="300px">
                  <DataGrid
                    className="datagridModal"
                    rows={kitRows}
                    columns={columns}
                    checkboxSelection
                    autoPageSize
                    pagination
                    density="compact"
                    onSelectionModelChange={handleSelection}
                    selectionModel={selectedRowsID}
                  />
                </Box>
                <FormControl isRequired>
                  <FormLabel>Amount</FormLabel>
                  <NumberInput
                    value={amount}
                    precision={2}
                    step={0.01}
                    onChange={(v) => setAmount(Number(v))} >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button variant="outline" onClick={handleAddKit}>Add kit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal >

    );
  };

export default AddKits
