/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/display-name */
import { AddIcon, CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import { MenuItem, TextField } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSelectionModel,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import {
  useHandleLifeCycleStagesProps
} from '../../hook/handleInfiniteEntityLifeCycleStages';
import {
  useHandleModifyEntity
} from '../../hook/handleInfiniteEntityModification';
import { useGlobalState } from '../../store/globalState';
import {
  InfiniteLifeCycleStage,
  LifeCycleStageCategory
} from '../../store/schema';
import { LCAStage, LCAStages } from '../../store/types';
import { RunLCA } from '../Icons/icons';
import AddLifeCycleStage from '../Modal/AddLifeCycleStage';
import { Calculation } from '../Modal/Calculation';

const BuildingDetails: FC<{
  handleModifyBuilding: useHandleModifyEntity,
  handleLifeCycleStages: useHandleLifeCycleStagesProps,
  onSave: () => void,
  touched: boolean
}> = ({
  handleModifyBuilding,
  handleLifeCycleStages,
  onSave,
  touched
}) => {
    const [globalState] = useGlobalState("globalState")
    const building = handleModifyBuilding.infiniteEntity
    const flowProperties = handleModifyBuilding.entityFlowProperties
    const isModifiedEnabled = handleModifyBuilding.isModificationEnabled
    const [description, setDescription] = handleModifyBuilding.description
    const [location, setLocation] = handleModifyBuilding.location
    const [referenceAmount, setReferenceAmount] =
      handleModifyBuilding.referenceAmount
    const [unitRef, setUnitRef] = handleModifyBuilding.unitRef
    const [flowProperty, setFlowProperty] = handleModifyBuilding.flowProperty
    const units = handleModifyBuilding.units
    const lifeCycleStages = handleModifyBuilding.lifeCycleStages
    const deleteLCS = handleModifyBuilding.deleteLifeCycleStage
    const { locations } = globalState
    const [selectedStagesIndex, setSelectedStagesIndex] =
      handleLifeCycleStages.selectedStagesIndex
    const {
      isOpen: isCalculationOpen,
      onOpen: onCalculationOpen,
      onClose: onCalculationClose
    } = useDisclosure()
    const [category, setCategory] = useState<string>("")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const handleOpen = (cat: string) => {
      setCategory(cat)
      onOpen()
    }

    const onDescriptionChange =
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
      };

    const onRefAmountChange =
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferenceAmount(parseFloat(event.target.value))
      };

    const onUnitRefChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnitRef(event.target.value)
    }

    const onFlowPropertyChange =
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFlowProperty(event.target.value)
      }

    return (
      <Flex mt={7}>
        <VStack w="100%" h="100%">
          <HStack w="100%" spacing={10} h="100%">
            <VStack alignItems="flex-start" spacing={4} w="50%">
              <HStack w="100%">
                <form style={{ width: "100%" }} noValidate autoComplete="off">
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    label="Description"
                    multiline maxRows={5}
                    value={description}
                    onChange={onDescriptionChange}
                    disabled={!isModifiedEnabled}
                  />
                </form>
              </HStack>
              <HStack>
                <Box
                  as="form"
                  sx={{
                    '& .MuiTextField-root': { width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Flow property"
                    value={flowProperty.name}
                    fullWidth
                    onChange={onFlowPropertyChange}
                    disabled={!isModifiedEnabled}
                  >
                    {flowProperties.map((flowProperty) => (
                      <MenuItem
                        key={flowProperty.name}
                        value={flowProperty.name}
                      >
                        {flowProperty.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box
                  as="form"
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="standard-basic"
                    label="Reference amount"
                    value={referenceAmount}
                    onChange={onRefAmountChange}
                    type="number"
                    disabled={!isModifiedEnabled}
                  />
                </Box>
                <form noValidate autoComplete="off">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unit"

                    value={unitRef.name}
                    onChange={onUnitRefChange}
                    disabled={!isModifiedEnabled}
                  >
                    {units.map((unit) => (
                      <MenuItem key={unit['@id']} value={unit.name}>
                        {unit.isReferenceUnit && <b>{unit.name}</b>}
                        {!unit.isReferenceUnit && unit.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </form>
              </HStack>
              <HStack >
                <form noValidate autoComplete="off">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Location"
                    style={{ width: '25ch' }}
                    value={location.name}
                    onChange={(e) => setLocation(e.target.value)}
                    disabled={!isModifiedEnabled}
                  >
                    {locations.map((loc) => (
                      <MenuItem key={loc.id} value={loc.name}>
                        {loc.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </form>
              </HStack>
            </VStack>

            <Flex w="50%" alignSelf="flex-start" h="100%">
              <StagesList
                lifeCycleStages={lifeCycleStages}
                selectedBuildingStage={selectedStagesIndex}
                setSelectedBuildingStage={setSelectedStagesIndex}
                isModifiedEnabled={isModifiedEnabled}
                handleOpen={handleOpen}
                deleteLCS={deleteLCS}
              />
            </Flex>
          </HStack>
          <Flex pt={10} alignSelf="flex-end" >
            <Button
              disabled={!touched}
              alignSelf="flex-end"
              mr={5}
              leftIcon={<CheckCircleIcon color={"green"} />}
              onClick={onSave}
            >
              Save modification
            </Button>
            <Button
              alignSelf="flex-end"
              onClick={onCalculationOpen}
              leftIcon={<RunLCA size="big" />}
            >
              Run Building LCA
            </Button>
          </Flex>
        </VStack>
        <Calculation
          isOpen={isCalculationOpen}
          onClose={onCalculationClose}
          infiniteEntitiesIds={[building.id]}
          selectedStages={selectedStagesIndex}
          isInfiniteKit={false}
        />
        <AddLifeCycleStage
          category={category}
          handleAddLifeCycleStage={handleModifyBuilding.addLifeCycleStage}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Flex>
    );
  };

const StagesList: FC<{
  lifeCycleStages: InfiniteLifeCycleStage[],
  selectedBuildingStage: GridSelectionModel,
  setSelectedBuildingStage: (
    s: GridSelectionModel
  ) => void,
  isModifiedEnabled: boolean,
  handleOpen: (cat: string) => void,
  deleteLCS: (category: LifeCycleStageCategory) => Promise<void>
}> = ({
  lifeCycleStages,
  selectedBuildingStage,
  setSelectedBuildingStage,
  isModifiedEnabled,
  handleOpen,
  deleteLCS
}) => {

    const toast = useToast()

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 1 / 5 },
      { field: 'description', headerName: 'Description', flex: 3 / 5 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1 / 5,
        align: "right",
        disableColumnMenu: true,
        headerAlign: "right",
        renderCell: (params: GridCellParams) => {
          if (!isModifiedEnabled) return null
          return (
            <HStack spacing={0}>
              <IconButton
                variant='ghost'
                colorScheme='green'
                aria-label='Add life cycle stage'
                fontSize={13}
                icon={<AddIcon />}
                disabled={params.row.description !== ""}
                onClick={() => handleOpen(params.row.name)}
              />
              <IconButton
                variant='ghost'
                colorScheme='red'
                aria-label='Delete life cycle stage'
                fontSize={13}
                icon={<DeleteIcon />}
                disabled={params.row.description === ""}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  deleteLCS(params.row.name
                    .replaceAll(" ", "_")
                    .toUpperCase() as LifeCycleStageCategory
                  ).then(() => {
                    toast.closeAll()
                    toast({
                      position: "top",
                      title: "Delete",
                      description: `Life cycle stage deleted`,
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                    })
                  })
                    .catch((error) => {
                      toast.closeAll()
                      toast({
                        position: "top",
                        title: "Error",
                        description: error as string,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                      })
                    })
                }}
              />
            </HStack>
          )
        }
      }
    ]

    const rows = Object.values(LCAStages).map((stage: LCAStage) => ({
      id: stage.index,
      name: stage.name,
      description: getLCSOf(lifeCycleStages, stage.name) || ""
    }))

    const handleModelChange = (selectionModel: GridSelectionModel) => {
      if (isModifiedEnabled) setSelectedBuildingStage(selectionModel)
    }

    return (
      <DataGrid
        style={{ top: "0px" }}
        autoHeight
        selectionModel={selectedBuildingStage}
        onSelectionModelChange={handleModelChange}
        columns={columns}
        rows={rows}
        headerHeight={0}
        checkboxSelection={isModifiedEnabled}
        disableSelectionOnClick
        hideFooter
        density="compact"
        components={{
          Toolbar: StagesToolBar
        }}
      />
    );
  }

const StagesToolBar: FC = () => {
  return (
    <Flex h="100%">
      <GridToolbarContainer>
        <b>Life cycle stages</b>
      </GridToolbarContainer>
    </Flex>
  );
}


function getLCSOf(
  lifeCycleStages: InfiniteLifeCycleStage[],
  category: string
): string | undefined {
  const lcs = lifeCycleStages.find(
    (lcs) => lcs.category
      .replaceAll("_", " ")
      .toLowerCase() === category.toLowerCase()
  )
  if (!lcs) return undefined
  return lcs.name
}

export default BuildingDetails
