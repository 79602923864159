import { Box, Text, IconButton, Input, Select } from "@chakra-ui/react"
import React, { FC, useEffect } from "react"
import {
  KitSocialIndicator, NewSocialAspect,
  SocialStakeHolder, SocialTheme
} from "../../store/schema"
import { Delete } from "../Icons/icons"
import { ChangeSocialAspect } from "../Kit/KitSocialAspectTab"

const SocialAspect: FC<{
  newSocialAspect: NewSocialAspect,
  changeSocialAspect: ChangeSocialAspect,
  allSocialThemes: SocialTheme[],
  allSocialIndicators: React.MutableRefObject<KitSocialIndicator[]>,
  filteredSocIndis: KitSocialIndicator[],
  socialAspectIndex: number,
  handleDelete: () => void,
  isModificationEnabled: boolean
}> = ({
  newSocialAspect,
  changeSocialAspect,
  allSocialThemes,
  allSocialIndicators,
  filteredSocIndis,
  socialAspectIndex,
  handleDelete,
  isModificationEnabled
}) => {
    useEffect(() => {
      if (allSocialThemes.length > 0)
        changeSocialAspect.setSocialTheme(allSocialThemes[0].id, socialAspectIndex);
    }, [allSocialThemes]);

    useEffect(() => {
      filterSocialIndicatorsByTheme(newSocialAspect.socialTheme);
    }, [newSocialAspect.socialTheme]);

    useEffect(() => {
      if (filteredSocIndis.length > 0)
        changeSocialAspect.changeSocialIndicator(
          filteredSocIndis[0].id, socialAspectIndex);
    }, [filteredSocIndis]);

    useEffect
    const filterSocialIndicatorsByTheme = (socialTheme: number) => {
      const data = allSocialIndicators.current.filter(
        (indicator: KitSocialIndicator) => {
          return indicator.socialTheme.id === socialTheme
        });
      changeSocialAspect.setFilteredSocIndis(data, socialAspectIndex);
    };

    const changeSocialTheme = (event: { target: { value: string } }) => {
      const themeId = parseInt(event.target.value);
      changeSocialAspect.setSocialTheme(themeId, socialAspectIndex);
      if (themeId === 0)
        changeSocialAspect.changeSocialIndicator(themeId, socialAspectIndex);
    };

    const changeCustomSocialTheme = (event: { target: { value: string } }) => {
      changeSocialAspect.setCustomSocialTheme(event.target.value, socialAspectIndex);
    };

    const changeSocialIndicator = (event: { target: { value: string } }) => {
      changeSocialAspect.changeSocialIndicator(
        parseInt(event.target.value), socialAspectIndex);
    };

    const changeCustomSocialIndicator = (event: { target: { value: string } }) => {
      changeSocialAspect.changeCustomSocialIndicator(
        event.target.value, socialAspectIndex);
    };

    const changeCustomSocialIndicatorId = (indicatorId: number) => {
      changeSocialAspect.changeCustomSocialIndicatorId(
        indicatorId, socialAspectIndex);
    };

    const changeStakeholder = (event: { target: { value: string } }) => {
      changeSocialAspect.changeStakeholder(
        (event.target.value as SocialStakeHolder), socialAspectIndex);
    };

    const changeCustomStakeholder = (event: { target: { value: string } }) => {
      changeSocialAspect.changeCustomStakeholder(
        (event.target.value as SocialStakeHolder), socialAspectIndex);
    };

    const changeValue = (event: { target: { value: string } }) => {
      changeSocialAspect.changeValue(
        parseFloat(event.target.value), socialAspectIndex);
    };

    const changeUnit = (event: { target: { value: string } }) => {
      changeSocialAspect.changeUnit(event.target.value, socialAspectIndex);
    };

    const changeDescription = (event: { target: { value: string } }) => {
      changeSocialAspect.changeDescription(
        event.target.value, socialAspectIndex);
    };

    return (
      <>
        <Box p="1">
          <Text as="b">Social theme</Text>
          {
            allSocialThemes && allSocialThemes.length > 0 &&
            <Select
              value={newSocialAspect.socialTheme}
              onChange={changeSocialTheme}>
              {allSocialThemes.map((item: SocialTheme) => (
                <option key={item.id} value={item.id}>
                  {item.theme}
                </option>
              ))}
              <option value="0">Other</option>
            </Select>
          }
        </Box>
        {newSocialAspect.socialTheme === 0 &&
          <Box p="1">
            <Text as="b">Custom social theme</Text>
            <Input type="text" value={newSocialAspect.customSocialTheme}
              onChange={changeCustomSocialTheme} />
          </Box>
        }
        <Box p="1">
          <Text as="b">Social indicator</Text>
          <Select
            value={newSocialAspect?.socialIndicatorId}
            onChange={changeSocialIndicator}>
            {filteredSocIndis && filteredSocIndis.length > 0 &&
              filteredSocIndis.map((item: KitSocialIndicator) => (
                <option key={item.id} value={item.id}>
                  {item.indicator}
                </option>
              ))
            }
            <option key="0" value="0">Other</option>
          </Select>
        </Box>
        {((newSocialAspect?.socialIndicatorId === 0)
          || (newSocialAspect.socialTheme === 0)) &&
          <Box p="1">
            <Text as="b">Custom social indicator</Text>
            <Input type="text" value={newSocialAspect?.customSocialIndicator}
              onChange={changeCustomSocialIndicator} />
          </Box>
        }
        <Box p="1">
          <Text as="b">Stakeholder</Text>
          <Select
            value={newSocialAspect?.stakeholder}
            onChange={changeStakeholder}>
            <option value={"Workers"}>
              Workers
            </option>
            <option value={"Residents"}>
              Residents
            </option>
            <option value={"Local community"}>
              Local community
            </option>
            <option value={"Value chain actors"}>
              Value chain actors
            </option>
            <option value="other">Other</option>
          </Select>
        </Box>
        {newSocialAspect?.stakeholder === "other" &&
          <Box p="1">
            <Text as="b">Custom stakeholder</Text>
            <Input type="text" value={newSocialAspect?.customStakeholder}
              onChange={changeCustomStakeholder} />
          </Box>
        }
        <Box p="1">
          <Text as="b">Social aspect value</Text>
          <Input type="number" value={newSocialAspect?.value}
            onChange={changeValue} />
        </Box>
        <Box p="1">
          <Text as="b">Social aspect unit</Text>
          <Input type="text" value={newSocialAspect?.unit}
            onChange={changeUnit} />
        </Box>
        <Box p="1">
          <Text as="b">Social aspect description</Text>
          <Input type="text" value={newSocialAspect?.description}
            onChange={changeDescription} />
        </Box>
        <Box p="1">
          <IconButton
            mr={2}
            colorScheme="red"
            size="xs"
            aria-label="Remove social aspect"
            icon={<Delete />}
            onClick={handleDelete}
            disabled={!isModificationEnabled}
          />
        </Box>
      </>
    )
  }

export default SocialAspect;
