import axios from "axios";
import {
  SocialTheme
} from "../store/schema";
import configData from "./config.json";

const socialThemeService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/social-themes",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getSocialThemes = async (
  skip?: number,
  limit?: number
): Promise<SocialTheme[]> => {
  const response = await socialThemeService.get<SocialTheme[]>(
    `/?skip=${skip || 0}&limit=${limit || 100}`
  );
  return response.data;
};

export const createSocialThemes = async (
  theme: string
): Promise<SocialTheme> => {
  const response = await socialThemeService.post<SocialTheme>("/", {
    theme
  });
  return response.data;
};
