/* eslint-disable indent */

import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useGlobalState } from "../../store/globalState";
import { InfiniteKitCreate, Unit } from "../../store/schema";
import { getFlowProperty, getUnitGroup } from "../../api/olca.service";
import { useKit } from "../../services/kits.service";

type AddKitProps = {
  isOpen: boolean,
  onClose: () => void,
}

function AddKit({
  isOpen,
  onClose
}: AddKitProps): JSX.Element {
  const initialRef = useRef(null)
  const [{ flowProperties }] = useGlobalState("globalState")
  const [units, setUnits] = useState<Unit[]>([])

  const [name, setName] = useState<string | undefined>(undefined)
  const [
    manufacturer,
    setManufacturer
  ] = useState<string | undefined>(undefined)
  const [
    description,
    setDescription
  ] = useState<string | undefined>(undefined)
  const [
    flowProperty,
    setFlowProperty
  ] = useState<string | undefined>(undefined)
  const [unit, setUnit] = useState<string | undefined>(undefined)
  const [amount, setAmount] = useState<number>(1)

  const { createCustomKit } = useKit()

  const toast = useToast()

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    setter: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    const value = event.target.value
    setter(value)
  }

  const handleCreate = () => {
    if (!name || !unit || !flowProperty) return
    const kit: InfiniteKitCreate = {
      name,
      manufacturer,
      description,
      flowPropertyRef: {
        id: flowProperty
      },
      unitRef: {
        id: unit
      },
      referenceAmount: amount,
      lifeCycleStages: []
    }
    createCustomKit(kit)
      .then(() => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Creation",
          description: `Kit created succesfully`,
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      })
  }

  useEffect(() => {
    if (!flowProperty) return
    const fetchFlowProperty = async () => {
      const fp = await getFlowProperty(flowProperty)
      if (!fp.unitGroup) return
      const ug = await getUnitGroup(fp.unitGroup["@id"])
      if (!ug.units) return
      setUnits(ug.units)
    }
    fetchFlowProperty()
      .catch((error) => {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      })

  }, [flowProperty])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new kit</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                type='text'
                value={name}
                onChange={(e) => handleInputChange(e, setName)} />
            </FormControl>
            <FormControl>
              <FormLabel>Manufacturer</FormLabel>
              <Input
                type='text'
                value={manufacturer}
                onChange={(e) => handleInputChange(e, setManufacturer)} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => handleInputChange(e, setDescription)} />
            </FormControl>
            <FormControl>
              <FormLabel>Flow property</FormLabel>
              <Select
                placeholder='Select kit flow property'
                value={flowProperty}
                onChange={(e) => handleInputChange(e, setFlowProperty)}>
                {flowProperties.map((flowProperty) => (
                  <option
                    key={flowProperty["@id"]}
                    value={flowProperty["@id"]}
                  >
                    {flowProperty.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Unit</FormLabel>
              <Select
                placeholder='Select kit unit'
                value={unit}
                onChange={(e) => handleInputChange(e, setUnit)}>
                {units.map((unit) => (
                  <option key={unit["@id"]} value={unit["@id"]}>
                    {unit.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Amount</FormLabel>
              <NumberInput
                value={amount}
                precision={2}
                step={0.01}
                onChange={(v) => setAmount(Number(v))} >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!name || !unit || !flowProperty}
            onClick={() => { handleCreate(); onClose(); }}
          >
            Add Kit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddKit
