import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import * as kitService from '../api/kit.service';
import * as buildingService from '../api/building.service';
import { getFlowProperty, getUnitGroup } from '../api/olca.service';
import {
  isInfiniteCustomBuilding,
  useBuilding
} from '../services/buildings.service';
import { useKit } from '../services/kits.service';
import { useGlobalState } from '../store/globalState';
import {
  InfiniteBuilding,
  InfiniteBuildingUpdate,
  InfiniteCustomKit,
  InfiniteEntityRef,
  InfiniteEntityStatus,
  InfiniteEntityUpdate,
  InfiniteKit,
  InfiniteKitUpdate,
  InfiniteLifeCycleStage,
  InfiniteLifeCycleStageCreate,
  LifeCycleStageCategory,
  Ref,
  Unit
} from '../store/schema';
import { dummyInfiniteEntity, InfiniteEntity } from '../store/types';
import { isInfiniteCustomEntity, isInfiniteKit } from '../utils/utils';

type State = {
  name: string,
  initialize: boolean,
  description: string,
  location: InfiniteEntityRef,
  manufacturer: string,
  referenceAmount: number,
  units: Unit[],
  unitRef: InfiniteEntityRef,
  isModifiedEnabled: boolean,
  flowProperty: InfiniteEntityRef,
  entityFlowProperties: Ref[],
  lifeCycleStages: InfiniteLifeCycleStage[],
  touched: boolean,
  status: InfiniteEntityStatus
}
export type useHandleModifyEntity = {
  name: [string, (n: string) => void],
  description: [string, (desc: string) => void],
  referenceAmount: [number, (ref: number) => void],
  flowProperty: [InfiniteEntityRef, (fp: string) => void],
  touched: [boolean, (b: boolean) => void],
  manufacturer: [string, (m: string) => void],
  location: [InfiniteEntityRef, (l: string) => void],
  units: Unit[],
  unitRef: [InfiniteEntityRef, (u: string) => void],
  lifeCycleStages: InfiniteLifeCycleStage[],
  addLifeCycleStage: (lcs: InfiniteLifeCycleStageCreate) => Promise<void>,
  deleteLifeCycleStage: (category: LifeCycleStageCategory) => Promise<void>,
  isModificationEnabled: boolean,
  infiniteEntity: InfiniteEntity,
  entityFlowProperties: Ref[],
  status: [InfiniteEntityStatus, (l: InfiniteEntityStatus) => void],
  onSave: () => void,
  onCreate: () => Promise<number>
}
const useHandleModifyEntity: (
  infiniteEntity: InfiniteEntity,
  setEntity: React.Dispatch<React.SetStateAction<InfiniteKit>> |
    React.Dispatch<React.SetStateAction<InfiniteBuilding>>
) => useHandleModifyEntity = (infiniteEntity, setEntity) => {
  const [state, setState] = useState<State>({
    description: "",
    name: "",
    flowProperty: dummyInfiniteEntity,
    initialize: false,
    isModifiedEnabled: false,
    entityFlowProperties: [],
    location: dummyInfiniteEntity,
    manufacturer: "",
    referenceAmount: 0,
    touched: false,
    unitRef: dummyInfiniteEntity,
    units: [],
    lifeCycleStages: [],
    status: InfiniteEntityStatus.PRELIMINARY
  })

  const {
    description,
    flowProperty,
    isModifiedEnabled,
    entityFlowProperties,
    location,
    manufacturer,
    name,
    referenceAmount,
    touched,
    unitRef,
    units,
    lifeCycleStages,
    status
  } = state
  const [{ flowProperties, locations }] = useGlobalState("globalState")

  const updateUnits = async (flowPropertyId: string) => {
    try {
      const units = await fetchUnits(flowPropertyId) as Unit[]
      const unitRef = units?.find(u => u.isReferenceUnit) as Unit
      return {
        unitRef: {
          id: unitRef?.['@id'],
          name: unitRef?.name as string
        },
        units: units ? units : []
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUnits = async (flowPropertyId: string) => {
    try {
      const flowPropertyTMP = await getFlowProperty(flowPropertyId)
      const unitGroup =
        await getUnitGroup(
          flowPropertyTMP.unitGroup?.['@id'] as string
        )
      return unitGroup.units ? unitGroup.units : []

    } catch (error) {
      console.log(error)
    }
  }

  const { updateCustomBuilding, createCustomBuilding } = useBuilding()
  const { updateCustomKit, createCustomKit } = useKit()
  const toast = useToast()

  const setInitialState = async () => {
    const units = await fetchUnits(
      infiniteEntity.flowPropertyRef.id
    ) as Unit[]
    setState({
      ...state,
      description: infiniteEntity.description,
      flowProperty: infiniteEntity.flowPropertyRef,
      location: infiniteEntity.locationRef,
      name: infiniteEntity.name,
      referenceAmount: infiniteEntity.referenceAmount,
      unitRef: infiniteEntity.unitRef,
      manufacturer: isInfiniteKit(infiniteEntity) ?
        infiniteEntity.manufacturer : "",
      units,
      lifeCycleStages: infiniteEntity.lifeCycleStages,
      initialize: true,
      isModifiedEnabled: isInfiniteCustomEntity(infiniteEntity),
      status: infiniteEntity.status || InfiniteEntityStatus.PRELIMINARY
    })
  }

  useEffect(() => {
    if (flowProperties)
      setState({ ...state, entityFlowProperties: flowProperties })
  }, [flowProperties])

  useEffect(() => {

    if (infiniteEntity.id !== -1) {
      void setInitialState()
    }
  }, [infiniteEntity])

  const handleSetName = (name: string) => {
    setState({ ...state, name, touched: true })
  }
  const handleSetUnitRef = (unitRef: string) => {
    const newUnitRef = units.find(
      unit => unit.name === unitRef
    ) as Unit
    setState({
      ...state,
      unitRef: {
        id: newUnitRef["@id"],
        name: newUnitRef.name as string
      },
      touched: true
    })
  }

  const handleSetDescription = (description: string) => {
    setState({ ...state, description, touched: true })
  }

  const handleSetRefAmount = (referenceAmount: number) => {
    setState({ ...state, referenceAmount, touched: true })
  }

  const handleSetFlowProperty = async (flowProperty: string) => {
    const newFlowProperty = flowProperties.find(
      fp => fp.name === flowProperty
    ) as Ref
    const units = await updateUnits(newFlowProperty["@id"])
    if (units)
      setState({
        ...state,
        ...units,
        flowProperty: {
          id: newFlowProperty["@id"],
          name: newFlowProperty.name as string
        },
        touched: true
      })
  }

  const handleSetLocation = (location: string) => {
    const newLocation = locations.find(
      loc => loc.name === location
    ) as InfiniteEntityRef
    setState({ ...state, location: newLocation, touched: true })
  }

  const handleSetManufacturer = (manufacturer: string) => {
    setState({ ...state, manufacturer, touched: true })
  }

  const handleSetTouched = (touched: boolean) => {
    setState({ ...state, touched: touched })
  }

  const handleSetStatus = (status: InfiniteEntityStatus) => {
    setState({ ...state, touched: true, status })
  }

  const handleAddLifeCycleStage =
    async (lcs: InfiniteLifeCycleStageCreate) => {
      try {
        let entity
        if (isInfiniteCustomBuilding(infiniteEntity)) {
          entity = await buildingService.createLCS(
            infiniteEntity.id,
            lcs.name,
            lcs.category,
            lcs.processRefId
          )
        } else {
          entity = await kitService.createLCS(
            infiniteEntity.id,
            lcs.name,
            lcs.category,
            lcs.processRefId
          )
        }
        const newLcs = entity.lifeCycleStages.find(
          l => l.category === lcs.category
        )
        if (!newLcs) return
        setState({
          ...state,
          lifeCycleStages: [...lifeCycleStages, newLcs]
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEntity(entity)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

  const handleDeleteLifeCycleStage =
    async (category: LifeCycleStageCategory) => {
      try {
        let entity
        if (isInfiniteCustomBuilding(infiniteEntity)) {
          await buildingService.deleteLCS(infiniteEntity.id, category)
          entity = await buildingService.getBuilding(infiniteEntity.id)
        } else {
          await kitService.deleteLCS(infiniteEntity.id, category)
          entity = await kitService.getKit(infiniteEntity.id)
        }
        const newLifeCycleStages = lifeCycleStages.filter(
          l => l.category !== category
        )
        setState({ ...state, lifeCycleStages: newLifeCycleStages })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEntity(entity)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

  const handleOnSave = async () => {
    const entityUpdate: InfiniteEntityUpdate = {
      name: name,
      description: description,
      flowPropertyRefId: flowProperty.id,
      unitRefId: unitRef.id,
      referenceAmount: referenceAmount,
      status,
    }
    if (isInfiniteCustomBuilding(infiniteEntity)) {
      (entityUpdate as InfiniteBuildingUpdate)
        .locationRefId = location.id;
      await updateCustomBuilding(infiniteEntity.id, entityUpdate)
    } else {
      (entityUpdate as InfiniteKitUpdate).manufacturer = manufacturer;
      await updateCustomKit(infiniteEntity.id, entityUpdate)
    }
    setState({ ...state, touched: false })

    toast.closeAll()
    const entityName =
      isInfiniteCustomBuilding(infiniteEntity) ? 'Building ' : 'Kit'
    toast({
      position: "top",
      title: "Modification",
      description: `${entityName} modified succesfully`,
      status: "success",
      duration: 4000,
      isClosable: true,
    })
  }

  const handleOnCreate = async () => {
    let createdEntity: InfiniteEntity
    if (isInfiniteCustomBuilding(infiniteEntity))
      createdEntity = await createCustomBuilding(infiniteEntity)
    else
      createdEntity = await createCustomKit(
        infiniteEntity as InfiniteCustomKit
      )

    setState({ ...state, touched: false })

    toast.closeAll()
    const entityName =
      isInfiniteCustomBuilding(infiniteEntity) ? 'Building ' : 'Kit'
    toast({
      position: "top",
      title: "Creation",
      description: `${entityName} created succesfully`,
      status: "success",
      duration: 4000,
      isClosable: true,
    })
    return createdEntity.id
  }

  return (
    {
      name: [name, handleSetName],
      description: [description, handleSetDescription],
      referenceAmount: [referenceAmount, handleSetRefAmount],
      flowProperty: [flowProperty, handleSetFlowProperty],
      location: [location, handleSetLocation],
      touched: [touched, handleSetTouched],
      manufacturer: [manufacturer, handleSetManufacturer],
      units: units,
      unitRef: [unitRef, handleSetUnitRef],
      lifeCycleStages,
      addLifeCycleStage: handleAddLifeCycleStage,
      deleteLifeCycleStage: handleDeleteLifeCycleStage,
      status: [status, handleSetStatus],
      isModificationEnabled: isModifiedEnabled,
      infiniteEntity,
      entityFlowProperties: entityFlowProperties,
      onSave: handleOnSave,
      onCreate: handleOnCreate,
    }
  );
};

export default useHandleModifyEntity
