/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from "@chakra-ui/react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from "@material-ui/core"
import {
  ChartData,
  ChartDataset,
  ChartEvent,
  LegendElement,
  LegendItem,
  TooltipItem
} from "chart.js"
import "chart.js/auto"
import { ExtendedChartData } from "chartjs-plugin-stacked100/build/types"
import React, { FC, useEffect, useRef, useState } from "react"
import { Bar } from "react-chartjs-2"
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types"
import { Ref } from "../../store/schema"
import { ChartOptionsBar } from "../../store/types"
import { deepCopy, formatNumber } from "../../utils/utils"
import ChartDataLabels from 'chartjs-plugin-datalabels';

/**
 * Component that draw a horizontal stacked bar chart.
 * It is using the framework `chart.js`, for drawing the chart,
 * and also an external plugin `chartjs-plugin-stacked100`
 * @param data The chart data
 * @returns
 */
export const CategoryChart: FC<{
  data: ChartData<"bar">
  impactCategories: Ref[],
  selectedStagesNumber: number
}> = ({ data, impactCategories,
  selectedStagesNumber }) => {
    const [impactIdx, setImpactIdx] = useState(0)
    const [chartData, setChartData] = useState(data)
    const chartRef =
      useRef<ChartJSOrUndefined<"bar", number[], unknown> | null>(null)
    const indexOfFirstDatasetOfStack = (
      datasets: ChartDataset<"bar", number[]>[]
    ) => {
      const indexArray: number[] = [];
      const tempArray: string[] = [];
      datasets.forEach((dataset, index) => {
        if (!tempArray.includes(dataset.stack as string)) {
          tempArray.push(dataset.stack as string);
          indexArray.push(index);
        }
      });
      return indexArray;
    };
    useEffect(() => {
      const dataTMP = deepCopy(data)
      dataTMP.datasets.map(
        (dataset) => dataset.data = [dataset.data[impactIdx]]
      )
      dataTMP.labels = data.labels?.filter((label, idx) => idx === impactIdx)
      setChartData(dataTMP)
    }, [impactIdx])

    const options: ChartOptionsBar = {
      indexAxis: "y", // Rotate the chart, to have horizontal bars
      scales: {
        // Stack the bars
        x: {
          title: {
            display: true,
            text: "Impact contribution amount",
            font: {
              size: 20,
            },
          },

        },
        y: {
          title: {
            display: true,
            text: "Impact category",
            font: {
              size: 20,
            },
          },
        },
      },
      interaction: {
        // Tooltip interaction
        intersect: false, // Tooltip is displayed anytime
        mode: "nearest", // Tooltip display all information about the nearest bar
        axis: "y",
      },
      plugins: {
        title: {
          // Chart title
          display: true,
          text: "Impact category contributions",
          font: {
            size: 30,
          },
        },
        tooltip: {
          callbacks: {
            title: (titleItem: TooltipItem<"bar">[]) => {
              return titleItem[0].dataset.stack as string
            },
            label: (tooltipItem: TooltipItem<"bar">) => {
              const data = tooltipItem.chart.data as ExtendedChartData
              const datasetIndex = tooltipItem.datasetIndex
              const index = tooltipItem.dataIndex
              const datasetLabel =
                data.datasets[datasetIndex].label || ""
              const value =
                formatNumber(data.datasets[datasetIndex].data[index] as number)
              return `${datasetLabel}: ${value} `;
            },
          },

          // Sort the tooltip items by descending order
          itemSort: (
            item1: TooltipItem<"bar">,
            item2: TooltipItem<"bar">
          ) => (item2.raw as number) - (item1.raw as number),
        },
        legend: {
          labels: {
            // Avoid having duplicate legend items
            filter: (legendItem: LegendItem) => {
              return !(legendItem.datasetIndex as number >
                selectedStagesNumber - 1)
            }
          },

          // Hide/show every dataset that is linked to the selected LCS
          onClick: (
            _: ChartEvent,
            legendItem: LegendItem,
            legend: LegendElement<"bar">
          ) => {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            let idx = index as number % selectedStagesNumber
            const max = data.datasets.length
            if (ci.isDatasetVisible(index as number)) {
              while (idx < max) {
                ci.hide(idx);
                idx += selectedStagesNumber
              }
              legendItem.hidden = true;
            } else {
              while (idx < max) {
                ci.show(idx);
                idx += selectedStagesNumber
              }
              legendItem.hidden = false;
            }
          }
        },
        datalabels: {
          formatter: function (value, context) {
            if (indexOfFirstDatasetOfStack(chartData.datasets)
              .includes(context.datasetIndex)) {
              return context.dataset.stack;
            }
            return "";
          },
          anchor: "start",
          align: "left",
          offset: 10
        }
      },
    }
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setImpactIdx(event.target.value as number)
    }
    return (
      <Box mt={10}>
        <FormControl >
          <InputLabel id="demo-simple-select-label">
            Impact category
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={impactIdx}
            label="Impact category"
            onChange={handleChange}
          >
            {impactCategories.map((impactCategory, idx) => (
              <MenuItem key={idx} value={idx}>
                {impactCategory.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Paper style={{ width: "50%", marginTop: 20 }}>
          <Bar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={[ChartDataLabels]}
          />
        </Paper>
      </Box>
    )
  }
