import axios, { AxiosResponse } from "axios";
import configData from "../config.json";
import { BIMPAuthBody } from "../../store/schema";

const authService = axios.create({
  baseURL: configData.BIMP_API_URL + "/Auth",
  headers: {
    "accept": "application/json",
    "Content-Type": "application/json"
  },
  responseType: "json"
});

const authBody: BIMPAuthBody = {
  "client_id": "infinite-lca-tool",
  "client_secret": "N5tjJDe5FM7q3I1Pt5Asy1ZgD3RHGjIA",
  "grant_type": "client_credentials"
};

export const getBIMPAuthToken = async (): Promise<AxiosResponse<string>> => {
  const response: AxiosResponse<string> = await authService.post<string>(
    "",
    authBody
  );
  return response;
};
