import { Exchange, FlowType } from "../store/schema";
import { ComponentType } from "../store/types";

export const getComponentType = (exchange: Exchange): ComponentType => {
  if (exchange.isInput) {
    if (exchange.flow?.flowType === FlowType.ELEMENTARY_FLOW)
      return "Resource"
    else
      return "Product"
  } else {
    if (exchange.flow?.flowType === FlowType.ELEMENTARY_FLOW)
      return "Emission"
    else if (exchange.flow?.flowType === FlowType.PRODUCT_FLOW)
      return "Product"
    else
      return "Waste"

  }
}
