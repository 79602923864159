/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import {
  DataGrid,
  GridAddIcon,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import {
  formatBuildingRefAmount,
  useBuilding
} from '../../services/buildings.service';
import { useGlobalState } from '../../store/globalState';
import { InfiniteBuilding, InfiniteEntityStatus } from '../../store/schema';
import { Delete, Info, RunLCA } from '../Icons/icons';
import AddBuilding from '../Modal/AddBuilding';
import DeleteComponent from '../Modal/DeleteComponent';
import StatusChip from '../StatusChipMenu/StatusChip';

type BuildingRow = {
  id: number,
  buildingId: number,
  name: string,
  description: string,
  lastChange: string,
  status: InfiniteEntityStatus
}
type State = {
  buildingsRow: GridRowsProp,
  initialBuildingsRow: BuildingRow[],
  selectedRowsID: GridRowId[],
}
const UserBuildingLibrary: FC<{
  searchString: string,
  setBuildingIds: Dispatch<SetStateAction<number[]>>,
  onCalculationOpen: () => void
}> = ({
  searchString,
  setBuildingIds,
  onCalculationOpen
}) => {
    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose
    } = useDisclosure()

    const { deleteCustomBuilding } = useBuilding()
    const toast = useToast()
    const history = useHistory()
    const [globalState] = useGlobalState("globalState")
    const { buildings } = globalState.user
    const [state, setState] = useState<State>({
      buildingsRow: [],
      initialBuildingsRow: [],
      selectedRowsID: [],
    })
    const { buildingsRow, initialBuildingsRow, selectedRowsID } = state
    const isEditEnabled = true
    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', flex: 6 / 24 },
      { field: 'referenceAmount', headerName: 'Reference amount', flex: 4 / 24 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 3 / 24,
        renderCell: (params: GridCellParams) => (
          <StatusChip status={params.row.status} />
        )
      },
      { field: 'description', headerName: 'Description', flex: 6 / 24 },
      {
        field: 'lastChange',
        headerName: 'Last change',
        flex: 3 / 24,
        type: "date",
        valueFormatter: (params: GridValueFormatterParams) => {
          return new Date(params.value as Date).toLocaleDateString()
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 3 / 24,
        align: "right",
        disableColumnMenu: true,
        headerAlign: "right",
        renderCell: (params: GridCellParams) => (
          <HStack>
            <Link
              as={ReachLink}
              to={`/buildings/${params.row.buildingId as number}`}
            >
              <Info />
            </Link>
            <Link as={ReachLink} to="#" onClick={() => handleRowDelete(params)}>
              <Delete color />
            </Link>
            <Link as={ReachLink} to="#" onClick={() => handleRowLCA(params)}>
              <RunLCA color />
            </Link>
          </HStack>
        )
      }
    ];

    useEffect(() => {
      const buildingIds = selectedRowsID.map(rowId => {
        const buildingId = (buildingsRow.find(
          row => row.id === rowId
        ) as BuildingRow).buildingId
        return buildingId
      })
      setBuildingIds(buildingIds)
    }, [selectedRowsID])

    useEffect(() => {
      /**
        * Filter the buildings on name, description or creation date.
        * It is case insensitive
        * @returns The filtered rows array
        */
      const filterRows = () => {
        const formatedSearch = searchString.toLowerCase()
        return initialBuildingsRow.filter(row =>
          row.name.toLowerCase().includes(formatedSearch)
        )
      }
      setState({ ...state, buildingsRow: filterRows() })
    }, [searchString])

    useEffect(() => {
      const formatBuildings = (buildings: InfiniteBuilding[]) => {
        const rows: BuildingRow[] = buildings.map(
          (building: InfiniteBuilding, idx: number) => (
            {
              id: idx,
              buildingId: building.id,
              name: building.name,
              referenceAmount: formatBuildingRefAmount(building),
              location: building.locationRef.name,
              lastChange: building.lastChange,
              description: building.description,
              status: building.status || InfiniteEntityStatus.PRELIMINARY
            }
          ))
        setState({ ...state, buildingsRow: rows, initialBuildingsRow: rows })
      }
      if (buildings)
        formatBuildings(buildings)
    }, [globalState.user.buildings])

    const handleSelection = (selectionModel: GridRowId[]) => {
      setState({ ...state, selectedRowsID: selectionModel })
    }

    const handleDoucleClick = (param: GridRowParams) => {
      history.push(`/buildings/${param.row.buildingId as string}`)
    }

    const handleDelete = async () => {
      try {
        const buildingRowsToDelete =
          buildingsRow.filter(row => selectedRowsID.includes(row.id))
        const buildingIdsToDelete =
          buildingRowsToDelete.map(row => row.buildingId as number)
        await deleteCustomBuilding(buildingIdsToDelete)
        setState({
          ...state,
          selectedRowsID: [],
          buildingsRow: [
            ...buildingsRow.filter(
              row => buildingRowsToDelete.includes(row.id)
            )
          ]
        })
        setBuildingIds([])
        onDeleteClose()
      } catch (error) {
        toast.closeAll()
        toast({
          position: "top",
          title: "Error",
          description: error as string,
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    }

    const handleRowDelete = (params: GridCellParams) => {
      setState({ ...state, selectedRowsID: [params.row.id] })
      onDeleteOpen()
    }

    const handleRowLCA = (params: GridCellParams) => {
      setState({ ...state, selectedRowsID: [params.row.id] })
      onCalculationOpen()
    }

    return (
      <>
        <Flex h="69vh">
          <DataGrid
            rows={buildingsRow}
            columns={columns}
            checkboxSelection
            autoPageSize
            pagination
            density="compact"
            onSelectionModelChange={handleSelection}
            onRowDoubleClick={handleDoucleClick}
            components={{
              Toolbar: () => CustomToolbar({
                selectedRowsID,
                onDeleteOpen,
                isEdit: isEditEnabled
              })
            }}
            selectionModel={selectedRowsID}
            disableSelectionOnClick />
        </Flex>
        <DeleteComponent
          handleDelete={handleDelete}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      </>
    );
  };

const CustomToolbar: FC<{
  selectedRowsID: GridRowId[],
  onDeleteOpen: () => void,
  isEdit: boolean
}> = ({
  selectedRowsID,
  onDeleteOpen,
  isEdit
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
      <>
        <Flex>
          <Text ml={5} fontSize={"lg"}>User building library</Text>
          <Spacer />
          <GridToolbarContainer>

            {isEdit &&
              <IconButton
                mr={2}
                colorScheme="green"
                size="xs"
                aria-label="Search database"
                icon={<GridAddIcon />}
                onClick={onOpen}
              />
            }
            {isEdit &&
              <IconButton
                mr={2}
                isDisabled={selectedRowsID.length === 0}
                colorScheme="red"
                size="xs"
                aria-label="Search database"
                icon={<Delete />}
                onClick={onDeleteOpen}
              />
            }
          </GridToolbarContainer>
        </Flex>
        <AddBuilding isOpen={isOpen} onClose={onClose} />
      </>
    );
  }

export default UserBuildingLibrary
