import { useToast, IconButton } from '@chakra-ui/react';
import {
  Step, StepButton,
  StepLabel, Stepper
} from "@material-ui/core";
import {
  KitSocialAspect,
  KitSocialIndicator,
  LifeCycleStageCategory,
  NewSocialAspect,
  SocialStakeHolder,
  SocialTheme
} from "../../store/schema";
import React, { FC, useState, useEffect, useRef } from "react";
import { useHandleLifeCycleStagesProps }
  from "../../hook/handleInfiniteEntityLifeCycleStages";
import { useHandleModifyEntity }
  from '../../hook/handleInfiniteEntityModification';
import { LCSName } from "../../store/types";
import {
  createKitSocialAspectByLCSCategory,
  deleteKitSocialAspectByLCSCategory,
  getKitSocialAspectByLCSCategory
} from "../../api/kit.service";
import { createSocialIndicators, getSocialIndicators }
  from '../../api/social-indicator.service';
import { createSocialThemes, getSocialThemes }
  from '../../api/social-theme.service';
import {
  Box, Flex, HStack, VStack,
  Text, Divider, Button
} from "@chakra-ui/react";
import { IoMdAddCircleOutline } from "react-icons/io";
import SocialAspect from "../SocialAspect/SocialAspect";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Delete } from '../Icons/icons';

const KitSocialAspectTab: FC<{
  handleModifyKit: useHandleModifyEntity,
  handleLifeCycleStages: useHandleLifeCycleStagesProps
}> = ({ handleModifyKit, handleLifeCycleStages }) => {
  const [
    selectedStagesCategory
  ] = handleLifeCycleStages.selectedStagesCategory;
  const [
    activeStep,
    setActiveStepAndStage
  ] = handleLifeCycleStages.activeStepAndStage;
  const currentLCS = handleLifeCycleStages.currentLCS;
  const kit = handleModifyKit.infiniteEntity;
  const isModificationEnabled = handleModifyKit.isModificationEnabled;
  const [touched, setTouched] = handleModifyKit.touched;
  const [allSocialThemes, setAllSocialThemes]
    = useState<SocialTheme[]>([]);
  const allSocialIndicators = useRef<KitSocialIndicator[]>([]);
  const [
    socialAspects,
    setSocialAspects
  ] = useState<KitSocialAspect[]>([]);
  const [newSocialAspects,
    setNewSocialAspects] = useState<NewSocialAspect[]>([]);
  const toast = useToast();

  const handleStep = (stage: LifeCycleStageCategory, step: number) => {
    setActiveStepAndStage(stage, step)
  };

  const getKitSocialAspects = async () => {
    const socialAspects =
      await getKitSocialAspectByLCSCategory(kit.id, currentLCS);
    setSocialAspects(socialAspects);
  };

  useEffect(() => { void getKitSocialAspects() }, [currentLCS]);

  useEffect(() => {
    if (newSocialAspects.length === 0)
      setTouched(false);
  }, [newSocialAspects]);

  useEffect(() => {
    getAllSocialThemesAndIndicators();
  }, []);

  const addSocialAspect = (): void => {
    const data = [{
      id: new Date().getMilliseconds(),
      socialTheme: 0,
      customSocialTheme: "",
      customSocialThemeId: 0,
      filteredSocIndis: [],
      stakeholder: "Workers",
      customStakeholder: "",
      value: 0,
      unit: "",
      description: "",
      socialIndicatorId: 1,
      customSocialIndicator: "",
      customSocialIndicatorId: 0
    }, ...newSocialAspects];
    setNewSocialAspects(data);
    setTouched(true);
  };

  const getAllSocialThemesAndIndicators = (isReload: boolean = false) => {
    if (allSocialIndicators.current.length === 0 ||
      allSocialThemes.length === 0 || isReload) {
      Promise.allSettled([getSocialThemes(), getSocialIndicators()])
        .then((results) => {
          if (results[0].status === "fulfilled")
            setAllSocialThemes(results[0].value);
          if (results[1].status === "fulfilled")
            allSocialIndicators.current = results[1].value;
        }).catch(() => {
          showResultMessage(
            "error",
            "Retrieve social aspects",
            "Error getting social themes or indicators"
          )
        });
    }
  };

  const removeSocialAspect = async (socialAspectId: number) => {
    await deleteKitSocialAspectByLCSCategory(
      kit.id,
      socialAspectId,
      currentLCS
    );
    const data = socialAspects.filter(socialAspect =>
      socialAspect.id !== socialAspectId
    );
    setSocialAspects(data);
    showResultMessage(
      "success",
      "Delete social aspects",
      "Social aspects deleted succesfully"
    );
  };

  const removeNewSocialAspect = (index: number): void => {
    const data = [...newSocialAspects];
    data.splice(index, 1);
    setNewSocialAspects(data);
    setTouched(true);
  };

  const changeSocialIndicator = (indicator: number, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].socialIndicatorId = indicator;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeCustomSocialIndicator = (indicator: string, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].customSocialIndicator = indicator;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeCustomSocialIndicatorId = (
    indicator: number,
    index: number
  ) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].customSocialIndicatorId = indicator;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeStakeholder = (
    stakeholder: SocialStakeHolder,
    index: number
  ) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].stakeholder = stakeholder;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeCustomStakeholder = (
    stakeholder: SocialStakeHolder,
    index: number
  ) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].customStakeholder = stakeholder;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeValue = (value: number, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].value = value;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeUnit = (unit: string, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].unit = unit;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeDescription = (description: string, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].description = description;
    setNewSocialAspects(changedSocialAspects);
  };

  const setSocialTheme = (themeId: number, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].socialTheme = themeId;
    setNewSocialAspects(changedSocialAspects);
  };

  const setCustomSocialTheme = (theme: string, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].customSocialTheme = theme;
    setNewSocialAspects(changedSocialAspects);
  };

  const setFilteredSocIndis = (
    filteredSocIndis: KitSocialIndicator[],
    index: number
  ) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].filteredSocIndis = filteredSocIndis;
    setNewSocialAspects(changedSocialAspects);
  };

  const setCustomSocialThemeId = (themeId: number, index: number) => {
    const changedSocialAspects = [...newSocialAspects];
    changedSocialAspects[index].customSocialThemeId = themeId;
    setNewSocialAspects(changedSocialAspects);
  };

  const changeSocialAspect: ChangeSocialAspect = {
    setSocialTheme: setSocialTheme,
    setCustomSocialTheme: setCustomSocialTheme,
    setCustomSocialThemeId: setCustomSocialThemeId,
    setFilteredSocIndis: setFilteredSocIndis,
    changeSocialIndicator: changeSocialIndicator,
    changeStakeholder: changeStakeholder,
    changeCustomStakeholder: changeCustomStakeholder,
    changeValue: changeValue,
    changeUnit: changeUnit,
    changeDescription: changeDescription,
    changeCustomSocialIndicator: changeCustomSocialIndicator,
    changeCustomSocialIndicatorId: changeCustomSocialIndicatorId
  };

  const closeAddSocialAspect = (): void => {
    setNewSocialAspects([]);
  };

  const showResultMessage = (
    status: "success" | "error",
    title: string,
    message: string
  ): void => {
    toast.closeAll();
    toast({
      position: "top",
      title: title,
      description: message,
      status: status,
      duration: 4000,
      isClosable: true,
    });
  };

  const isSocialAspectsValidated = () => {
    let validity = true;
    for (let socialAspect of newSocialAspects) {
      if (socialAspect.socialIndicatorId === 0) {
        if (!socialAspect.customSocialIndicator ||
          socialAspect.customSocialIndicator.trim() === '') {
          validity = false;
        }
      }
      if (socialAspect.socialTheme === 0) {
        if (!socialAspect.customSocialTheme ||
          socialAspect.customSocialTheme.trim() === '') {
          validity = false;
        }
      }
    }
    return validity;
  };

  const handleCustomThemesAndIndicators = async (item: NewSocialAspect) => {
    if (item.socialTheme === 0 && item.socialIndicatorId === 0) {
      const themeResult: SocialTheme =
        await createSocialThemes(item.customSocialTheme);
      const indicatorResult: KitSocialIndicator =
        await createSocialIndicators(
          item?.customSocialIndicator as string, themeResult.id
        );
      item.customSocialIndicatorId = indicatorResult.id;
    }
    if (item.socialTheme !== 0 && item.socialIndicatorId === 0) {
      const indicatorResult: KitSocialIndicator =
        await createSocialIndicators(
          item?.customSocialIndicator as string, item.socialTheme
        );
      item.customSocialIndicatorId = indicatorResult.id;
    }
  };

  const saveSocialAspects = async () => {
    if (!isSocialAspectsValidated()) {
      showResultMessage(
        "error",
        "Save social aspects",
        "Social themes and indicators cannot be blank!"
      );
      return;
    }
    const results = await Promise.allSettled(
      newSocialAspects.map(async (item) => {
        item.value = item.value ? item.value : 0;
        await handleCustomThemesAndIndicators(item);
        if (item.stakeholder === "other")
          item.stakeholder = item.customStakeholder ?
            item.customStakeholder : "";
        if (item.socialIndicatorId === 0)
          item.socialIndicatorId =
            item.customSocialIndicatorId as number;
        return createKitSocialAspectByLCSCategory(
          kit.id,
          currentLCS,
          item.stakeholder,
          item.socialIndicatorId,
          item.value,
          item.unit,
          item.description);
      }));
    if (results.length === results.filter((result) =>
      result.status === "fulfilled"
    ).length)
      showResultMessage(
        "success",
        "Save social aspects",
        "Social aspects saved succesfully"
      );
    else
      showResultMessage(
        "error",
        "Save social aspects",
        "Failed to save some social aspects"
      );
    closeAddSocialAspect();
    getAllSocialThemesAndIndicators(true);
    void getKitSocialAspects();
  };

  return (
    <HStack spacing="5%">
      <Flex alignSelf="flex-start" w="13%">
        <Stepper nonLinear activeStep={activeStep}
          orientation="vertical">
          {selectedStagesCategory.map((stage, index) => (
            <Step key={index}>
              <StepButton onClick={() => handleStep(stage, index)}>
                <StepLabel>{LCSName[stage]}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Flex>
      <Flex alignSelf="flex-start" w="87%">
        <VStack spacing="3" align="left" w="100%">
          <Button
            w="20%"
            leftIcon={<IoMdAddCircleOutline />}
            onClick={addSocialAspect}
            disabled={!isModificationEnabled}
          >
            Add a social aspect
          </Button>
          {(newSocialAspects && newSocialAspects.length > 0) &&
            newSocialAspects.map(
              (newSocialAspect: NewSocialAspect, index) => (
                <Box w="60%"
                  key={newSocialAspect.id}
                  borderRadius='lg'
                  overflow='hidden'>
                  <SocialAspect
                    newSocialAspect={newSocialAspect}
                    changeSocialAspect={changeSocialAspect}
                    allSocialThemes={allSocialThemes}
                    allSocialIndicators={allSocialIndicators}
                    filteredSocIndis={newSocialAspect.filteredSocIndis}
                    socialAspectIndex={index}
                    handleDelete={() => { removeNewSocialAspect(index) }}
                    isModificationEnabled={isModificationEnabled} />
                </Box>
              )
            )}
          <Button
            disabled={!touched}
            alignSelf="flex-start"
            mr={5}
            leftIcon={<CheckCircleIcon color={"green"} />}
            onClick={saveSocialAspects}
          >
            Save
          </Button>
          {(socialAspects && socialAspects.length > 0) &&
            socialAspects.map((item: KitSocialAspect, index) => (
              <Box w="60%"
                key={index}
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                p="5">
                <Box p="1">
                  <Text as="b">Social indicator</Text>
                  <Text>{item?.socialIndicator?.indicator}</Text>
                </Box>
                <Divider />
                <Box p="1">
                  <Text as="b">Description</Text>
                  <Text>{item?.description}</Text>
                </Box>
                <Divider />
                <Box p="1">
                  <Text as="b">Stakeholder</Text>
                  <Text>{item?.stakeholder}</Text>
                </Box>
                <Divider />
                <Box p="1">
                  <HStack spacing="5">
                    <VStack align="left">
                      <Text as="b">Value</Text>
                      <Text height={6}>{item?.value}</Text>
                    </VStack>
                    <VStack align="left">
                      <Text as="b">Unit</Text>
                      <Text height={6}>{item?.unit}</Text>
                    </VStack>
                  </HStack>
                </Box>
                <Box p="1">
                  <IconButton
                    mr={2}
                    colorScheme="red"
                    size="xs"
                    aria-label="Remove social aspect"
                    icon={<Delete />}
                    onClick={
                      () => removeSocialAspect(item.id)
                    }
                  />
                </Box>
              </Box>
            ))
          }
        </VStack>
      </Flex>
    </HStack>
  );
};

export interface ChangeSocialAspect {
  setSocialTheme: (themeId: number, index: number) => void,
  setCustomSocialTheme: (theme: string, index: number) => void,
  setCustomSocialThemeId: (themeId: number, index: number) => void,
  setFilteredSocIndis: (
    filteredSocIndis: KitSocialIndicator[],
    index: number
  ) => void,
  changeSocialIndicator: (indicator: number, index: number) => void,
  changeCustomSocialIndicator: (indicator: string, index: number) => void,
  changeCustomSocialIndicatorId: (indicator: number, index: number) => void,
  changeStakeholder: (stakeholder: SocialStakeHolder, index: number) => void,
  changeCustomStakeholder: (
    stakeholder: SocialStakeHolder,
    index: number
  ) => void,
  changeValue: (value: number, index: number) => void,
  changeUnit: (unit: string, index: number) => void,
  changeDescription: (description: string, index: number) => void
}

export default KitSocialAspectTab;
