import { useToast } from "@chakra-ui/react"
import { GridSelectionModel } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import {
  getFlowProperty,
  getProcess,
  getUnitGroup,
  updateProcess
} from "../api/olca.service"
import { isInfiniteCustomBuilding } from "../services/buildings.service"
import { useGlobalState } from "../store/globalState"
import {
  Exchange,
  InfiniteKit,
  InfiniteLifeCycleStage,
  LifeCycleStageCategory,
  Process,
  ProcessUpdate,
  Ref,
  Unit
} from "../store/schema"
import { dummyProcess, dummyRef, InfiniteEntity } from "../store/types"

export type useHandleLifeCycleStagesProps = {
  activeStepAndStage: [
    number,
    (stage: LifeCycleStageCategory, step: number) => void
  ]
  currentProcess: [
    Process,
    (p: Process) => void,
    (e: Exchange) => void,
    (exchanges: Exchange[]) => void
  ]
  selectedStagesIndex: [GridSelectionModel, (i: GridSelectionModel) => void]
  selectedStagesCategory: [
    LifeCycleStageCategory[],
    (c: LifeCycleStageCategory[]) => void
  ]
  lifeTimeAmount: [number, (a: number) => void]
  lifeTimeUnit: [Ref, (u: string) => void]
  lifeTimeUnits: Unit[]
  maintenanceTimeAmount: [number, (a: number) => void]
  maintenanceTimeUnit: [Ref, (u: string) => void]
  maintenanceTimeUnits: Unit[]
  description: [string, (d: string) => void]
  currentLCS: LifeCycleStageCategory
  deleteComponent: (c: number[]) => void
  onSave: () => void
  touched: boolean
  addKitsToBuilding: (kitIds: number[]) => void
}
type State = {
  activeStep: number
  currentProcess: Process
  selectedStages: LifeCycleStageCategory[]
  selectedStagesIndex: GridSelectionModel
  processes: Process[]
  updatedProcesses: Process[]
  touched: boolean
  lifeTimeAmount: number
  lifeTimeUnit: Ref
  lifeTimeUnits: Unit[]
  maintenanceTimeAmount: number
  maintenanceTimeUnit: Ref
  maintenanceTimeUnits: Unit[]
  currentLCS: LifeCycleStageCategory
}
export const useHandleInfiniteEntityLifeCycleStages = (
  infiniteEntity: InfiniteEntity
): useHandleLifeCycleStagesProps => {
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const { units, kits } = globalState
  const [state, setState] = useState<State>({
    lifeTimeAmount: 0,
    lifeTimeUnit: dummyRef,
    lifeTimeUnits: [],
    maintenanceTimeAmount: 0,
    maintenanceTimeUnit: dummyRef,
    maintenanceTimeUnits: [],
    activeStep: 0,
    currentProcess: dummyProcess,
    selectedStagesIndex: [0, 1, 2, 3, 4, 5, 6],
    selectedStages: [],
    processes: [],
    updatedProcesses: [],
    touched: false,
    currentLCS: LifeCycleStageCategory.MANUFACTURING,
  })
  const {
    activeStep,
    currentProcess,
    selectedStages,
    selectedStagesIndex,
    touched,
    processes,
    updatedProcesses,
    lifeTimeUnits,
    lifeTimeAmount,
    lifeTimeUnit,
    maintenanceTimeAmount,
    maintenanceTimeUnit,
    maintenanceTimeUnits,
    currentLCS,
  } = state
  const toast = useToast()

  const fetchUnits = async (flowPropertyId: string) => {
    try {
      const flowPropertyTMP = await getFlowProperty(flowPropertyId)
      const unitGroup = await getUnitGroup(
        flowPropertyTMP.unitGroup?.["@id"] as string
      )
      return unitGroup.units ? unitGroup.units : []
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedStages.length)
      void handleStepAndStage(selectedStages[0], 0)
  }, [selectedStages])

  const setInitialState = async () => {
    let stagesCategory: LifeCycleStageCategory[] = []
    let processPromises: Promise<Process>[] = []
    if (isInfiniteCustomBuilding(infiniteEntity)) {
      stagesCategory = infiniteEntity.lifeCycleStages.map(
        (stage: InfiniteLifeCycleStage) => stage.category
      )
      processPromises = infiniteEntity.lifeCycleStages.map(
        (lcs: InfiniteLifeCycleStage) => getProcess(lcs.processRefId)
      )
    } else {
      stagesCategory = infiniteEntity.lifeCycleStages.map(
        (stage: InfiniteLifeCycleStage) => stage.category
      )
      processPromises = infiniteEntity.lifeCycleStages.map(
        (lcs: InfiniteLifeCycleStage) => getProcess(lcs.processRefId)
      )
    }
    const processes = await Promise.all(processPromises)
    const useProcess = processes[4]
    let qRefUse
    let qRefMaintenance
    if (useProcess) {
      qRefUse = useProcess.exchanges?.find(
        (e) => e.isQuantitativeReference
      ) as Exchange
    }
    const maintenanceProcess = processes[5]
    if (maintenanceProcess) {
      qRefMaintenance = maintenanceProcess.exchanges?.find(
        (e) => e.isQuantitativeReference
      ) as Exchange
    }
    let useUnits: Unit[] = []
    let maintenanceUnits: Unit[] = []
    // const timeUnits =
    //     (await getUnitGroup("af638906-3ec7-4314-8de7-f76039f2dd01"))
    //         .units || []
    // const defaultTimeUnit = await getUnitDescriptor(
    //     "11074cfd-08a4-449b-adad-18ce24a1b275"
    // )
    // FIXME : switch to unit from the flowProperty instead of hardoded units
    if (qRefUse) {
      useUnits = (await fetchUnits(
        qRefUse.flowProperty?.["@id"] as string
      )) as Unit[]
    }
    if (qRefMaintenance) {
      maintenanceUnits = (await fetchUnits(
        qRefMaintenance.flowProperty?.["@id"] as string
      )) as Unit[]
    }
    setState({
      ...state,
      selectedStages: stagesCategory,
      processes,
      lifeTimeAmount: qRefUse?.amount as number,
      lifeTimeUnit: qRefUse?.unit as Ref,
      lifeTimeUnits: useUnits,
      maintenanceTimeAmount: qRefMaintenance?.amount as number,
      maintenanceTimeUnit: qRefMaintenance?.unit as Ref,
      maintenanceTimeUnits: maintenanceUnits,
    })
  }

  useEffect(() => {
    if (infiniteEntity.id !== -1) {
      void setInitialState()
    }
  }, [infiniteEntity])

  useEffect(() => {
    const stagesCategory: LifeCycleStageCategory[] =
      infiniteEntity.lifeCycleStages
        .filter((stage, idx) => selectedStagesIndex.includes(idx))
        .map((stage: InfiniteLifeCycleStage) => stage.category)
    setState({
      ...state,
      activeStep: 0,
      selectedStages: stagesCategory,
    })
  }, [selectedStagesIndex])

  const addToUpdate = (process: Process): Process[] => {
    const updatedProcesses = [...state.updatedProcesses]
    const index = updatedProcesses.findIndex(
      (p) => p["@id"] === process["@id"]
    )
    if (index === -1) {
      updatedProcesses.push(process)
    } else {
      updatedProcesses[index] = process
    }
    return updatedProcesses
  }

  const handleStepAndStage = (
    stageCategory: LifeCycleStageCategory,
    step: number
  ) => {
    setState({
      ...state,
      activeStep: step,
      currentProcess: state.processes[step],
      currentLCS: stageCategory,
    })
  }

  const handleModifyExchange = (exchange: Exchange) => {
    if (currentProcess.exchanges) {
      const idx = currentProcess.exchanges?.indexOf(exchange)
      currentProcess.exchanges[idx] = exchange
      setState({
        ...state,
        currentProcess: { ...currentProcess },
        updatedProcesses: addToUpdate(currentProcess),
        touched: true,
      })
    }
  }

  const handleAddExchanges = (exchanges: Exchange[]) => {
    const updatedExchangeProcess = {
      ...currentProcess,
      exchanges: [
        ...currentProcess.exchanges as Exchange[],
        ...exchanges
      ]
    };
    setState({
      ...state,
      currentProcess: updatedExchangeProcess,
      updatedProcesses: addToUpdate(currentProcess),
      touched: true
    })
  }

  const handleLifeTimeAmount = (a: number) => {
    const useProcess = processes[4]
    const qRef = useProcess.exchanges?.find(
      (e) => e.isQuantitativeReference
    )
    if (qRef) qRef.amount = a
    setState({ ...state, lifeTimeAmount: a, touched: true })
  }
  const handleLifeTimeUnit = (unitName: string) => {
    const unit = lifeTimeUnits.find((u) => u.name === unitName) as Unit
    const unitRef = units.find((u) => u["@id"] === unit["@id"]) as Ref
    const useProcess = processes[4]
    const qRef = useProcess.exchanges?.find(
      (e) => e.isQuantitativeReference
    )
    if (qRef) qRef.unit = unitRef
    setState({ ...state, lifeTimeUnit: unitRef, touched: true })
  }
  const handleMaintenanceTimeAmount = (a: number) => {
    const maintenanceProcess = processes[5]
    const qRef = maintenanceProcess.exchanges?.find(
      (e) => e.isQuantitativeReference
    )
    if (qRef) qRef.amount = a
    setState({ ...state, maintenanceTimeAmount: a, touched: true })
  }
  const handlemaintenanceTimeUnit = (unitName: string) => {
    const unit = maintenanceTimeUnits.find(
      (u) => u.name === unitName
    ) as Unit
    const unitRef = units.find((u) => u["@id"] === unit["@id"]) as Ref
    const maintenanceProcess = processes[4]
    const qRef = maintenanceProcess.exchanges?.find(
      (e) => e.isQuantitativeReference
    )
    if (qRef) qRef.unit = unitRef
    setState({ ...state, maintenanceTimeUnit: unitRef, touched: true })
  }

  const handleDelete = (componentIds: number[]) => {
    currentProcess.exchanges = currentProcess.exchanges?.filter(
      (e) => !componentIds.includes(e.internalId as number)
    )
    setState({
      ...state,
      currentProcess: { ...currentProcess },
      updatedProcesses: addToUpdate(currentProcess),
      touched: true,
    })
  }

  const handleOnSave = async () => {
    const processPromises = state.updatedProcesses.map((p) => {
      const processUpdate: ProcessUpdate = p
      return updateProcess(processUpdate)
    })
    await Promise.all(processPromises)
    setState({ ...state, touched: false })
    setGlobalState({
      ...globalState,
      selectedStageIndex: selectedStagesIndex,
    })

    toast.closeAll()
    toast({
      position: "top",
      title: "Modification",
      description: "Entity modified succesfully",
      status: "success",
      duration: 4000,
      isClosable: true,
    })
  }

  const handleDescription = (description: string) => {
    currentProcess.description = description
    setState({
      ...state,
      currentProcess,
      updatedProcesses: addToUpdate(currentProcess),
      touched: true,
    })
  }

  const addKitsToBuilding = async (kitIds: number[]) => {
    const lcsLength = Object.keys(LifeCycleStageCategory).length
    const processPromises: Promise<Process>[][] = [
      ...(Array(lcsLength) as Promise<Process>[]),
    ].map((e) => [])
    const processMap: Map<string, InfiniteKit> = new Map()
    kitIds.forEach((kitId) => {
      const kit = kits.find((k) => k.id === kitId)
      if (kit) kits.push(kit)
      return kit?.lifeCycleStages.map((stage, idx) => {
        const process = stage.processRefId
        if (kit) {
          processMap.set(process, kit)
          processPromises[idx].push(getProcess(process))
        }
      })
    })

    const lcs = await Promise.all(
      processPromises.map(async (p) => await Promise.all(p))
    )
    const processesCopy = [...state.processes]
    lcs.forEach((stage, idx) => {
      processesCopy[idx].exchanges?.push(
        ...(stage.flatMap((s) => {
          s.exchanges?.forEach((e) => {
            const kit = processMap.get(s["@id"]) as InfiniteKit
            if (e.flow?.name) {
              e.flow.name =
                (kit.name || "") + " - " + (e.flow?.name || "")
            }
          })
          return s.exchanges
        }) as Exchange[])
      )
    })
    setState({ ...state, processes: processesCopy })
  }

  return {
    activeStepAndStage: [activeStep, handleStepAndStage],
    currentProcess: [
      currentProcess,
      (process) => setState({ ...state, currentProcess: process }),
      (exchange) => handleModifyExchange(exchange),
      (exchanges) => handleAddExchanges(exchanges),
    ],
    selectedStagesIndex: [
      selectedStagesIndex,
      (index) => setState({ ...state, selectedStagesIndex: index }),
    ],
    selectedStagesCategory: [
      selectedStages,
      (categories) => setState({ ...state, selectedStages: categories }),
    ],
    lifeTimeAmount: [lifeTimeAmount, (a) => handleLifeTimeAmount(a)],
    lifeTimeUnit: [lifeTimeUnit, (u) => handleLifeTimeUnit(u)],
    lifeTimeUnits,
    maintenanceTimeAmount: [
      maintenanceTimeAmount,
      handleMaintenanceTimeAmount,
    ],
    maintenanceTimeUnit: [maintenanceTimeUnit, handlemaintenanceTimeUnit],
    maintenanceTimeUnits,
    description: [currentProcess.description as string, handleDescription],
    currentLCS,
    deleteComponent: handleDelete,
    onSave: handleOnSave,
    touched: touched,
    addKitsToBuilding,
  }
}
