import axios from "axios";
import {
  KitSocialIndicator
} from "../store/schema";
import configData from "./config.json";

const socialIndicatorService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/social-indicators",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getSocialIndicators = async (
  skip?: number,
  limit?: number
): Promise<KitSocialIndicator[]> => {
  const response = await socialIndicatorService.get<KitSocialIndicator[]>(
    `/?skip=${skip || 0}&limit=${limit || 100}`
  );
  return response.data;
};

export const createSocialIndicators = async (
  indicator: string,
  socialThemeId: number
): Promise<KitSocialIndicator> => {
  const response = await socialIndicatorService.post<KitSocialIndicator>("/", {
    indicator,
    socialThemeId
  });
  return response.data;
};
