/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from "axios"
import moment from "moment"
import {
  CalculationState,
  Entity,
  InfiniteBuilding,
  InfiniteCustomEntity, InfiniteEntityType,
  InfiniteKit
} from "../store/schema"
import { InfiniteEntity, Role } from "../store/types"

export const isEmail = (email: string | undefined): boolean => {
  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email && regEmail.test(email)
}

export const createDate = (dateString: string): Date =>
  moment(dateString, "DD/MM/YYYY").toDate()

export const formatDate = (date: Date | undefined): string =>
  date ? new Date(date).toLocaleDateString() : ""

export const roleToString = (role: Role): string => {
  switch (role) {
    case Role.INPUT:
      return "Input"
    case Role.OUTPUT:
      return "Output"
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getErrorMessage = (error: any): string => {
  let returnedErrorMessage = "Unknown error"
  if (axios.isAxiosError(error)) {
    returnedErrorMessage = error.response?.data?.detail as string
    if (!returnedErrorMessage) {
      returnedErrorMessage = error.message
    }
  }
  return returnedErrorMessage
}

export const arrayEquals = (tab1: any[], tab2: any[]): boolean => {
  if ((!tab1 && tab2) || (tab1 && !tab2)) return false
  if (!tab1 && !tab2) return true
  // compare lengths - can save a lot of time
  if (tab1.length != tab2.length) return false

  for (let i = 0, l = tab1.length; i < l; i++) {
    // Check if we have nested arrays
    if (tab1[i] instanceof Array && tab2[i] instanceof Array) {
      // recurse into the nested arrays
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (!tab1[i].equals(tab2[i])) return false
    } else if (tab1[i] != tab2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

/**
 * Perform a deep copy, so the initial object and the copy object are totaly disconnected
 * @param object The object to copy
 * @returns A deep copy of the parameter
 */
export const deepCopy = <T,>(object: T): T =>
  JSON.parse(JSON.stringify(object)) as T

export const isCalculationReady = (
  calculation: CalculationState
): boolean => calculation.isReady

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms))

//@ts-ignore
const numberFormat = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 3, notation: "standard" })
//@ts-ignore
const scientificFormat = new Intl.NumberFormat('en-EN', { maximumSignificantDigits: 3, notation: "scientific" })
// If the length of num is strictly more than digits, or num is smaller than 0.001
// it is then formated to exponential notation, otherwise it is converted to a string
export const formatNumber = (num: number, digits = 10000): string => {
  const length = Math.floor(num).toString().length;
  if (length > digits || num < 0.001) {
    return scientificFormat.format(num).toString();
  }
  return numberFormat.format(num).toString();
}

export const isInfiniteCustomEntity = (
  entity: InfiniteEntity
): entity is InfiniteCustomEntity => {
  return (
    entity.type === InfiniteEntityType.CUSTOM
  )
}

export const isInfiniteKit = (
  entity: InfiniteEntity
): entity is InfiniteKit => {
  return (entity as InfiniteKit).manufacturer !== undefined
}

export const isInfiniteBuilding = (
  entity: InfiniteEntity
): entity is InfiniteBuilding => {
  return !isInfiniteKit(entity)
}

export const formatInfiniteEntityRefAmount = (
  infiniteEntity: InfiniteEntity
): string => {
  let ref = infiniteEntity.referenceAmount.toString()
  if (infiniteEntity.unitRef && infiniteEntity.unitRef.name) {
    ref += " " + infiniteEntity.unitRef.name
  }
  return ref
}

export const formatInfiniteEntityName = (
  infiniteEntity: InfiniteEntity,
  idx: number
): string => {
  const entityName = isInfiniteKit(infiniteEntity) ? "Kit" : "Building"
  const index = (idx + 1).toString()
  return entityName + " " + index
}

export const unique = <T extends Entity,>(elem: T, index: number, array: T[]): boolean => {
  for (let i = 0; i < index; i++) {
    if (array[i]["@id"] === elem["@id"]) return false;
  }
  return true;
};
