import {
  Button,
  Flex,
  HStack,
  Spacer,
  useDisclosure,
  VStack
} from "@chakra-ui/react"
import { Grid } from "@material-ui/core"
import React, { FC, useState } from "react"
import { IoMdArrowDroprightCircle } from "react-icons/io"
import useSearchBar from "../../hook/SearchBar"
import { Calculation } from "../Modal/Calculation"
import SearchBar from "../SearchBar"
import DefaultKitLibrary from "./DefaultKitLibrary"
import UserKitLibrary from "./UserKitLibrary"

const KitLibraries: FC = () => {
  const [search, setSearch] = useSearchBar()

  const [userSelectedKitIds, setUserKitIds] = useState<number[]>([])
  const [infiniteSelectedKitIds, setInfiniteKitIds] = useState<number[]>([])
  const {
    isOpen: isCalculationOpen,
    onOpen: onCalculationOpen,
    onClose: onCalculationClose,
  } = useDisclosure()

  return (
    <Flex m={5}>
      <VStack w="100%">
        <HStack alignSelf="end">
          <Spacer />
          <SearchBar search={search} setSearch={setSearch} />
        </HStack>

        <HStack w="100%" mb={6}>
          <VStack w="100%">
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6}>
                <DefaultKitLibrary
                  searchString={search}
                  setKitIds={setInfiniteKitIds}
                  onCalculationOpen={onCalculationOpen}
                />
              </Grid>
              <Grid item xs={12} xl={6} >
                <UserKitLibrary
                  searchString={search}
                  setKitIds={setUserKitIds}
                  onCalculationOpen={onCalculationOpen}
                />
              </Grid>
            </Grid>

            <Flex mt={5} alignSelf="end">
              <HStack w="100%">
                <Button
                  isDisabled={
                    userSelectedKitIds.length +
                    infiniteSelectedKitIds.length ===
                    0
                  }
                  onClick={onCalculationOpen}
                  leftIcon={
                    <IoMdArrowDroprightCircle
                      color="green"
                      size={20}
                      style={{ marginTop: 2 }}
                    />
                  }
                >
                  {userSelectedKitIds.length +
                    infiniteSelectedKitIds.length >
                    1
                    ? "Run Kits LCA comparison"
                    : "Run Kit LCA"}
                </Button>
              </HStack>
            </Flex>
          </VStack>
          <Calculation
            isOpen={isCalculationOpen}
            onClose={onCalculationClose}
            infiniteEntitiesIds={infiniteSelectedKitIds.concat(
              userSelectedKitIds
            )}
          />
        </HStack>
      </VStack>
    </Flex>
  )
}

export default KitLibraries
