import React, { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { useGlobalState } from "../store/globalState";

const PrivateRoute: React.FC<{
  children: Element | ReactNode;
  path: string;
  exact?: boolean;
}> = (props) => {
  const [{ isAuthenticated }] = useGlobalState("authState")
  return isAuthenticated ?
    (<Route path={props.path} exact={props.exact} >{props.children} </Route>) :
    (<Redirect to="/" />);
};
export default PrivateRoute;
