import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { FC } from 'react';

const DeleteComponent: FC<{
  isOpen: boolean,
  onClose: () => void,
  handleDelete: () => void
}> = ({ isOpen, onClose, handleDelete }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Component</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            Are you sure to delete the selected Components ?
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            variant="outline"
            onClick={() => { handleDelete(); onClose(); }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteComponent
