/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/display-name */
import { Box, Flex, HStack, Spacer } from "@chakra-ui/react"
import { Tab, Tabs, TextField } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  useHandleInfiniteEntityLifeCycleStages
} from "../../hook/handleInfiniteEntityLifeCycleStages"
import useHandleModifyKit from "../../hook/handleInfiniteEntityModification"
import { useGlobalState } from "../../store/globalState"
import {
  InfiniteKit
} from "../../store/schema"
import {
  anonymousCustomKit,
  anonymousKit,
  InfiniteEntityAction
} from "../../store/types"
import ChipSelect from "../StatusChipMenu/ChipSelect"
import TechnicalSpecifications from "../TechSpec/TechnicalSpecifications"
import FileUpload from "../Upload/FileUpload"
import KitDetails from "./KitDetails"
import { KitLifeCycle } from "./KitLifeCycle"
import KitSocialAspectTab from "./KitSocialAspectTab"

const KitPage: FC = () => {
  const history = useHistory()
  const { kitId } = useParams<{ kitId: string }>()
  const [globalState] = useGlobalState("globalState")
  const { kits, user } = globalState
  const [kit, setKit] = useState<InfiniteKit>(anonymousKit)
  const handleModifyKit = useHandleModifyKit(kit, setKit)
  const handleLifeCycleStages = useHandleInfiniteEntityLifeCycleStages(kit)
  const isModificationEnabled = handleModifyKit.isModificationEnabled
  const kitDetailsTouched = handleModifyKit.touched[0]
  const LCSTouched = handleLifeCycleStages.touched
  const [name, setName] = handleModifyKit.name
  const [isCreatingKit, setIsCreatingKit] = useState(false)

  useEffect(() => {
    if (kitId === "create") {
      setIsCreatingKit(true)
      const customKit = anonymousCustomKit
      setKit(customKit)
      return
    }

    if (!kits || (kit && kit.id !== -1)) return
    let findKit = kits?.find(
      (kit) => kit.id === parseInt(kitId)
    ) as InfiniteKit
    if (!findKit) {
      findKit = user.kits?.find(
        (kit) => kit.id === parseInt(kitId)
      ) as InfiniteKit
    }
    if (findKit) setKit(findKit)
  }, [kits, user.kits])

  const onSave = () => {
    if (handleLifeCycleStages.touched)
      handleLifeCycleStages.onSave()
    if (handleModifyKit.touched[0])
      handleModifyKit.onSave()
  }

  const onCreate = async () => {
    const id = await handleModifyKit.onCreate()
    history.push(`/kits/${id}`)
  }
  const action: InfiniteEntityAction = {
    actionLabel: isCreatingKit ? "Create Kit" : "Save modification",
    action: isCreatingKit ? onCreate : onSave
  }

  const [value, setTabIndex] = useState(0)

  const handleChange = (newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Box m={5} h="100vh" w="96vw">
      <HStack>
        <Box
          as="form"
          sx={{
            "& .MuiTextField-root": { width: "75ch" },
          }}
          noValidate
          autoComplete="off"
          mb={4}
        >
          <TextField
            id="standard-basic"
            label="Kit name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            size="medium"
            inputProps={{ style: { fontSize: 40 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
            disabled={!isModificationEnabled}
          />
        </Box>
        <Spacer />
        <ChipSelect
          defaultStatus={handleModifyKit.status[0]}
          handleStatus={handleModifyKit.status[1]}
          isModificationEnabled={isModificationEnabled}
        />
      </HStack>
      <Box sx={{ borderBottom: 1, borderColor: "" }}>
        <Tabs
          value={value}
          onChange={(e, v) => handleChange(v)}
          aria-label="basic tabs example"
        >
          <Tab label="Kit details" {...a11yProps(0)} />
          <Tab label="Kit life cycle" {...a11yProps(1)} />
          <Tab label="Technical specification" {...a11yProps(2)} />
          <Tab label="Document" {...a11yProps(3)} />
          <Tab label="Social aspects" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {kit.id !== -1 && (
          <KitDetails
            handleModifyKit={handleModifyKit}
            handleLifeCycleStages={handleLifeCycleStages}
            action={action}
            touched={kitDetailsTouched || (LCSTouched && false) && false}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {kit.id !== -1 && (
          <>
            <Flex h="50vh" justify="center">
              <Box w="98vw" h="100%">
                <KitLifeCycle
                  handleModifyKit={handleModifyKit}
                  handleLifeCycleStages={
                    handleLifeCycleStages
                  }
                  onSave={onSave}
                  touched={kitDetailsTouched || LCSTouched}
                />
              </Box>
            </Flex>
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TechnicalSpecifications
          entity={kit}
          setKit={setKit}
          handleModifyEntity={handleModifyKit}
          onSave={onSave}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FileUpload
          entity={kit}
          onSave={onSave}
          handleModifyEntity={handleModifyKit}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <KitSocialAspectTab
          handleModifyKit={handleModifyKit}
          handleLifeCycleStages={handleLifeCycleStages}
        />
      </TabPanel>
    </Box>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default KitPage
