import Chip from "@material-ui/core/Chip"
import { green, grey, orange } from "@material-ui/core/colors"
import React, { FC } from "react"
import { InfiniteEntityStatus } from "../../store/schema"
import { EntityStatus } from "../../store/types"

const colorForStatus = (status: InfiniteEntityStatus) => {
  switch (status) {
    case InfiniteEntityStatus.FINAL:
      return green
    case InfiniteEntityStatus.WORK_IN_PROGRESS:
      return orange
    default:
      return grey
  }
}

const StatusChip: FC<{ status: InfiniteEntityStatus }> = ({ status }) => {
  return (
    <Chip
      label={EntityStatus[status]}
      style={{
        backgroundColor: colorForStatus(status)[600],
        color: "white",
      }}
    />
  )
}

export default StatusChip
