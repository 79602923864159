/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useHistory } from "react-router-dom";
import { logout, signin as login } from "../api/authentication.service";
import { getCurrentUser } from "../api/user.service";
import { LOCAL_STORAGE_AUTHSTATE_KEY } from "../constants";
import { useGlobalState } from "../store/globalState";
import { InfiniteUser } from "../store/schema";
import { AuthState, Token } from "../store/types";
import { getErrorMessage } from "../utils/utils";


type UseBackendType = {
  (): {
    signin: (
      email: string,
      password: string,
      saveState: boolean
    ) => Promise<InfiniteUser>,
    signout: () => void,
    authenticate: () => Promise<InfiniteUser>,
  }
}

export const useAuthentication: UseBackendType = () => {
  const [authState, setAuthState] = useGlobalState("authState")
  const [globalState, setGlobalState] = useGlobalState("globalState")
  const history = useHistory()
  const backHome = () => history.push('/')

  /**
   * Send the email and password to the server, in order to
   * authenticate the user
   * Set the state with the JWT token sent back by the server
   */
  const signin = async (
    email: string,
    password: string,
    saveState: boolean
  ) => {
    let token: Token
    let user: InfiniteUser
    try {
      token = await login(email, password)
      user = await getCurrentUser()
      setAuthState({ ...authState, isAuthenticated: true, token })
      setGlobalState({ ...globalState, user })
      if (!saveState)
        localStorage.setItem(LOCAL_STORAGE_AUTHSTATE_KEY, "")
      return user
    } catch (error) {
      setAuthState({ isAuthenticated: false, error: true })
      throw getErrorMessage(error)
    }
  }

  /**
   * Authentification function, that send to the server the stored token,
   * so it can be verified by the server.
   * This action will be performed at any refresh or
   * new connection to the website.
   * Set the state with the JWT token sent back by the server
   */
  const authenticate = async () => {
    try {
      const user = await getCurrentUser()
      setAuthState({ ...authState, isAuthenticated: true })
      setGlobalState({ ...globalState, user })
      return user
    } catch (error) {
      setAuthState({ isAuthenticated: false, error: true })
      throw getErrorMessage(error)
    }
  }

  /**
   * Delete the session's state from local storage
   */
  const signout = (
    backHome: () => void,
    setAuthState: (state: AuthState) => void
  ) => {
    setAuthState({ isAuthenticated: false })
    logout()
    backHome()
  }
  return {
    signin: (email, password, saveState) => signin(email, password, saveState),
    signout: () => signout(backHome, setAuthState),
    authenticate: () => authenticate(),
  }
}
