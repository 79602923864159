import { BIMP_AUTH_TOKEN } from "../../components/Modal/CreateBIMPResource";
import { BIMPUserProject, BIMPUserRole } from "../../store/schema";
import configData from "../config.json";
import axios, { AxiosResponse } from "axios";

const userService = axios.create({
  baseURL: configData.BIMP_API_URL + "/users",
  headers: {
    "accept": "application/json",
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${sessionStorage.getItem(BIMP_AUTH_TOKEN) || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getBIMPUserToken = async (username: string, password: string): Promise<AxiosResponse<string>> => {
  const response = await userService.get<string>(
    "/users-token", {
      params: {
        username,
        password
      }
    }
  );
  return response;
};

export const getBIMPUserProjects = async (tokenUser: string): Promise<BIMPUserProject[]> => {
  const response = await userService.get<string>(
    "/user-projects", {
      params: {
        tokenUser
      }
    }
  );
  return JSON.parse(response.data);
};

export const getBIMPUserRoles = async (tokenUser: string, tokenProject: string): Promise<BIMPUserRole[]> => {
  const response = await userService.get<string>(
    "/user-roles",{
      params: {
        tokenUser,
        tokenProject
      }
    }
  );
  return JSON.parse(response.data);
};
