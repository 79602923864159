import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react"
import { makeStyles } from "@material-ui/styles"
import React, { FC, useState } from "react"
import GreenDeltaGreen from "../assets/gdlogogreen.png"
import GreenDeltaGrey from "../assets/gdlogogrey.png"
import Logo from "../assets/InfiniteLogoWithoutText.png"
import { useGlobalState } from "../store/globalState"

const Home: FC = () => {
  const [authState] = useGlobalState("authState")
  const [globalState] = useGlobalState("globalState")
  const { user } = globalState
  const msg = authState.isAuthenticated ? (
    <p>Authenticated</p>
  ) : (
    <p>You have to login</p>
  )

  return (
    // Center a div horizontally and vertically
    <Flex
      justifyContent="center"
      alignItems="center"
      height="90vh"
      width="100vw"
      verticalAlign={"bottom"}
    >
      <VStack>
        <Image src={Logo} height={"100"} />
        <Heading size="xl">WELCOME TO THE INFINITE LCA TOOL</Heading>
        <Text fontSize={"xl"} color="grey" fontWeight={"bold"}>
          Life Cycle Assesment For Building Renovation
        </Text>
        {!authState.isAuthenticated && (
          <Text pt={10}>
            Please login in order to use this plugin
          </Text>
        )}

        <Spacer />
        <Footer />
      </VStack>
    </Flex>
  )
}

export default Home

const Footer: FC = () => {
  const useStyles = makeStyles(() => ({
    root: {
      position: "fixed",
      bottom: 20,
    },
  }))
  const classes = useStyles()
  const [onHover, setOnHover] = useState(false)
  return (
    <HStack className={classes.root}>
      <Heading size="sm">Developed by</Heading>
      <a href="https://www.greendelta.com/">
        <Box
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <Image
            src={onHover ? GreenDeltaGreen : GreenDeltaGrey}
            height="4"
          />
        </Box>
      </a>
    </HStack>
  )
}
