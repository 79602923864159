/* eslint-disable indent */
import axios from "axios";
import {
  CalculationSetup,
  CalculationState,
  InfiniteEntityCalculationStates,
  InfiniteEntityStatus,
  InfiniteEntityType,
  InfiniteKit,
  InfiniteKitUpdate,
  InfiniteLifeCycleStage,
  LifeCycleStageCategory,
  TechnicalSpecs,
  KitSocialAspect,
  SocialAspectResp,
  SocialStakeHolder
} from "../store/schema";
import configData from "./config.json";

const kitService = axios.create({
  baseURL: configData.INFINITE_API_URL + "/kits",
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("authToken") || ""}`;
      },
    },
  },
  responseType: "json",
});

export const getDefaultKits = async (
  skip?: number,
  limit?: number
): Promise<InfiniteKit[]> => {
  const response = await kitService.get<InfiniteKit[]>(
    `/?skip=${skip || 0}&limit=${limit || 100
    }&type=${InfiniteEntityType.DEFAULT.toLowerCase()}`
  );
  return response.data;
};

export const getKit = async (id: number): Promise<InfiniteKit> => {
  const response = await kitService.get<InfiniteKit>(`/${id}`);
  return response.data;
};

export const getCustomKits = async (
  skip?: number,
  limit?: number
): Promise<InfiniteKit[]> => {
  const response = await kitService.get<InfiniteKit[]>(
    `?skip=${skip || 0}&limit=${limit || 100
    }&type=${InfiniteEntityType.CUSTOM.toLowerCase()}`
  );
  return response.data;
};

export const createKit = async (
  name: string,
  description: string | undefined,
  manufacturer: string | undefined,
  referenceAmount: number,
  unitRefId: string,
  flowPropertyRefId: string,
  lifeCycleStages: InfiniteLifeCycleStage[],
  status: InfiniteEntityStatus
): Promise<InfiniteKit> => {
  const response = await kitService.post<InfiniteKit>("/", {
    name,
    description,
    manufacturer,
    referenceAmount,
    unitRefId,
    flowPropertyRefId,
    status,
    lifeCycleStages,
    type: InfiniteEntityType.CUSTOM,
  });
  return response.data;
};

export const updateKit = async (
  id: number,
  kitUpdate: InfiniteKitUpdate
): Promise<InfiniteKit> => {
  const response = await kitService.put<InfiniteKit>(`/${id}`, kitUpdate);
  return response.data;
};

export const deleteKit = async (id: number): Promise<void> => {
  await kitService.delete<InfiniteKit>(`/${id}`);
};

export const getLCSs = async (
  id: number
): Promise<InfiniteLifeCycleStage[]> => {
  const response = await kitService.get<InfiniteLifeCycleStage[]>(
    `/${id}/life-cycle-stages`
  );
  return response.data;
};

export const createLCS = async (
  kitId: number,
  name: string,
  category: LifeCycleStageCategory,
  processRefId: string
): Promise<InfiniteKit> => {
  const response = await kitService.post<InfiniteKit>(
    `/${kitId}/life-cycle-stages`,
    {
      name,
      category,
      processRefId,
    }
  );
  return response.data;
};

export const getLCS = async (
  id: number,
  category: LifeCycleStageCategory
): Promise<InfiniteLifeCycleStage> => {
  const response = await kitService.get<InfiniteLifeCycleStage>(
    `/${id}/life-cycle-stages/${category}`
  );
  return response.data;
};

export const deleteLCS = async (
  id: number,
  category: LifeCycleStageCategory
): Promise<void> => {
  await kitService.delete(`/${id}/life-cycle-stages/${category}`);
};

export const getTechSpecs = async (id: number): Promise<TechnicalSpecs[]> => {
  const response = await kitService.get<TechnicalSpecs[]>(
    `/${id}/technical-specs`
  );
  return response.data;
};

export const createTechSpec = async (
  id: number,
  name: string,
  description?: string
): Promise<InfiniteKit> => {
  const response = await kitService.post<InfiniteKit>(
    `/${id}/technical-specs`,
    {
      name,
      description,
    }
  );
  return response.data;
};

export const deleteTechSpec = async (
  id: number,
  techId: number
): Promise<void> => {
  await kitService.delete(`/${id}/technical-specs/${techId}`);
};

export const calculateKit = async (
  id: number,
  calculationSetup: CalculationSetup
): Promise<InfiniteEntityCalculationStates> => {
  const response = await kitService.post<InfiniteEntityCalculationStates>(
    `/${id}/calculate`,
    calculationSetup
  );
  return response.data;
};

export const calculateLCS = async (
  id: number,
  category: LifeCycleStageCategory,
  calculationSetup: CalculationSetup
): Promise<CalculationState> => {
  const response = await kitService.post<CalculationState>(
    `/${id}/life-cycle-stages/${category}/calculate`,
    calculationSetup
  );
  return response.data;
};

export const getKitSocialAspectByLCSCategory = async (
  id: number,
  category: string
): Promise<KitSocialAspect[]> => {
  const response = await kitService.get<KitSocialAspect[]>(
    `/${id}/life-cycle-stages/${category}/social-aspects`
  );
  return response.data;
};

export const createKitSocialAspectByLCSCategory = async (
  id: number,
  category: LifeCycleStageCategory,
  stakeholder: SocialStakeHolder,
  socialIndicatorId: number,
  value: number,
  unit?: string,
  description?: string
): Promise<SocialAspectResp> => {
  const response = await kitService.post<SocialAspectResp>(
    `/${id}/life-cycle-stages/${category}/social-aspects`,
    {
      stakeholder,
      value,
      unit,
      socialIndicatorId,
      description
    }
  );
  return response.data;
};

export const deleteKitSocialAspectByLCSCategory = async (
  kitId: number,
  socialAspectId: number,
  category: string
): Promise<void> => {
  await kitService.delete(
    `/${kitId}/life-cycle-stages/${category}/social-aspects/${socialAspectId}`
  );
};
