/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Flex, HStack, Link, Text, useToast } from '@chakra-ui/react';
import { DataGrid, GridCellParams, GridColDef, GridRowId, GridRowParams, GridRowsProp, GridValueFormatterParams } from '@mui/x-data-grid';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import { formatKitRefAmount, useKit } from '../../services/kits.service';
import { useGlobalState } from '../../store/globalState';
import { InfiniteEntityStatus, InfiniteKit } from '../../store/schema';
import { Copy, Info, RunLCA } from '../Icons/icons';
import StatusChip from '../StatusChipMenu/StatusChip';

type KitRow = {
  id: number,
  kitId: number,
  name: string,
  description: string,
  lastChange: string,
  status: InfiniteEntityStatus
}
type State = {
  kitsRow: GridRowsProp,
  initialKitsRow: KitRow[],
  selectedRowIds: GridRowId[],
}

const DefaultKitLibrary: FC<{
  searchString: string, setKitIds: Dispatch<SetStateAction<number[]>>, onCalculationOpen: () => void
}> = ({ searchString, setKitIds, onCalculationOpen }) => {

  const { createCustomKit } = useKit()
  const toast = useToast()
  const history = useHistory();

  const [globalState] = useGlobalState("globalState")
  const { kits } = globalState
  const [state, setState] = useState<State>({
    kitsRow: [],
    initialKitsRow: [],
    selectedRowIds: [],
  })
  const { kitsRow, initialKitsRow, selectedRowIds } = state
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 6 / 24 },
    { field: 'referenceAmount', headerName: 'Reference amount', flex: 4 / 24 },
    {
      field: 'status', headerName: 'Status', flex: 3 / 24, renderCell: (params: GridCellParams) => (
        <StatusChip status={params.row.status} />
      )
    },
    { field: 'description', headerName: 'Description', flex: 6 / 24 },
    {
      field: 'lastChange', headerName: 'Last change', flex: 3 / 24, type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return new Date(params.value as Date).toLocaleDateString()
      },
    },
    {
      field: 'actions', headerName: 'Actions', flex: 3 / 24, align: "right", disableColumnMenu: true, headerAlign: "right",
      renderCell: (params: GridCellParams) => (
        <HStack>
          <Link as={ReachLink} to={`/kits/${params.row.kitId as number}`}>
            <Info />
          </Link>
          <Link as={ReachLink} to={`#`} onClick={() => handleCopy(params.row as KitRow)}>
            <Copy />

          </Link>
          <Link as={ReachLink} to="#" onClick={() => handleRowLCA(params)}>
            <RunLCA color />
          </Link>
        </HStack>
      )
    }
  ];

  useEffect(() => {
    const kitIds = selectedRowIds.map(rowId => {
      const kitId = (kitsRow.find(row => row.id === rowId) as KitRow).kitId
      return kitId
    })
    setKitIds(kitIds)
  }, [selectedRowIds])

  const handleCopy = async (kitRow: KitRow) => {
    try {
      const findKit = { ...kits.find(kit => kit.id === kitRow.kitId) as InfiniteKit }
      findKit.name += " (copy)"

      await createCustomKit(findKit)
      toast({
        position: "top",
        title: "Success",
        description: "Kit copied successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        position: "top",
        title: "Error",
        description: error as string,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    /**
                * Filter the kits on name, description or creation date. It is case insensitive
                * @returns The filtered rows array
                */
    const filterRows = () => {
      const formatedSearch = searchString.toLowerCase()
      return initialKitsRow.filter(row =>
        row.name.toLowerCase().includes(formatedSearch)
      )
    }

    setState({ ...state, kitsRow: filterRows() })
  }, [searchString])

  useEffect(() => {
    const formatKits = (kits: InfiniteKit[]) => {
      const rows: KitRow[] = kits.map((kit: InfiniteKit, idx: number) => (
        {
          id: idx,
          kitId: kit.id,
          name: kit.name,
          referenceAmount: formatKitRefAmount(kit),
          lastChange: kit.lastChange,
          description: kit.description,
          status: kit.status || InfiniteEntityStatus.PRELIMINARY
        }
      ))
      setState({ ...state, kitsRow: rows, initialKitsRow: rows })

    }
    if (kits)
      formatKits(kits)
  }, [kits])

  const handleSelection = (selectionModel: GridRowId[]) => {
    setState({ ...state, selectedRowIds: selectionModel })
  }

  const handleDoucleClick = (param: GridRowParams) => {
    history.push(`/kits/${param.row.kitId as string}`)
  }

  const handleRowLCA = (params: GridCellParams) => {
    setState({ ...state, selectedRowIds: [params.row.id] })
    onCalculationOpen()
  }

  return (
    <>
      <Flex h="69vh" w="100%">
        <DataGrid
          rows={kitsRow}
          columns={columns}
          checkboxSelection
          autoPageSize
          pagination
          density="compact"
          onSelectionModelChange={handleSelection}
          onRowDoubleClick={handleDoucleClick}
          components={{
            Toolbar: CustomToolbar
          }}
          selectionModel={selectedRowIds}
          disableSelectionOnClick />
      </Flex>
    </>
  );
};

const CustomToolbar: FC = () => {
  return (
    <Flex>
      <Text ml={5} fontSize={"lg"}>INFINITE kit library</Text>
    </Flex>
  );
}

export default DefaultKitLibrary
