import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react"
import React, { FC, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useUser } from "../services/user.service"
import { useGlobalState } from "../store/globalState"
import { isEmail } from "../utils/utils"

const Home: FC<{ edit?: boolean }> = ({ edit = false }) => {
  const [isEditEnabled, setIsEditEnabled] = useState(edit)
  const [authState] = useGlobalState("authState")
  const [globalState] = useGlobalState("globalState")
  const { user } = globalState
  const { isAuthenticated } = authState
  const history = useHistory()
  const toast = useToast()
  const [email, setEmail] = useState(user?.email)
  const [firstName, setFirstName] = useState(user?.firstName)
  const [touched, setTouched] = useState(false)

  const { updateUser } = useUser()

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setTouched(true)
    if (user) {
      if (!isEmail(email) || !firstName)
        return
      let dirty = false
      if (firstName && user.firstName !== firstName) {
        user.firstName = firstName
        dirty = true
      }
      if (email && user.email !== email) {
        user.email = email
        dirty = true
      }
      if (dirty) {
        try {
          void updateUser({ email, firstName })
          toast.closeAll()
          toast({
            position: "top",
            title: "Success",
            description: "User modified successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
          })
        } catch (error) {
          toast({
            position: "top",
            title: "Error",
            description: error as string,
            status: "error",
            duration: 4000,
            isClosable: true,
          })
        }
      }
    }
  }



  return (
    <Flex
      minH={'92vh'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Profile</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            You can see and modify your personal information
          </Text>
        </Stack>
        <form>
          <FormControl
            id="email"
            isRequired isInvalid={touched && !isEmail(email)}
          >
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={!isEditEnabled}
            />
            <FormErrorMessage>
              {!email && "Please provide an email"}
              {email && "Please provide a well formated email"}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="firstName"
            isRequired
            isInvalid={touched && !firstName}
          >
            <FormLabel>First name</FormLabel>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              isDisabled={!isEditEnabled}
            />
            <FormErrorMessage>Please provide a first name</FormErrorMessage>
          </FormControl>
          <Button
            mt={4} mr={4}
            colorScheme={isEditEnabled ? "orange" : "yellow"}
            onClick={() => setIsEditEnabled(!isEditEnabled)}
          >
            Edit
          </Button>
          <Button
            mt={4}
            colorScheme="teal"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </Stack>
    </Flex>
  )
}

export default Home;
