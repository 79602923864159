import { Search2Icon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React, { Dispatch, FC, SetStateAction } from 'react';

const SearchBar: FC<{
  search: string,
  setSearch: Dispatch<SetStateAction<string>>,
  style?: React.CSSProperties | undefined, placeholder?: string
}> = ({ search, setSearch, style, placeholder }) => {
  return (
    <Box style={style}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
        ><Search2Icon color="gray.300" />
        </InputLeftElement>
        <Input
          value={search}
          placeholder={placeholder ? placeholder : "Kit name"}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>
    </Box>
  );
};

export default SearchBar
