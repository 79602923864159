import { Chart, Plugin } from 'chart.js';
import { Dataset } from "../../store/types";

// Plugin that sort the bars in the descending order
const sortPlugin: Plugin<"bar"> = {
  id: 'sortPlugin',
  /**
   * The horizontal bars will be sorted before the initialisation of the chart.
   * @param chart The Chart
   */
  beforeInit(chart: Chart<"bar">) {
    const getData = (labels: unknown[] | undefined, datasets: number[][]) => {
      const sum = [];
      for (let i = 0; i < datasets[0].length; i++) {
        const data = datasets.map((data: number[]) => data[i])
        sum.push({
          label: labels ? labels[i] : undefined,
          data: datasets.map((data: number[]) => data[i]),
          get sum(): number {
            return data.reduce((a: number, b: number) => a + b);
          }
        });
      }
      return sum;
    }

    const datasets: number[][] = []
    chart.data.datasets.forEach((dataset: Dataset) => {
      datasets.push(dataset.data);
    });
    if (!datasets.length) return chart.render()
    // Effective sort of datasets
    const data_store = getData(chart.data.labels, datasets)
      .sort((a, b) => b.sum - a.sum);
    // Update the charts values with sorted datasets
    data_store.forEach((d, i) => {
      if (chart.data.labels) {
        chart.data.labels[i] = d.label;
        d.data.forEach((v: number, vi: number) => {
          chart.data.datasets[vi].data[i] = v;
        });
      }
    });
    chart.render()
  }
}
export default sortPlugin
