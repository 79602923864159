import axios from "axios";
import { Token } from "../store/types";
import configData from "./config.json";

const oauth2 = axios.create({
  baseURL: configData.INFINITE_API_URL + "/login",
  headers: {
    accept: "application/json",
    "Content-type": "application/x-www-form-urlencoded",
  },
  responseType: "json",
});

export const signin = async (
  username: string,
  password: string
): Promise<Token> => {
  const response = await oauth2.post<Token>(
    "/access-token",
    `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
  );
  localStorage.setItem("authToken", response.data.accessToken);
  return response.data
};

export const logout = (): void => {
  localStorage.removeItem("authToken");
};
