import {
  Box, HStack,
  IconButton,
  Spacer,
  VStack
} from "@chakra-ui/react"
import { TextField } from "@material-ui/core"
import React, { FC } from "react"
import { TechnicalSpecs } from "../../store/schema"
import { Delete } from "../Icons/icons"

const TechSpec: FC<{
  techSpec: TechnicalSpecs | Omit<TechnicalSpecs, "kitId">,
  techSpecs: TechnicalSpecs[],
  setTechSpecs: React.Dispatch<React.SetStateAction<TechnicalSpecs[]>>,
  newTechSpecs: Omit<TechnicalSpecs, "kitId">[],
  setNewTechSpecs: React.Dispatch<
    React.SetStateAction<Omit<TechnicalSpecs, "kitId">[]>
  >,
  isKitTechSpec: boolean,
  handleDelete: () => void, isModificationEnabled: boolean,
  setTouched: (touched: boolean) => void;
}> = ({
  techSpec,
  techSpecs,
  setTechSpecs,
  newTechSpecs,
  setNewTechSpecs,
  isKitTechSpec,
  handleDelete,
  isModificationEnabled,
  setTouched
}) => {

    return (
      <VStack spacing={5} alignItems="start" w="100%">
        <HStack w="100%">
          <Box
            as="form"
            sx={{
              "& .MuiTextField-root": { width: "30ch" },
            }}
          >
            <TextField
              id="standard-basic"
              label="Technical specification name"
              variant="standard"
              value={techSpec.name}
              onChange={(e) => {
                //if (isKitTechSpec) {
                setTechSpecs(techSpecs.map((t) => {
                  if (t.id === techSpec.id) {
                    return {
                      ...t,
                      name: e.target.value,
                    }
                  }
                  return t
                }));
                setTouched(true);
              }}
              fullWidth
              disabled={!isModificationEnabled}
            />
          </Box>
          <Spacer />
          <Box>
            <IconButton
              mr={2}
              colorScheme="red"
              size="xs"
              aria-label="Search database"
              icon={<Delete />}
              onClick={() => handleDelete()}
              disabled={!isModificationEnabled}
            />
          </Box>
        </HStack>
        <Box
          as="form"
          sx={{
            "&": { width: "100%" },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            id="standard-multiline-static"
            label="Description"
            multiline
            rows={4}
            variant="standard"
            value={techSpec.description}
            onChange={(e) => {
              //if (isKitTechSpec) {
              setTechSpecs(techSpecs.map((t) => {
                if (t.id === techSpec.id) {
                  return {
                    ...t,
                    description: e.target.value,
                  }
                }
                return t
              }));
              setTouched(true);
            }}
            fullWidth
            disabled={!isModificationEnabled}
          />
        </Box>
      </VStack>
    )
  }

export default TechSpec
